export enum PerfisContributivos {
    SALARIOMINIMO11,
    SALARIOVARIAVEL11,
    SALARIOTETO11,
    SALARIOVARIAVEL20,
    SALARIOTETO20,
    OUTROSVALORES,
    NAOCONTRIBUIR,
    MANTERQUALIDADESEGURADO,
    SALARIOMINIMO20
}