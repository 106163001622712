export enum TiposVinculos {
    EMPREGADOOUAGENTEPUBLICO,
    CONTRIBUINTEINDIVIDUAL,
    BENEFICIO,
    FACULTATIVO,
    EMPREGADODOMESTICO,
    TRABALHADORAVULSO,
    SEGURADOESPECIAL,
    SEGURADOFACULTATIVO,
    APOSENTADO,
    CONTRIBUINTEEMDOBRO,
    AUTONOMO,
    PENSAOMORTE,
    MEI,
    PRESTADORSERVICO,
    AUXILIOINCAPACIDADETEMPORARIA,
    AUXILIOINCAPACIDADEPERMANENTE,
    SALARIOMATERNIDADE,
    SOCIOEMPRESARIO,
    BENEFICIOINDEFERIDO,
    PROJECAOPLANEJAMENTO,
    CONTRIBUINTEINDIVIDUALPFPLANOSIMPLIFICADO,
    CONTRIBUINTEINDIVIDUALPJEMPRESARIO,
    CONTRIBUINTEINDIVIDUALPF,
    CONTRIBUINTEINDIVIDUALPFCOOPERADO

}