<app-cabecalho [titulo]="'Novo Cálculo'"></app-cabecalho>
<div class=" container-fluid mt--3">

  <div class=" row">
    <div class=" col-12">
      <div class="  ">

        <div class="pb-4">
          <div class="card">
            <div class="row">
              <div class="col">
                <div class="card-body">

                  <div class>

                    <div class="row g-3 flex d-flex pb-6">

                      <!-- <div class="col-md-4 py-2" role="button">

                        <a class="text-default" [routerLink]="['/calculei/calculos/previdenciario/beneficios']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <p class=" pb-5 display-3 fw-lighter">Renda Mensal Inicial</p>
                              <p class="mb-3 text-sm">Quando quiser calcular o valor da RMI de todas as regras de aposentadorias programáveis para hoje, ou uma data anterior. Recomendado para quem já atingiu os requisitos de idade, tempo de contribuição e carência. Útil também, para verificação de quanto falta de tempo de contribuição, carência e idade para cada regra de transição quando o segurado ainda não tiver completado estes requisitos.</p>
                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Regras de transição pós reforma</span>
                                </div>
                              </div>
                              

                            </div>

                          </div>
                        </a>

                      </div> -->

                      <div class="col-md-4 py-2" role="button">
                        <a class="text-default" [routerLink]="['/calculei/calculos/previdenciario/simulacao']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <p class=" pb-5 display-3 fw-lighter">Simulação</p>
                              <!-- <p class="mb-3 text-sm">Quando quiser calcular o valor da RMI de todas as regras de aposentadorias programáveis para uma data futura. Ideal para escolher a melhor regra de transição, e saber quanto tempo falta para cumprimento de idade, e quantas contribuições para cumprimento de tempo de contribuição e carência.</p> -->
                              <!-- <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Regras de transição pós reforma</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Comparativo de Investimento Contribuição X Capitalização</span>
                                </div>
                              </div> -->

                          

                            </div>

                          </div>
                        </a>

                      </div>

                      <div class="col-md-4 py-2" role="button">
                        <a class="text-default" [routerLink]="['/calculei/calculos/previdenciario/kertzman']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <p class=" pb-5 display-3 fw-lighter">Otimização da Aposentadoria por Idade Método Kertzman</p>
                              <!-- <p class="mb-3 text-sm">Quando quiser calcular quantas contribuições pelo teto, vale a pena fazer quando for optante pela aposentadoria por idade. Considera a melhor Taxa de Retorno do Investimento e Tempo de Retorno do Investimento (payback). Ideal para o planejamento de autônomos e sócios de empresas</p> -->
                              <!-- <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Otimização: contribuições futuras X taxa de retorno</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Aposentadoria por Idade</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Comparativo de Investimento Contribuição X Capitalização</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Taxa de Retorno e Payback do Investimento</span>
                                </div>
                              </div> -->

                            
                            </div>

                          </div>
                        </a>

                        <!-- <span class="position-absolute px-4 mt-2 top-0 start-50 translate-middle badge rounded-pill bg-default">
                          <small>
                            <i class="bi bi-link"></i> <a href="https://tutoriais.calculei.app/calculos/metodokertzman/" target="_blank">Tutorial</a>
                          </small>

                        </span> -->

                      </div>

                      <!-- <div class="col-md-4 py-2" role="button">

                        <a class="text-default" [routerLink]="['/calculei/calculos/previdenciario/analisecnis']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <p class=" pb-5 display-3 fw-lighter">Análise de CNIS</p>
                              <p class="mb-3 text-sm">Quando quiser ter uma visão sumária e detalhada do CNIS. Ideal para começar o atendimento de qualquer caso.</p>
                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Validação de Sequência</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Pontos de Atenção</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Linha do Tempo do Período Contributivo</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Métricas do CNIS</span>
                                </div>
                              </div>

                            </div>

                          </div>

                        </a>

                      </div> -->

                      <div class="col-md-4 py-2" role="button">
                        <a class="text-default" [routerLink]="['/calculei/calculos/previdenciario/mapaqualidadesegurado']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <p class=" pb-5 display-3 fw-lighter">Qualidade de Segurado</p>
                              <!-- <p class="mb-3 text-sm">Quando quiser mapear os períodos em que o segurado esteve, ou não, na qualidade de segurado, e indica quais contribuições futuras devem ser feitas para adquirir ou manter a qualidade de segurado. Ideal para quem já cumrpriu os requesitos de carência e deseja manter a qualidade de segurado.</p> -->
                              <!-- <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Linha do Tempo da Qualidade de Segurado</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Calendário de Contribuições para Manutenção da Qualidade de Segurado</span>
                                </div>
                              </div> -->

                            </div>

                          </div>
                        </a>

                      </div>

                      <div class="col-md-4 py-2" role="button">
                        <a class="text-default" [routerLink]="['/calculei/calculos/previdenciario/incapacidadetemporaria']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <p class=" pb-5 display-3 fw-lighter">Auxílio por Incapacidade Temporária / Acidente de Trabalho</p>
                              
                              <!-- <p class="mb-3 text-sm">Quando quiser calcular o valor indenizatório de um benefício que deve ser concedido, para cobertura dos eventos de doença, invalidez, morte e idade avançada.</p> -->
                              <!-- <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    Arts. 19 a 23, Lei 8.213/1991
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    Arts. 59 a 64, Lei 8.213/1991
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    Arts. 71 a 80 e 337, Decreto 3.048/1999
                                  </span>
                                </div>
                              </div> -->

                            

                            </div>

                          </div>
                        </a>

                      </div>



                      <div class="col-md-4 py-2" role="button">
                        <a class="text-default" [routerLink]="['/calculei/calculos/previdenciario/invalidez']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <p class=" pb-5 display-3 fw-lighter">Aposentadoria por Invalidez</p>
                              
                              <!-- <p class="mb-3 text-sm">Quando quiser calcular o valor indenizatório de um benefício que deve ser concedido, para cobertura dos eventos de doença, invalidez, morte e idade avançada.</p> -->
<!-- 
                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    EC 103/2019
                                  </span>
                                </div>
                              </div>


                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    Arts. 42 a 47, Lei 8.213/1991
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    Arts. 59 a 64, Lei 8.213/1991
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    Arts. 43 a 50, Decreto 3.048/1999
                                  </span>
                                </div>
                              </div> -->

                              

                            </div>

                          </div>
                        </a>

                      </div>


                      <div class="col-md-4 py-2" role="button">
                        <a class="text-default" [routerLink]="['/calculei/calculos/matriz']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <p class=" pb-5 display-3 fw-lighter">Matriz de Planejamento</p>
                              
                              <!-- <p class="mb-3 text-sm">Quando quiser calcular o valor indenizatório de um benefício que deve ser concedido, para cobertura dos eventos de doença, invalidez, morte e idade avançada.</p> -->

                              <!-- <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    Melhor benefício
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">
                                    Simulação do investimento em produto financeiro privado
                                  </span>
                                </div>
                              </div>

 -->

                              

                            </div>

                          </div>
                        </a>

                      </div>

                      <!-- <div class="col-md-4 py-2" role="button">
                        <a class="text-default" [routerLink]="['/calculei/calculos/previdenciario/liquidacaosentenca']">
                          <div class="h-100 w-100 p-4 rounded bg-secondary">

                            <div class>
                              <h5 class="pb-5 display-4">Liquidação de Sentença</h5>
                              
                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Descontos por Importação do HISCRE</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Desconto por Evolução de RMI</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Customização dos Critérios de Correção e Juros</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Total dos Atrasados</span>
                                </div>
                              </div>

                            </div>

                          </div>
                        </a>

                      </div> -->

                      <!-- <div class="col-md-4 mb-4 clicavel">
                        <a [routerLink]="['/calculei/calculos/previdenciario/auxilioacidente']">
                          <div class="card shadow-none border h-100">
                            <div class="card-header text-sm-left text-center pt-4 pb-3 px-4 no-bottom-border">
                              <h5 class="pb-5 display-4">Auxílio-acidente</h5>
                              <p class="mb-3 text-sm">Quando quiser calcular o valor indenizatório de um benefício que deve ser concedido, como forma de indenização, após a consolidação das lesões decorrentes de acidente de qualquer natureza.</p>
                            </div>

                            <div class="card-body">
                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Arts. 86, Lei 8.213/1991</span>
                                </div>
                              </div>

                              <div class="d-flex pb-3">
                                <div>
                                  <i class="fas fa-check text-default text-sm" aria-hidden="true"></i>
                                </div>
                                <div class="ps-3">
                                  <span class="text-sm">Arts. 104, Decreto 3.048/1999</span>
                                </div>
                              </div>

                           
                            </div>
                          </div>
                        </a>

                      </div> -->

                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>