import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { LinkMarcadorLeituraCnisComponent } from 'src/app/aplicacao/calculos/previdenciario/compartilhado/link-marcador-leitura-cnis/link-marcador-leitura-cnis.component';
import { AnonimoPipe } from 'src/app/aplicacao/compartilhado/pipes/anonimopipe';
import { Marcador } from 'src/app/modelos/common/marcador';
import { Global } from 'src/app/modelos/global';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { BaixarLeituraCnisService } from 'src/app/servicos/calculo/previdenciario/baixar-leitura-cnis.service';
import { ExcluirLeituraCnisService } from 'src/app/servicos/calculo/previdenciario/excluir-leitura-cnis.service';
import { ListarLeituraCnisService } from 'src/app/servicos/calculo/previdenciario/listar-leitura-cnis.service';
import { LogService } from 'src/app/servicos/log.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-cnis-listagem',
  templateUrl: './cnis-listagem.component.html',
  styleUrls: ['./cnis-listagem.component.scss']
})
export class CnisListagemComponent {


  @ViewChild('linkMarcadorLeituraCnis') linkMarcadorLeituraCnis: LinkMarcadorLeituraCnisComponent;

  @Output() leituraCnisSelecionadoEvent = new EventEmitter<LeituraCNIS>();

  leituraCnis: LeituraCNIS;
  listaNomes: string[];
  listaLeituraCnis: LeituraCNIS[];
  partitionKey: string = Global.usuario.rowKey;

  listaPronta: boolean;
  aguarde: boolean;
  
  usuario: Usuario;

  constructor(private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private excluirLeituraCnisService: ExcluirLeituraCnisService,
    private listarLeituraCnisService: ListarLeituraCnisService,
    private baixarLeituraCnisService: BaixarLeituraCnisService,
    private configService: ConfigService,
    private calculeiApiService:CalculeiApiService,
    private datePipe: DatePipe) {

    this.usuario = Global.usuario;

    this.configService.getHistoricoLeituraCnis().subscribe(lista => {
      this.carregarDados(lista);

    });
    this.configService.getAguarde().subscribe(status=>this.aguarde= status);
    
  }


  ngOnInit(): void {
    // this.carregarDados();
    this.configService.getHistoricoLeituraCnis().subscribe(lista => {
      this.carregarDados(lista);

    });

  }
  ngAfterViewInit(): void {

  }



  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("leituracnis.json", request => {
      this.listaLeituraCnis = request;
    });
  }

  carregarDados(lista: LeituraCNIS[]) {

    // LogService.log('carregar dados cnis listagem');

    if (lista) {
      this.listaPronta = false;
      this.listaLeituraCnis = lista;
      this.listaLeituraCnis = this.listaLeituraCnis.sort((a, b) => { return (new Date(a.dataUltimaModificacao).getTime() - (new Date(b.dataUltimaModificacao).getTime())) }).reverse();
      this.listaPronta = true;
      // this.listaNomes = Array.from([...new Set(this.listaLeituraCnis.map(leitura => leitura.cnis.identificacaoFiliado.nome))]);
      
    }
  }

  // leituraCnisNome(nome: string) {
  //   return this.listaLeituraCnis.filter(leitura => leitura.cnis.identificacaoFiliado.nome === nome).sort((a, b) => { return (new Date(a.dataUltimaModificacao).getTime() - (new Date(b.dataUltimaModificacao).getTime())) }).reverse();
  // }


  cardClick(leituraCnis: LeituraCNIS) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.configService.setAguarde(true);

    this.configService.getUsuario().subscribe(usuario=>leituraCnis.usuario = usuario);
    this.calculeiApiService.api("AbrirLeituraCnis", leituraCnis).subscribe(resultado=>{
      setTimeout(() => {
        this.leituraCnis = resultado as LeituraCNIS;
        this.leituraCnisSelecionadoEvent.emit(this.leituraCnis);
        this.configService.setAguarde(false);
  
      }, 200);

    });
    

  }

  excluirCNIS(leituraCnis: LeituraCNIS) {
    this.listaPronta = false;
    let indexLeituraCnis: number;
    this.configService.getHistoricoLeituraCnis().subscribe(lista => { indexLeituraCnis = lista.indexOf(leituraCnis) });
    // console.log('index leituracnis no historico para excluir', indexLeituraCnis);
    if (indexLeituraCnis > 0) {
      let listaRemocao:LeituraCNIS[];
      this.configService.getHistoricoLeituraCnis().subscribe(lista => { listaRemocao = lista; });
      listaRemocao.splice(indexLeituraCnis, 1);

      this.configService.atualizarHistoricoLeituraCnis(listaRemocao);
    }

    this.configService.getUsuario().subscribe(usuario=>leituraCnis.usuario = usuario);

    this.excluirLeituraCnisService.excluirLeituraCnis(leituraCnis)
      .then((leituraCnis: LeituraCNIS) => {
        // console.log('exlcluir leitura cnis retorno api');
        // this.carregarDados(this.listaLeituraCnis);
        this.listaPronta = true;
      });
  }

  excluirTodosCNIS() {


    this.listaPronta = false;

    let tasks: Promise<Object>[] = new Array();

    this.listaLeituraCnis.forEach(leituraCnis => {
      this.configService.getUsuario().subscribe(usuario=>leituraCnis.usuario = usuario);
      tasks.push(this.excluirLeituraCnisService.excluirLeituraCnis(leituraCnis));
    });
    Promise.all(tasks).then(resultado => {

      this.listaLeituraCnis.forEach(leitura => {
        let indexLeitura = this.listaLeituraCnis.indexOf(leitura);
        if (indexLeitura >= 0) {
          this.listaLeituraCnis.splice(indexLeitura, 1);
        }
      });

      

      this.carregarDados(this.listaLeituraCnis);

      this.listaPronta = true;
      // console.log('excluir todos cnis finalizado');

      //limpar lista local
      // let listaRemover: LeituraCNIS[];
      // this.configService.getHistoricoLeituraCnis().subscribe(lista => { listaRemover = lista; });

      // if (listaRemover) {
      //   console.log('lista remover', listaRemover);
      //   this.listaPronta = false;
      //   listaRemover.forEach(leitura => {

      //     let indexLeituraCnis: number;

      //     indexLeituraCnis = listaRemover.indexOf(leitura)
      //     if (indexLeituraCnis > 0) {
      //       listaRemover.splice(indexLeituraCnis, 1)
      //     }
      //   });

      //   this.configService.atualizarHistoricoLeituraCnis(listaRemover);


      //   this.listaPronta = true;
      // }



    });

  }

  adicionarMarcador(leituraCnis: LeituraCNIS) {

    this.linkMarcadorLeituraCnis.openModal(leituraCnis);

  }

  linkMarcadorConcluido(marcadores: Marcador[]) {
    // this.carregarDados();

  }

}
