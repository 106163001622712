<!-- 
<div class="row">
    <div class="col-12">
        <h5 class=" h3 mb-0 linha-quadro pb-4">Fichas</h5>
    </div>
</div>

<div class="py-4">
    <div class="row">
        <div class="col">
            <p class="h3">
                Nova Ficha
            </p>
            <i class="comando bi bi-plus-square-dotted fs-6" role="button" [routerLink]="['/calculei/entrevistas/ficha']"></i>
        </div>
    </div>
</div>
<div class="py-4">
    <app-lista-fichas (fichaSelecionadaEvent)="selecionarFicha($event)"></app-lista-fichas>
</div> -->


<div class="row">
    <div class="col-12">
        <h5 class=" h3 mb-0 linha-quadro pb-4">Fichas</h5>
    </div>
</div>

<div class="py-4">
    <app-add-ficha (fichaAdicionadaEvent)="fichaAdicionada($event)" ></app-add-ficha>
</div>
<div class="py-4">
    <app-lista-fichas #listaFichas (fichaSelecionadaEvent)="fichaSelecionada($event)" ></app-lista-fichas>
</div>
