<div class=" header bg-back-dark">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-3">
        <!-- <div class=" col-lg-12">
          <span class=" text-white inline-block mb-0 h6 fw-lighter">Novo Cálculo de Análise de CNIS</span>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div class=" container-fluid mt--3  ">

  <div class=" row">
    <div class=" col-12">
      <div class="  ">
        <div class="pb-4">

          <div class=" card ">

            <div class="row">
              <div class="col-12">

                <div class=" card-body  ">

                  <div class="row">
                    <div class="col-12">

                      <div class="row">
                        <div class="col-12">
                          <app-barra-comandos *ngIf="calculoAnaliseCnis" [titulo]="calculoAnaliseCnis.nomeCalculo" (proximoEvent)="proximo($event)" (anteriorEvent)="anterior($event)" (pdfEvent)="pdf($event)" (cancelarEvent)="cancelar($event)" (salvarEdicaoCnisEvent)="salvarEdicaoCnis($event)"></app-barra-comandos>
                        </div>
                      </div>
                      <div id="stepper1" class="bs-stepper .vertical">
                        <div class="row">
                          <div class="col-12">
                            <div class="bs-stepper-header">
                              <div class="step" data-target="#test-l-1">
                                <button class="step-trigger">
                                  <span class="bs-stepper-circle fw-normal"><i class="bi bi-filetype-pdf"></i></span>
                                  <span class="bs-stepper-label fw-normal">Selecionar CNIS</span>
                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-2">
                                <button class="step-trigger">
                                  <span class="bs-stepper-circle fw-normal"><i class="bi bi-calendar2-check"></i></span>
                                  <span class="bs-stepper-label fw-normal">Análise de CNIS</span>
                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-3">
                                <button class="step-trigger">
                                  <span class="bs-stepper-circle fw-normal"><i class="bi bi-calendar2-week"></i></span>
                                  <span class="bs-stepper-label fw-normal">Relatório</span>

                                </button>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div class="bs-stepper-content m-0 p-0">

                          <!-- selecionar CNIS -->
                          <div id="test-l-1" class="content">


                            <div class="row">
                              <div class="col-12">
                                <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
                              </div>
                            </div>

                            <div [className]="!aguarde ? 'visible' : 'invisible'">
                              <div class="row">
                                <div class="col-12">
                                  <div class="pb-2">
                                    <app-importador-cnis #importadorCNIS (leituraCNISEvent)="leituraCNISEvent($event)"></app-importador-cnis>
                                  </div>

                                  <div class="py-2">
                                    <app-cnis-listagem #seletorCnis (leituraCnisSelecionadoEvent)="leituraCNISEvent($event)"></app-cnis-listagem>

                                  </div>
                                  <div class="py-2">
                                    <app-gerador-cnis (gerarLeituraCnisFinalizadaEvent)="leituraCNISEvent($event)"></app-gerador-cnis>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="row">
                              <div class="col-12">
                                <div class="py-2">
                                  <app-importador-cnis #importadorCNIS (leituraCNISEvent)="leituraCNISEvent($event)"></app-importador-cnis>
                                </div>

                                <div class="py-2">
                                  <app-cnis-listagem #seletorCnis (leituraCnisSelecionadoEvent)="leituraCNISEvent($event)"></app-cnis-listagem>
                                </div>
                                
                                <div class="py-2">
                                  <app-gerador-cnis (gerarLeituraCnisFinalizadaEvent)="leituraCNISEvent($event)"></app-gerador-cnis>
                                </div>
                              </div>
                            </div> -->




                          </div>

                          <!-- Análise CNIS -->
                          <div id="test-l-2" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-editor-cnis #editorCNIS *ngIf="calculoAnaliseCnis && calculoAnaliseCnis.calculoBeneficios"
                                  [calculoBeneficios]="calculoAnaliseCnis.calculoBeneficios" [aguardeVisivel]="aguardeVisivel"
                                  (edicaoCNISFinalizadaEvent)="edicaoCNISFinalizada($event)"
                                  (listaSeletorCnisAtualizadaEvent)="atualizarListaCnis($event)"
                                  (cnisAtualizadoEvent)="cnisAtualizado($event)"
                                  (cnisModificadoEvent)="cnisModificado($event)"
                                  (excluirCnisFinalizadoEvent)="excluirCnisFinalizado($event)"></app-editor-cnis>

                              </div>
                            </div>
                          </div>

                          <!-- Resultado -->
                          <div id="test-l-3" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-resultado-analise-cnis #resultadoAnaliseCnis *ngIf="calculoAnaliseCnis && calculoAnaliseCnis.calculoBeneficios" [calculoAnaliseCnis]="calculoAnaliseCnis"></app-resultado-analise-cnis>

                              </div>
                            </div>

                          </div>

                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
