export class EdicaoImpostoRenda{
    
    numeroDependentes:number;
    totalDespesasMedicas:number;
    totalDespesasEducacao:number;
    totalContribuicaoPrevidenciaSocial:number;
    totalContribuicaoPrevidenciaPrivada:number;
    totalDespesasHabitacao:number;
    totalDoacoesIncentivadas:number;
    totalContribuicoesFundoAposentadoriaServidorPublico:number;
    totalPensaoAlimenticia:number;
}