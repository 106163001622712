<div class=" header bg-back-dark">
    <div class=" container-fluid">
      <div class=" header-body">
        <div class=" row align-items-center py-3">
          <!-- <div class=" col-lg-12">
            <span class=" text-white inline-block mb-0 h6 fw-lighter">Ficha de Entrevista</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  
  <div  class=" container-fluid mt--3  ">
  
    <div class=" row">
      <div class=" col-12">
        <div class="  ">
          <div class="pb-4">
  
            <div class=" card ">
  
              <div class="row">
                <div class="col-12">
  
                  <div class=" card-body  ">
  
                    <div class="row">
                      <div class="col-12">
  
                        <div class="row">
                          <div class="col-12">
                            <app-barra-comandos *ngIf="ficha" [(titulo)]="ficha.nome" (editarTituloEvent)="editarTitulo($event)" (proximoEvent)="proximo($event)" (anteriorEvent)="anterior($event)" (pdfEvent)="pdf($event)" (cancelarEvent)="cancelar($event)" (salvarEdicaoCnisEvent)="salvarEdicaoCnis($event)"></app-barra-comandos>
                          </div>
                        </div>
                        <div id="stepper1" class="bs-stepper .vertical">
                          <div class="row">
                            <div class="col-12">
                              <div class="bs-stepper-header">
                                <div class="step" data-target="#test-l-1">
                                  <button class="step-trigger">
                                    <span class="bs-stepper-circle fw-normal"><i class="bi bi-list-ul"></i></span>
                                    <span class="bs-stepper-label fw-normal">Formulário</span>
                                  </button>
                                </div>
                                <div class="line"></div>
                                <div class="step" data-target="#test-l-2">
                                  <button class="step-trigger">
                                    <span class="bs-stepper-circle fw-normal"><i class="bi bi-ui-checks-grid"></i></span>
                                    <span class="bs-stepper-label fw-normal">Opções </span>
                                  </button>
                                </div>
                                <div class="line"></div>
                                <div class="step" data-target="#test-l-3">
                                  <button class="step-trigger">
                                    <!-- <div class="spinner-grow" role="status" [ngClass]="{'show': aguardeResultado, 'collapse': !aguardeResultado}"></div> -->
                                    
                                    <span class="bs-stepper-circle fw-normal"><i class="bi bi-clipboard-check"></i></span>
                                    <span class="bs-stepper-label fw-normal">Resumo</span>
                                    
                                </button>
                                </div>
  
                              </div>
                            </div>
                          </div>
                          
                          <div class="bs-stepper-content m-0 p-0">
  
                            <!-- selecionar CNIS -->
                            <div id="test-l-1" class="content">
  
                              <div class="row">
                                <div class="col-12">
                                  <app-editor-perguntas *ngIf="ficha" [ficha]="ficha"></app-editor-perguntas>
                                </div>
                              </div>
                            </div>
  
                            <!-- Análise CNIS -->
                            <div id="test-l-2" class="content">
  
                              <div class="row">
                                <div class="col-12">
                                  <!-- <app-editor-opcoes-ficha *ngIf="ficha" [ficha]="ficha"></app-editor-opcoes-ficha> -->
                                  
                                </div>
                              </div>
                            </div>
  
                            <!-- Resultado -->
                            <div id="test-l-3" class="content">
  
                              <div class="row">
                                <div class="col-12">
                                  <!-- <app-publicar-ficha *ngIf="ficha" [ficha]="ficha"></app-publicar-ficha> -->
  
                                </div>
                              </div>
  
                            </div>
  
                          </div>
                        </div>
  
                      </div>
  
                      
                    </div>
  
                  
                  </div>
  
                </div>
              </div>
  
            </div>
  
          </div>
  
        </div>
      </div>
    </div>
  
  </div>