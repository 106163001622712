import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';

import { LogService } from 'src/app/servicos/log.service';
import { ptBR } from 'date-fns/locale';

@Component({
  selector: 'app-salarios-preview',
  templateUrl: './salarios-preview.component.html',
  styleUrl: './salarios-preview.component.scss'
})
export class SalariosPreviewComponent implements OnInit, AfterViewInit {

  @Input() calculoBeneficios: CalculoBeneficios;

  ngAfterViewInit(): void {

  }

  public chart: Chart;
  dataMin: Date;
  dataMax: Date;

  opcao: string;
  datasets = [];
  datasetsVazios = [];
  datasetsPrePlanoReal = [];
  datasetSalariosContribuicao;
  datasetSalariosContribuicaoLimitadoTeto;
  datasetTetoInss;
  datasetSalariosAtualizados;
  datasetSalarioMinimo;

  competencias: Date[];


  constructor(private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe) { }



  ngOnInit(): void {
    this.dataMin = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.flatMap(sequencia => sequencia.contribuicoes).sort((a, b) => (new Date(a.competencia)).getTime() - (new Date(b.competencia)).getTime())[0].competencia;
    this.dataMax = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.flatMap(sequencia => sequencia.contribuicoes).sort((a, b) => (new Date(b.competencia)).getTime() - (new Date(a.competencia)).getTime())[0].competencia;

    this.competencias = Array.from(
      new Set(
        this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias
          .flatMap(sequencia => sequencia.contribuicoes)
          .map(contribuicao => {
            const data = new Date(contribuicao.competencia);
            return new Date(data.getFullYear(), data.getMonth()); // Remove o dia, mantendo apenas o ano e mês
          })
          .map(date => date.getTime()) // Converte para milissegundos para facilitar a deduplicação
      )
    ).map(time => new Date(time)); // Converte de volta para Date

    // LogService.log('periodos vazios', listarPeriodosVazios(this.competencias));

    this.datasetsVazios = listarPeriodosVazios(this.competencias).map(periodo => ({
      label: `Sem Remuneração entre ${this.datePipe.transform(periodo.dataInicio, 'MM/yyyy')} e ${this.datePipe.transform(periodo.dataFim, 'MM/yyyy')}`,
      data: [
        { x: new Date(periodo.dataInicio), y: 1 },
        { x: new Date(periodo.dataFim), y: 1 }
      ],
      borderColor: 'DeepSkyBlue',
      borderWidth: 2,
      fill: true,
      showLine: true,
      pointRadius: 5, // Remover os pontos
      pointBackgroundColor: 'DeepSkyBlue',
      type: 'line', // Adiciona as linhas de períodos vazios

    }));

    agruparCompetenciasEmPeriodos(this.competencias.filter(competencia=>(new Date(competencia)) < (new Date(1994,7,1)))).map(periodo=>{
      // LogService.log('periodo pre plano real', periodo);
    });

    this.datasetsPrePlanoReal = agruparCompetenciasEmPeriodos(this.competencias.filter(competencia=>(new Date(competencia)) < (new Date(1994,7,1)))).map(periodo => ({
      
      label: `Remunerações pré plano real entre ${this.datePipe.transform(periodo.dataInicio, 'MM/yyyy')} e ${this.datePipe.transform(periodo.dataFim, 'MM/yyyy')}`,
      data: [
        { x: new Date(periodo.dataInicio), y: 1 },
        { x: new Date(periodo.dataFim), y: 1 }
      ],
      borderColor: 'gold',
      borderWidth: 2,
      fill: true,
      showLine: true,
      pointRadius: 5, // Remover os pontos
      pointBackgroundColor: 'gold',
      type: 'line', // Adiciona as linhas de períodos vazios

    }));

    

    this.datasetSalariosContribuicao = {
      label: "Salários de Contribuição",
      borderColor: 'black',
      borderWidth: 2,
      fill: true,
      showLine: true,
      pointRadius: 0, // Remover os pontos
      data: Array.from(
        this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias
          .flatMap(sequencia => sequencia.contribuicoes)
          .reduce((acc, contribuicao) => {
            const data = new Date(contribuicao.competencia);
            const key = new Date(data.getFullYear(), data.getMonth()).getTime();

            if (!acc.has(key)) {
              acc.set(key, 0);
            }

            acc.set(key, acc.get(key)! + contribuicao.valor);
            return acc;
          }, new Map<number, number>())
      ).map(([key, valor]) =>
        key < new Date(1994, 7, 1).getTime() ? 0 : valor
      ),
      backgroundColor: 'black'
    }

    this.datasetSalariosContribuicaoLimitadoTeto = {
      label: "Salários de Contribuição Limitados ao Teto do INSS",
      borderColor: 'tomato',
      borderWidth: 2,
      fill: true,
      showLine: true,
      pointRadius: 0, // Remover os pontos
      data: Array.from(
        this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias
          .flatMap(sequencia => sequencia.contribuicoes)
          .reduce((acc, contribuicao) => {
            const data = new Date(contribuicao.competencia);
            const key = new Date(data.getFullYear(), data.getMonth()).getTime();

            // Aplicação da lógica do teto do INSS
            const valorContribuicao = contribuicao.valor > contribuicao.tetoCompetencia ? contribuicao.tetoCompetencia : contribuicao.valor;

            if (!acc.has(key)) {
              acc.set(key, 0);
            }

            acc.set(key, acc.get(key)! + valorContribuicao);
            return acc;
          }, new Map<number, number>())
      ).map(([key, valor]) =>
        key < new Date(1994, 7, 1).getTime() ? 0 : valor
      ),
      backgroundColor: 'tomato'
    }

    this.datasetTetoInss = {
      label: "Teto do INSS",
      borderColor: 'violet',
      borderWidth: 2,
      fill: true,
      showLine: true,
      pointRadius: 0, // Remover os pontos
      data: Array.from(
        this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias
          .flatMap(sequencia => sequencia.contribuicoes)
          .reduce((acc, contribuicao) => {
            const data = new Date(contribuicao.competencia);
            const key = new Date(data.getFullYear(), data.getMonth()).getTime();

            // Aplicação da lógica do teto do INSS
            const valorContribuicao = contribuicao.tetoCompetencia;

            if (!acc.has(key)) {
              acc.set(key, 0);
            }

            acc.set(key, acc.get(key)! + valorContribuicao);
            return acc;
          }, new Map<number, number>())
      ).map(([key, valor]) =>
        key < new Date(1994, 7, 1).getTime() ? 0 : valor
      ),
      backgroundColor: 'violet'
    }

    this.datasetSalariosAtualizados = {
      label: "Salários Atualizados",
      borderColor: 'limegreen',
      borderWidth: 2,
      fill: true,
      showLine: true,
      pointRadius: 0, // Remover os pontos
      data: Array.from(
        this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias
          .flatMap(sequencia => sequencia.contribuicoes)
          .reduce((acc, contribuicao) => {
            const data = new Date(contribuicao.competencia);
            const key = new Date(data.getFullYear(), data.getMonth()).getTime();

            // Aplicação da lógica do teto do INSS
            const valorContribuicao = contribuicao.valorAtualizado;

            if (!acc.has(key)) {
              acc.set(key, 0);
            }

            acc.set(key, acc.get(key)! + valorContribuicao);
            return acc;
          }, new Map<number, number>())
      ).map(([key, valor]) =>
        key < new Date(1994, 7, 1).getTime() ? 0 : valor
      ),
      backgroundColor: 'limegreen'
    }

    this.datasetSalarioMinimo = {
      label: "Salário Mínimo",
      borderColor: 'crimson',
      borderWidth: 2,
      fill: true,
      showLine: true,
      pointRadius: 0, // Remover os pontos
      data: Array.from(
        this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias
          .flatMap(sequencia => sequencia.contribuicoes)
          .reduce((acc, contribuicao) => {
            const data = new Date(contribuicao.competencia);
            const key = new Date(data.getFullYear(), data.getMonth()).getTime();

            // Aplicação da lógica do teto do INSS
            const valorContribuicao = contribuicao.salarioMinimoCompetencia;

            if (!acc.has(key)) {
              acc.set(key, 0);
            }

            acc.set(key, acc.get(key)! + valorContribuicao);
            return acc;
          }, new Map<number, number>())
      ).map(([key, valor]) =>
        key < new Date(1994, 7, 1).getTime() ? 0 : valor
      ),
      backgroundColor: 'crimson'
    }







    function listarPeriodosVazios(competencias: Date[]): { dataInicio: Date; dataFim: Date }[] {
      if (competencias.length === 0) return [];

      // Ordenar as datas para garantir a sequência correta
      competencias.sort((a, b) => a.getTime() - b.getTime());

      const periodosVazios: { dataInicio: Date; dataFim: Date }[] = [];
      const dataMaisAntiga = competencias[0];
      const dataMaisRecente = competencias[competencias.length - 1];

      // Verificar intervalos vazios entre as competências
      for (let i = 1; i < competencias.length; i++) {
        const dataAtual = competencias[i];
        const dataAnterior = competencias[i - 1];

        const proximaDataEsperada = new Date(dataAnterior.getFullYear(), dataAnterior.getMonth() + 1);

        if (dataAtual.getTime() !== proximaDataEsperada.getTime()) {
          const dataFimVazio = new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 1);

          // Calcular a duração do espaço vazio em meses
          const mesesDeDiferenca = (dataFimVazio.getFullYear() - proximaDataEsperada.getFullYear()) * 12
            + dataFimVazio.getMonth() - proximaDataEsperada.getMonth();

          if (mesesDeDiferenca > 0) {
            periodosVazios.push({ dataInicio: proximaDataEsperada, dataFim: dataFimVazio });
          }
        }
      }

      // Verificar se há um período vazio antes da primeira competência
      const dataLimiteAnterior = new Date(dataMaisAntiga.getFullYear(), dataMaisAntiga.getMonth() - 1);
      if (dataMaisAntiga.getTime() !== new Date(dataMaisAntiga.getFullYear(), dataMaisAntiga.getMonth(), 1).getTime()) {
        const mesesDeDiferenca = (dataMaisAntiga.getFullYear() - dataLimiteAnterior.getFullYear()) * 12
          + dataMaisAntiga.getMonth() - dataLimiteAnterior.getMonth();

        if (mesesDeDiferenca > 1) {
          periodosVazios.unshift({ dataInicio: new Date(dataLimiteAnterior.getFullYear(), dataLimiteAnterior.getMonth() + 1), dataFim: new Date(dataMaisAntiga.getFullYear(), dataMaisAntiga.getMonth() - 1) });
        }
      }

      // Verificar se há um período vazio após a última competência
      const dataLimitePosterior = new Date(dataMaisRecente.getFullYear(), dataMaisRecente.getMonth() + 1);
      const mesesDeDiferencaFinal = (dataMaisRecente.getFullYear() - dataLimitePosterior.getFullYear()) * 12
        + dataMaisRecente.getMonth() - dataLimitePosterior.getMonth();

      if (mesesDeDiferencaFinal > 1) {
        periodosVazios.push({ dataInicio: dataLimitePosterior, dataFim: new Date(dataMaisRecente.getFullYear(), dataMaisRecente.getMonth() + 1) });
      }

      return periodosVazios;
    }
    type Periodo = {
      dataInicio: Date;
      dataFim: Date;
    };
    
    function agruparCompetenciasEmPeriodos(competencias: Date[]): Periodo[] {
      if (competencias.length === 0) return [];
    
      // Ordenar as competências para garantir que estão em ordem cronológica
      competencias.sort((a, b) => a.getTime() - b.getTime());
    
      const periodos: Periodo[] = [];
      let dataInicio = competencias[0];
      let dataFim = competencias[0];
    
      for (let i = 1; i < competencias.length; i++) {
        const competenciaAtual = competencias[i];
        const competenciaAnterior = competencias[i - 1];
    
        // Calcular a diferença em meses entre a competência atual e a anterior
        const diffMeses =
          (competenciaAtual.getFullYear() - competenciaAnterior.getFullYear()) * 12 +
          competenciaAtual.getMonth() -
          competenciaAnterior.getMonth();
    
        if (diffMeses <= 1) {
          // Se a diferença é 1 mês ou menos, extende o período atual
          dataFim = competenciaAtual;
        } else {
          // Se a diferença é maior que 1 mês, fecha o período atual e começa um novo
          periodos.push({ dataInicio, dataFim });
          dataInicio = competenciaAtual;
          dataFim = competenciaAtual;
        }
      }
    
      // Adicionar o último período
      periodos.push({ dataInicio, dataFim });
    
      return periodos;
    }





    this.createChart();

    this.opcao = "Salários de Contribuição";
    this.selecionarOpcao('salariosContribuicao');

  }

  selecionarOpcao(opcao: string) {
    switch (opcao) {
      case 'salariosContribuicao':
        // LogService.log('opcao', opcao);
        this.opcao = "Salários de Contribuição";
        this.datasets = [
          this.datasetSalariosContribuicao,
          
        ];
        // this.datasets = [
        //   {
        //     label: "Salário de Contribuição",
        //     data: this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias
        //       .flatMap(sequencia => sequencia.contribuicoes)
        //       .map(contribuicao => new Date(contribuicao.competencia).getTime() < new Date(1994, 7, 1).getTime() ? 0 : contribuicao.valor),
        //     backgroundColor: 'black'
        //   },
        // ];

        if (this.chart) this.chart.destroy();

        this.createChart();
        break;



      case 'salariosContribuicaoLimiteTetoInss':
        // LogService.log('opcao', opcao);

        this.opcao = "Salários de Contribuição Limitados ao Teto do INSS";
        this.datasets = [
          this.datasetSalariosContribuicaoLimitadoTeto,
          
        ];


        if (this.chart) this.chart.destroy();

        this.createChart();
        break;


      case 'todos':
        // LogService.log('opcao', opcao);

        this.opcao = "Outras Opções";
        this.datasets = [
          this.datasetSalariosContribuicao,

          this.datasetSalariosContribuicaoLimitadoTeto,

          this.datasetTetoInss,

          this.datasetSalariosAtualizados,

          this.datasetSalarioMinimo,
          
        ];


        if (this.chart) this.chart.destroy();

        this.createChart();
        break;

      default:
        break;
    }
  }





  createChart() {

    let yPixel = null;
    let xPixel = null;
    let yValue = null;
    let xValue = null;

    const horizontalLinePlugin = {
      id: 'horizontalLinePlugin', // Adiciona um ID ao plugin
      afterEvent: function (chart, args) {
        const event = args.event;
        const nativeEvent = event.native;

        if (!(nativeEvent instanceof MouseEvent)) {
          return;
        }

        const canvasPosition = {
          x: nativeEvent.offsetX,
          y: nativeEvent.offsetY
        };

        // yPixel = chart.scales.y.getPixelForValue(chart.scales.y.getValueForPixel(canvasPosition.y));

        // Limitar o valor de yPixel para não ser desenhado abaixo do eixo x
        if (canvasPosition.y >= chart.scales.y.top && canvasPosition.y <= chart.scales.y.bottom) {
          yPixel = chart.scales.y.getPixelForValue(chart.scales.y.getValueForPixel(canvasPosition.y));
          xPixel = chart.scales.y.getPixelForValue(chart.scales.x.getValueForPixel(canvasPosition.x));
          yValue = chart.scales.y.getValueForPixel(canvasPosition.y).toFixed(2); // Armazena o valor y para o tooltip
          xValue = chart.scales.x.getValueForPixel(canvasPosition.x);
          chart.draw(); // Garante que a linha seja desenhada após o update
        } else {
          yPixel = null; // Reseta yPixel se o mouse estiver fora dos limites
          yValue = null; // Reseta yPixel e yValue se o mouse estiver fora dos limites
          xPixel = null;
          xValue = null;
        }


        // LogService.log('Y', yPixel);
        // LogService.log('X Value', new Date(xValue));

        // Desenhar a linha horizontal
        if (canvasPosition.y >= chart.scales.y.top && canvasPosition.y <= chart.scales.y.bottom) {
          chart.draw(); // Garante que a linha seja desenhada após o update
        }
      },
      afterDraw: (chart) => {
        if (yPixel !== null) {
          const ctx = chart.ctx;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(chart.scales.x.left, yPixel);
          ctx.lineTo(chart.scales.x.right, yPixel);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
          ctx.stroke();

          // Desenhar o tooltip
          const tooltipWidth = 100;
          const tooltipHeight = 40;
          const tooltipX = chart.scales.x.right - tooltipWidth - 10;
          const tooltipY = yPixel - tooltipHeight / 2;

          ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
          ctx.fillRect(tooltipX, tooltipY, tooltipWidth, tooltipHeight);

          // ctx.font = '12px Arial';
          ctx.fillStyle = 'white';
          ctx.textAlign = 'center';
          let labels: string[] = new Array()
          labels.push(this.currencyPipe.transform(yValue));
          labels.push(this.datePipe.transform(new Date(xValue), 'MM/yyyy'));

          // ctx.fillText(labels);
          // ctx.fillText(`R$ ${yValue}`, tooltipX + tooltipWidth / 2, tooltipY + tooltipHeight / 1.5);
          ctx.fillText(labels[0], tooltipX + tooltipWidth / 2, tooltipY + tooltipHeight / 3);
          ctx.fillText(labels[1], tooltipX + tooltipWidth / 2, tooltipY + (2 * tooltipHeight) / 3);

          ctx.restore();
        }
      }
    };

    // let dataMin:Date = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.flatMap(sequencia=>sequencia.contribuicoes).sort((a,b)=> (new Date(a.competencia)).getTime() - (new Date(b.competencia)).getTime())[0].competencia;
    // let dataMax:Date = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.flatMap(sequencia=>sequencia.contribuicoes).sort((a,b)=> (new Date(a.competencia)).getTime() - (new Date(b.competencia)).getTime()).reverse()[0].competencia;



    // Chart.register(horizontalLinePlugin);
    this.chart = new Chart(document.getElementById('canvasSalariosPreview') as HTMLCanvasElement, {
      type: 'bar', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: this.competencias,
        datasets: [...this.datasets, ...this.datasetsVazios, ...this.datasetsPrePlanoReal]
      },

      plugins: [horizontalLinePlugin],
      options: {
        scales: {
          x: {
            type: 'time',
            adapters: { date: { locale: ptBR } },
            display: true,
            max: (new Date(this.dataMax)).toISOString(),
            min: (new Date(this.dataMin)).toDateString(),
            time: {
              unit: 'year', // Define o intervalo de unidade como 'ano'
              displayFormats: {
                year: 'yyyy' // Formato para exibir o ano
              }
            },
            ticks: {
              stepSize: 1 // Mostra um ano por vez no eixo
            }

          },
          y: {
            display: false

          }
        },

        plugins: {
          legend: {
            // display: false

            labels: {
              boxHeight: 10,
              usePointStyle: true,

              pointStyle: 'line',
              // filter: item => !item.text.includes("Período")
            }
          },
          tooltip: {


            displayColors: false,

            callbacks: {
              label: (context) => {

                // LogService.log('log tooltip', context);
                let label: string[] = new Array();
                label.push(context.dataset.label || '');

                if (context.parsed.y > 2) {
                  label.push(this.currencyPipe.transform(context.parsed.y));
                }

                return label;

              },
              title: (tooltipItems) => {

                // LogService.log('log tooltip',tooltipItems);

                // return new Intl.DateTimeFormat('pt-BR', {
                // }).format(new Date(tooltipItems[0].parsed.x));

                return this.datePipe.transform(new Date(tooltipItems[0].parsed.x), 'MM/yyyy');
              },
            }
          },
        },
      },
    });

    this.chart.canvas.addEventListener('mouseleave', () => {
      // LogService.log('Y', yPixel)
      yPixel = null; // Reseta a variável ao sair do gráfico
      yValue = null; // Reseta as variáveis ao sair do gráfico
      this.chart.update(); // Limpa o gráfico quando o mouse sair
    });



  }
}
