import { Component, Input } from '@angular/core';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';

@Component({
  selector: 'app-retorno-financeiro-beneficio-kertzman',
  templateUrl: './retorno-financeiro-beneficio-kertzman.component.html',
  styleUrl: './retorno-financeiro-beneficio-kertzman.component.scss'
})
export class RetornoFinanceiroBeneficioKertzmanComponent {

  @Input() relatorio:RelatorioKertzman;
}
