<div *ngIf="beneficio">

  <div class="py-4">
    <h5 class=" h3 mb-0 linha-quadro">Memória de Cálculo do Benefício</h5>
  </div>

  <div class=" row m-0 p-0 py-2">

    <div class="col-4 col-xs-12">
      <p class="h2">
        Idade necessária
      </p>
      <p class="text-default">
        {{beneficio.idadeCarencia.porExtenso}}
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Idade em {{beneficio.dataCalculo | date : 'dd/MM/yyyy'}}
      </p>
      <p class="text-default">
        {{beneficio.idade.porExtenso}}
      </p>
    </div>

    <!-- <div class="col-4 col-xs-12">
      <p class="h2">
        Tempo para completar a idade
      </p>
      <p [className]="beneficio.idadeComplementar.totalDias > 0 ? 'text-danger' : 'text-success'">
        <mark role="button">
          {{beneficio.idadeComplementar.porExtenso}}
        </mark>

      </p>
    </div> -->
  </div>

  <div class=" row m-0 p-0 py-2">

    <div class="col-4 col-xs-12">
      <p class="h2">
        Tempo de Contribuição necessário
      </p>
      <p class="text-default">
        {{beneficio.tempoContribuicaoCarencia.porExtenso}}
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Tempo de Contribuição
      </p>
      <p class="text-default">
        {{beneficio.tempoContribuicao.porExtenso}}
      </p>
    </div>

    <!-- <div class="col-4 col-xs-12">
      <p class="h2">
        Tempo de Contribuição que ainda falta
      </p>
      <p [className]="beneficio.tempoContribuicaoComplementar.totalDias > 0 ? 'text-danger' : 'text-success'">
        {{beneficio.tempoContribuicaoComplementar.porExtenso}}
      </p>
    </div> -->

  </div>

  <div class=" row m-0 p-0 py-2">

    <div class="col-4 col-xs-12">
      <p class="h2">
        Carência necessária
      </p>
      <p class="text-default">
        {{beneficio.carenciaMeses.porExtenso}}
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Carência alcançada
      </p>
      <p class="text-default">
        {{beneficio.carenciaCumprida.porExtenso}}
      </p>
    </div>

    <!-- <div class="col-4 col-xs-12">
      <p class="h2">
        Carência que ainda falta
      </p>
      <p [className]="beneficio.carenciaComplementar.totalDias > 0 ? 'text-danger' : 'text-success'">
        {{beneficio.carenciaComplementar.porExtenso}}
      </p>
    </div> -->

  </div>

  <div *ngIf="beneficio.pontos > 0" class=" row m-0 p-0 py-2 ">

    <div class="col-4 col-xs-12">
      <p class="h2">
        Pontos necessários
      </p>
      <p class="text-default">
        {{beneficio.pontosCarencia}} pontos
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Pontos alcançados
      </p>
      <p class="text-default">
        {{beneficio.pontos}} pontos
      </p>
    </div>

    <!-- <div class="col-4 col-xs-12">
      <p class="h2">
        Pontos que ainda faltam
      </p>
      <p [className]="beneficio.pontosComplementares > 0 ? 'text-danger' : 'text-success'">
        {{beneficio.pontosComplementares}} pontos
      </p>
    </div> -->
  </div>

  <div class=" row m-0 p-0 py-2">

    <div class="col-4 col-xs-12">
      <p class="h2">
        PBC
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.pbc}}
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        PBC considerado
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.totalContribuicoesConsideradas}}
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Total de descartes
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.totalDescartesRealizados}}
      </p>
    </div>

  </div>

  <div class=" row m-0 p-0 py-2">

    <div class="col-4 col-xs-12">
      <p class="h2">
        Divisor
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.divisor }}
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Soma Salários Atualizados
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.somaSalariosAtualizados | currency}}
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Média Salários Atualizados
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.mediaSalariosAtualizados > 0 ? (beneficio.relatorioRendaMensalInicial.mediaSalariosAtualizados | currency) : (0 | currency)}}
      </p>
    </div>

  </div>

  <div class=" row m-0 p-0 py-2">

    <div class="col-4 col-xs-12">
      <p class="h2">
        Coeficiente
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.coeficiente | number : '1.0-0'}} %
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Salário de Benefício
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.salarioBeneficio > 0 ? (beneficio.relatorioRendaMensalInicial.salarioBeneficio | currency) : 0 | currency}}
      </p>
    </div>

    <div class="col-4 col-xs-12">
      <p class="h2">
        Média Salários Atualizados
      </p>
      <p class="text-default">
        <!-- {{beneficio.relatorioRendaMensalInicial.mediaSalariosAtualizados | currency}} -->
      </p>
    </div>

    <div *ngIf="beneficio.relatorioRendaMensalInicial.fator > 0" class="col-xl-2 col-sm-2">
      <p class="h2">
        Fator Previdenciário
      </p>
      <p class="text-default">
        {{beneficio.relatorioRendaMensalInicial.fator | number : '1.2-2' : 'pt-BR'}}
      </p>
    </div>

  </div>

  <div class="row m-0 p-0 py-2">
    <div class=" col-12 d-flex justify-content-end pt-5">

      <div class="col-4 col-xs-12">
        <p class="h2">
          Data Prevista
        </p>
        <p class="text-default">
          {{beneficio.dataCalculo | date : 'dd/MM/yyyy'}}
        </p>
      </div>

      <div class="col-4 col-xs-12">
        <p class="h2">
          Renda Mensal inicial
        </p>
        <p class="text-default">
          {{beneficio.relatorioRendaMensalInicial.rendaMensalInicial > 0 ? (beneficio.relatorioRendaMensalInicial.rendaMensalInicial | currency) : 0 |currency}}
        </p>
      </div>

    </div>
  </div>

</div>