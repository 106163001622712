import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name:'anonimo'})
export class AnonimoPipe implements PipeTransform{

    transform(value: string, ...args: any[]): string {

        let index = value.includes(" ") ? value.indexOf(" ") + 1 : value.length;
        return value.substring(0, index);
    }

}