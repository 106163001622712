export enum TiposMarcador{
    MARCADOR,
    TINTAFUNDO,
    RECORTESEQUENCIA,
    NOVASEQUENCIA,
    ALERTABASEMINIMO,
    ALERTAALIQUOTAREDUZIDA,
    ALERTACONCOMITANCIAAJUSTADA,
    ALERTACONCOMITANCIAAJUSTADAREDUZIDA,
    ALERTACONTRIBUICAOIMPLICITA

}