import { filter } from 'rxjs';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { CurrencyPipe, DatePipe } from '@angular/common';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';
import { RelatorioRMI } from 'src/app/modelos/previdenciario/relatoriormi';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { Colors } from 'chart.js';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';

@Component({
  selector: 'app-variacao-contribuicoes-originais-simulacao-kertzman',
  templateUrl: './variacao-contribuicoes-originais-simulacao-kertzman.component.html',
  styleUrls: ['./variacao-contribuicoes-originais-simulacao-kertzman.component.scss']
})
export class VariacaoContribuicoesOriginaisSimulacaoKertzmanComponent implements OnInit, OnChanges{

  @Input() relatorio:RelatorioKertzman;
  @Input() visualizarPdf:boolean=true;

  data:Contribuicao[];
  dataNaoOtimizacao:any[];
  dataOriginal:any[];
  dataOtimizacao:any[];
  dataOtimizada:any[];
  barColors:any[];

  aguardePdf:boolean;


  chart:Chart;

  media:number;
  constructor(private currency: CurrencyPipe, private datePipe: DatePipe,
    private listarPDFService:ListarPDFService,
    private notificacoesService:NotificacoesService) {
  }




  ngOnChanges(changes: SimpleChanges): void {
    if(this.chart){
      this.carregarDados();
    }
  }

  ngOnInit(): void {


    this.carregarDados();
    
  }

  carregarDados(){
    if(this.chart){this.chart.destroy(); this.chart = null;}

    this.barColors = [];
    this.data = this.relatorio.contribuicoesOtimizacao;
    this.dataOriginal = this.relatorio.contribuicoesOtimizacao.map(contribuicao=>{return {competencia:contribuicao.competencia, valor:contribuicao.valorAtualizado, otimizacao:contribuicao.otimizacao, otimizada:contribuicao.otimizada };});
    // this.dataNaoOtimizacao = this.caso.beneficio.relatorioRendaMensalInicial.contribuicoes.map(contribuicao=>{return {competencia:contribuicao.competencia, valor:contribuicao.valorAtualizado, otimizacao:contribuicao.otimizacao, otimizada:contribuicao.otimizada };});
    // this.dataOtimizacao = this.caso.beneficio.relatorioRendaMensalInicial.contribuicoes.map(contribuicao=>{return {competencia:contribuicao.competencia, valor:contribuicao.valorAtualizado, otimizacao:contribuicao.otimizacao, otimizada:contribuicao.otimizada };});
    // this.dataOtimizada = this.caso.beneficio.relatorioRendaMensalInicial.contribuicoes.map(contribuicao=>{return {competencia:contribuicao.competencia, valor:contribuicao.valorAtualizado, otimizacao:contribuicao.otimizacao, otimizada:contribuicao.otimizada };});

    // this.dataOriginal.map(data=>{

    //   this.barColors[this.dataOriginal.indexOf(data)] = '#172b4d';
    //   if (data.otimizacao) {
    //     this.barColors[this.dataOriginal.indexOf(data)] = '#2dce89';
    //   }
    // });

    this.media = this.dataOriginal.map(contribuicao=>contribuicao.valorAtualizado).reduce((soma, valor)=> soma+=valor) / this.dataOriginal.length;
    
    let datasetLabels = this.dataOriginal.map(contribuicao=>contribuicao.competencia);

    // console.log('contribuicoes otimizadas');
    // console.log(this.data.map(contribuicao=> contribuicao.otimizada));

    let dataset = {
      labels: datasetLabels, datasets: [
        {
          data: this.data.map(contribuicao=> contribuicao.planejamento === false && contribuicao.otimizada === false && contribuicao.qualidadeSegurado === false && contribuicao.otimizacao ===false ? contribuicao.valorAtualizado : 0),
          label: 'Originais atualizadas',
          backgroundColor: '#000000',
          barThickness: 1.5,
        },

        {
          data: this.data.map(contribuicao=> contribuicao.otimizada === true  ? contribuicao.valorAtualizado : 0),
          label: 'Originais descartadas',
          barThickness: 1.5,
          backgroundColor: '#D3D3D3',
        },

        // {
        //   data: this.data.map(contribuicao=> contribuicao.planejamento === true  ? contribuicao.valorAtualizado : 0),
        //   label: 'Previstos para cumprimento de carência',
        //   barThickness: 1.5,
        //   // backgroundColor: '#00ff00',
        // },


        {
          data: this.data.map(contribuicao=> contribuicao.otimizacao === true  ? contribuicao.valorAtualizado : 0),
          label: 'Novas Contribuições para Otimização',
          barThickness: 1.5,
          backgroundColor: '#8FBC8B',
        },

        {
          data: this.data.map(contribuicao=> contribuicao.qualidadeSegurado === true  ? contribuicao.valorAtualizado : 0),
          label: 'Manutenção da Qualidade de Segurado',
          barThickness: 1.5,
          backgroundColor: '#708090',
        },

      ]
    };


    // dataset.datasets = dataset.datasets.filter(data=>data.data.filter(valor=>valor > 0).length > 0);



    let chart = document.getElementById('variacaoContribuicoesOriginais') as HTMLCanvasElement;


    Chart.register(annotationPlugin);
    // Chart.register(Colors);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      
      options: {
        
        onClick: (e, activeEls) => {
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          // colors: {
          //   enabled: true
          // },
          annotation: {
            annotations: {
              

              // line1: {
              //   drawTime: 'afterDatasetsDraw',
              //   type: 'line',
              //   yMin: this.media.toFixed(2),
              //   yMax: this.media.toFixed(2),
              //   xMin: new Date(1994,7,1).toISOString(),
              //   xMax: new Date(datasetLabels[0]).toISOString(),
              //   borderColor: '#000000',
              //   borderWidth: 2,
              //   label: {
              //     position: 'center',
              //     display: true,
              //     // font:{
              //     //   size:24,
              //     //   weight:'normal',
              //     // },
              //     content: (ctx, options) => {
              //       return 'Média ' + this.currency.transform(this.media.toFixed(2));
              //     },
              //   }

              // },
            }
          },
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: 'Valores Atualizados dos Salários de Contribuição',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont:{
              // size:24,
              // weight:'normal',
            },
            titleFont:{
              // size:24,
              // weight:'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.currency.transform(context.parsed.y);
                }
                return label;
              },
              title: (tooltipItens) => {
                return "Competência " + this.datePipe.transform(tooltipItens[0].parsed.x, 'MM/yyyy');
              },

            }
          }
        },

        
        scales: {
          y: {
            beginAtZero: true,
            

            ticks: {
              callback: (value, index, ticks) => {
                return this.currency.transform(value);
              }
            }

          },
          x: {
            type: 'time',
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Competência do Salário de Contribuição'
            },




          }
        }
      }

    });


  }


  gerarPDF(){

    // this.aguardePdf = true;
    // this.caso.tipoCalculo = CalculoTipos.VARIACAOCONTRIBUICOES;
    // this.caso.usuario = Global.usuario;

    // this.listarPDFService.ListarPDF(this.caso)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'VARIACAO-SALARIO-CONTIRBUICAO-PLANEJAMENTO-CONTRIBUTIVO-KERTZMAN-.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

    //     this.aguardePdf = false;

    //   });
  }

}
