import { PeriodoContributivo } from './../../../../../modelos/previdenciario/periodocontributivo';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart, Interaction } from 'chart.js';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { CalculoMapaQualidadeSegurado } from 'src/app/modelos/previdenciario/calculomapaqualidadesegurado';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';

import 'chartjs-adapter-date-fns';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-mapa-consolidado-qualidade-segurado',
  templateUrl: './mapa-consolidado-qualidade-segurado.component.html',
  styleUrls: ['./mapa-consolidado-qualidade-segurado.component.scss']
})
export class MapaConsolidadoQualidadeSeguradoComponent implements OnInit, OnChanges {

  @Input() visualizarPdf: boolean = true;
  @Input() calculoMapaQualidadeSegurado: CalculoMapaQualidadeSegurado;

  chart: Chart;
  data;
  aguardePdf: boolean;

  constructor(private currency: CurrencyPipe, private datePipe: DatePipe, private decimalPipe: DecimalPipe,
    private listarPDFService: ListarPDFService,
    private calculeiApiService:CalculeiApiService,
    private configService:ConfigService,
    private notificacoesService: NotificacoesService) {
      this.configService.getAguarde().subscribe(aguarde=>this.aguardePdf=aguarde);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart)
      this.carregarDados();
  }


  ngOnInit(): void {
    this.carregarDados();
  }

  carregarDados() {
    if (this.chart) { this.chart.destroy(); this.chart = null; }

    this.data = this.calculoMapaQualidadeSegurado.manutencaoConsolidada.sort((a, b) => (new Date(a.periodo.inicio)).getTime() - (new Date(b.periodo.inicio)).getTime());

    let datasetLabels = this.data.map(manutencao => new Date(manutencao.periodo.inicio));//Array.from(Array(this.data.length).keys());

    // let colors = this.caso.simulacoes.map(caso=>caso.tempoRetornoInvestimentoMesesAbono === this.caso.tempoRetornoInvestimentoMesesAbono ? "#ffd600" : "#172b4d" )



    let dataset = {
      labels: datasetLabels,
      datasets: [
        {
          data: this.data.map(manutencao => { return [manutencao.periodo.inicio, manutencao.vencimento] }),
          label: 'Periodo de Graça',
          barPercentage: 0.5,
        }
      ]
    };



    let chart = document.getElementById('mapaQualidadeSeguradoConsolidado') as HTMLCanvasElement;



    document.getElementById("wrapperConsolidado").style.height = ((this.data.length * 15) + 100).toString() +'px';

    Chart.register(annotationPlugin);
    Chart.register(CrosshairPlugin);
    Interaction.modes.point = Interpolate;
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,

      options: {
        devicePixelRatio: 2,
        maintainAspectRatio: false,
        responsive:true,
        onClick: (e, activeEls) => {
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          // (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          annotation: {
            annotations: {
            }
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Períodos de Graça',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              // size: 24,
              // weight: 'normal',
            },
            titleFont: {
              // size: 24,
              // weight: 'normal',

            },
            callbacks: {
              label: (context) => {

                let linhas = new Array<string>();
                linhas.push("Inicio: " + this.datePipe.transform(this.data[context.dataIndex].periodo.inicio, 'dd/MM/yyyy'));
                linhas.push("Fim:  " + this.datePipe.transform(this.data[context.dataIndex].vencimento, 'dd/MM/yyyy'));
                
                return linhas;
              },
              title: (tooltipItens) => {
                
                return "Período de Graça Consolidado"; //tooltipItens[0].label; //this.caso.simulacoes[tooltipItens[0].dataIndex].totalSubstituicoesTeto + " Contribuições de Otimização";
              },
              

            }
          }
        },
        indexAxis: 'y',
        scales: {

          y: {

            beginAtZero: true,

            grid: {
              display: true,
            },

            title: {
              display: false,
              text: "Competências"
            },

            ticks: {
              autoSkip: false,
              callback: (value, index, ticks) => {

                return index + 1;
              }
            }

          },
          x: {


            grid: {
              display: true,
            },
            type: 'time',
            time: {
              unit: 'year',

            },

            ticks: {

              padding: 10,
              autoSkipPadding: 0,
              backdropPadding: 0,


              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: false,
              text: ''
            },




          }
        }
      }

    });
  }


  gerarPdf() {

    this.configService.setAguarde(true);
    this.calculoMapaQualidadeSegurado.tipoCalculo = CalculoTipos.MAPAQUALIDADESEGURADOCONSOLIDADO;
    this.calculoMapaQualidadeSegurado.usuario = Global.usuario;


    this.calculeiApiService.apiBlobServices("ListarPDF", this.calculoMapaQualidadeSegurado)
    // this.calculeiApiService.apiBlob("ListarPDF", this.calculoPlanejamentoKertzman)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO-GRAFICO-MAPA-QUALIDADE-SEGURADO-' + this.calculoMapaQualidadeSegurado.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
        this.configService.setAguarde(false);
      });

  }

}
