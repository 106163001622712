import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import * as currency from 'currency.js';
import * as moment from 'moment';
import { AppComponent } from 'src/app/app.component';
import { ContagemCarencia } from 'src/app/modelos/previdenciario/contagemcarencia';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';
import { SeveridadePontoAtencaoCnis } from 'src/app/modelos/previdenciario/severidadepontoatencaocnis';
import { TipoContribuicao } from 'src/app/modelos/previdenciario/tipocontribuicao';
import { TiposIndicadoresCNIS } from 'src/app/modelos/previdenciario/tiposindicadorescnis';
import { TiposVinculos } from 'src/app/modelos/previdenciario/tiposvinculos';
import { LogService } from 'src/app/servicos/log.service';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { AguardeLocalComponent } from '../aguarde-local/aguarde-local.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-canvas-contribuicoes',
  templateUrl: './canvas-contribuicoes.component.html',
  styleUrl: './canvas-contribuicoes.component.scss'
})
export class CanvasContribuicoesComponent {

  @Input() sequencia: RelacaoPrevidenciaria;
  @Output() sequenciaChange = new EventEmitter<RelacaoPrevidenciaria>();
  @Output() habilitarSalvarEdicaoEvent = new EventEmitter<any>();
  @Output() atualizarCnisModificadoEvent = new EventEmitter<RelacaoPrevidenciaria>();
  @Output() atualizarCnisEvent = new EventEmitter<RelacaoPrevidenciaria>();

  @ViewChildren(AguardeLocalComponent) listaAguarde: QueryList<AguardeLocalComponent>;

  currencyInputMask = createMask(AppComponent.currencyInputOptionsSemPrefix);

  severidadePontoAtencaoCnisType = SeveridadePontoAtencaoCnis;
  tiposIndicadoresCnis = TiposIndicadoresCNIS;
  
  dateInputMask = createMask<Date>(AppComponent.dateInputCompetenciaOptions);

  tiposVinculosType = TiposVinculos;

  constructor(private datePipe: DatePipe){}


  switchCarenciaContribuicao(event, contribuicao: Contribuicao) {
    let status: boolean = event.target.checked;
    // console.log('switch carencia', event.target.checked);
    contribuicao.carencia = status;


    this.atualizarCnisModificadoEvent.emit(this.sequencia);
    this.habilitarSalvarEdicaoEvent.emit(true);
  }

  editarCompetenciaContribuicao(vinculo: RelacaoPrevidenciaria, contribuicao: Contribuicao, idInput: string, idEditor: string) {

    // console.log('id input competencia', idInput);
    // console.log('id editor competencia', idEditor);


    let edicao: string = (<HTMLInputElement>document.getElementById(idInput)).value;
    let dataCompetencia: Date = moment(edicao, "MM/YYYY").toDate();
    contribuicao.competencia = dataCompetencia;

    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    editor.classList.add('collapse');


    this.habilitarSalvarEdicaoEvent.emit(true);
    this.atualizarCnisModificadoEvent.emit(this.sequencia);


  }


  editarSalarioContribuicao(contribuicao: Contribuicao, idInput: string, idEditor: string) {

    let edicao: string = (<HTMLInputElement>document.getElementById(idInput)).value;
    let valor: number = currency(edicao, { separator: '.', decimal: ',' }).value;
    contribuicao.valor = valor;

    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    editor.classList.add('collapse');


    LogService.log('contribuicao editada', contribuicao);
    LogService.log('sequencia editada', this.sequencia);
    this.habilitarSalvarEdicaoEvent.emit(true);

    this.atualizarCnisModificadoEvent.emit(this.sequencia);

  }


  cancelEditarCompetenciaContribuicao(idEditor: string) {
    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    editor.classList.add('collapse');
  }

  cancelEditarSalarioContribuicao(idEditor: string) {
    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    editor.classList.add('collapse');
  }

  excluirContribuicao(sequencia: RelacaoPrevidenciaria, contribuicao: Contribuicao) {
    let indexContribuicao = sequencia.contribuicoes.indexOf(contribuicao);
    sequencia.contribuicoes.splice(indexContribuicao, 1);
    // LogService.log('excluir contribuicao', contribuicao);
    this.atualizarCnisModificadoEvent.emit(sequencia);
    this.habilitarSalvarEdicaoEvent.emit(true);
  }

  changeDataInicio(event, contribuicao: Contribuicao, index: number) {
    // console.log('data inicio change', event.value, sequenciaView.index);
    // this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias[index].dataInicio = new Date(event.value);
    // this.grid.refresh();
  }

  novaContribuicao(sequencia: RelacaoPrevidenciaria, idInputCompetencia: string, idInputValor: string, idEditor: string) {


    let edicaoCompetencia: string = (<HTMLInputElement>document.getElementById(idInputCompetencia)).value;
    let dataCompetencia: Date = moment(edicaoCompetencia, "MM/yyyy").toDate();

    let edicaoValor: string = (<HTMLInputElement>document.getElementById(idInputValor)).value;
    let valor: number = currency(edicaoValor, { separator: '.', decimal: ',' }).value;


    let contribuicao: Contribuicao = new Contribuicao();
    contribuicao.rowKey = uuidv4();

    contribuicao.valor = valor;
    contribuicao.competencia = dataCompetencia;
    contribuicao.indicadores = new Array();
    contribuicao.indicadoresCnis = new Array();
    contribuicao.carencia = true;
    contribuicao.contagemCarencia = ContagemCarencia.AUTOMATICO;

    contribuicao.tipo = TipoContribuicao.RECOLHIMENTO;

    // console.log('nova contribuicao', contribuicao);
    // console.log('contribuicoe sequencias', sequencia.contribuicoes.length);
    sequencia.contribuicoes.push(contribuicao);
    // console.log('contribuicoe sequencias nova', sequencia.contribuicoes.length);
    // sequencia.contribuicoes.sort((a, b) => (new Date(a.competencia)).getTime() < (new Date(b.competencia)).getTime())
    sequencia.contribuicoes.sort((a, b) => { return new Date(a.competencia).getTime() - new Date(b.competencia).getTime() });

    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    editor.classList.add('collapse');

    this.sequenciaChange.emit(sequencia);
    this.atualizarCnisModificadoEvent.emit(sequencia);
    this.habilitarSalvarEdicaoEvent.emit(true);

  }



  changeContribuicao(event, contribuicao: Contribuicao, index: number) {
    var editor = document.getElementById("editorCompetencia" + index) as HTMLInputElement;
    var aguarde: AguardeLocalComponent = this.listaAguarde.filter(aguarde => aguarde.id === "aguardeCompetencia" + index)[0];

    LogService.log('competencia change', editor.value);
    LogService.log('competencia aguarde change', aguarde.aguarde);

    // aguarde.aguarde = true;


    let novaData: Date = AppComponent.stringToDateCompetencia(editor.value);
    if (novaData) {
      contribuicao.competencia = novaData;
      // this.sequenciaChange.emit(sequencia);
      this.atualizarCnisModificadoEvent.emit(this.sequencia);
      this.habilitarSalvarEdicaoEvent.emit(true);
      // this.atualizarPrepCnis().then(resultado => {
      //   this.habilitarSalvarEdicaoEvent.emit(true);
      //   aguarde.aguarde = false;
      // });


    } else {

      editor.value = this.datePipe.transform(contribuicao.competencia, 'MM/yyyy');
      setTimeout(() => {
        aguarde.aguarde = false;
      }, 200);

    }
  }


  editarTipoVinculo(contribuicao: Contribuicao, tipo: TiposVinculos) {
    contribuicao.tipoVinculo = tipo;
    contribuicao.tipoVinculoEdicao = tipo;
    contribuicao.tipoVinculoConsiderarEdicao = true;
    LogService.log("editar tipo vinculo contribuicao", contribuicao);
    LogService.log("editar tipo vinculo sequencia", this.sequencia);
    this.atualizarCnisEvent.emit(this.sequencia);
    
  }

}
