<div *ngIf="matriz">
    <!-- <div class="row p-2">
        <div class="col-md-6">
            <img src={{logoUrl}} width="100px">
        </div>
    
        <div class="col-md-6 text-right">
            <p class="font-weight-bold mb-1 h1">Relatório de Planejamento Contributivo Previdenciário
            </p>
            <p class="mb-1 h2">Cliente {{ calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}</p>
        </div>
    </div> -->

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1  fw-lighter text-default">Planos de Aposentadoria</span>
        </div>
    </div>

    <div class="row pt-2 pb-6 p-2">
        <div class="col-6">
            <p class="fw-lighter mb-4 display-3 text-default">Cliente</p>

            <p class="mb-2">
                <!-- <span class="text-default h1">Nome </span> -->
                <span class="display-1 fw-lighter">
                    {{matriz.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}
                </span>
            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    CPF
                </span>
                <span class="h1 text-default">
                    {{matriz.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.cpf}}
                </span>
            </p>
            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Sexo
                </span>
                <span class="h1 text-default">
                    {{matriz.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo }}

                </span>

            </p>
            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Data de nascimento
                </span>
                <span class="h1 text-default">
                    {{matriz.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}

                </span>

            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Idade
                </span>
                <span class="h1 text-default">
                    {{matriz.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.idade.porExtenso}}
                </span>

            </p>

        </div>

        <div class="col-6">
            <p class="fw-lighter mb-4 display-3 text-default">Plano indicado</p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Data do Planejamento
                </span>
                <span class="h1 text-default">
                    {{matriz.data | date : 'dd/MM/yyyy'}}
                </span>
            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Regra
                </span>
                <span class="h1 text-default">
                    {{matriz.casoReferencia.qualificacaoReferencia}}
                </span>
            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Salário base da contribuição
                </span>
                <span class="h1 text-default">
                    {{matriz.casoReferencia.baseContributiva | currency}}
                </span>
            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Alíquota
                </span>
                <span class="h1 text-default">
                    {{matriz.casoReferencia.aliquotaContributiva * 100 | number : '1.0-0'}} %
                </span>
            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Valor do Benefício
                </span>
                <span class="h1 text-default">
                    {{matriz.casoReferencia.valorBeneficio | currency}}
                </span>
            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Lucro estimado
                </span>
                <span class="h1 text-default">
                    {{matriz.casoReferencia.retornoTotalInvestimentoRoi | currency}}
                </span>
            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Data Prevista
                </span>
                <span class="h1 text-default">
                    {{matriz.casoReferencia.dataAposentadoria | date : 'dd/MM/yyyy'}}
                </span>
            </p>

            <p class="mb-2">
                <span class="text-default fw-lighter d-block mb-0">
                    Idade na data da Aposentadoria
                </span>
                <span class="h1 text-default">
                    {{matriz.casoReferencia.idadeDataAposentadoria.porExtenso}}
                </span>
            </p>

        </div>

    </div>

    <div class="row py-4">
        <div class="col-12">
            <p class="fw-lighter mb-4 display-3 text-default">Planos analisados</p>
            <app-tabela-planos-relatorio-matriz [matriz]="matriz"></app-tabela-planos-relatorio-matriz>
        </div>

    </div>

    <div class="row py-4">
        <div class="col-12">
            <p class="fw-lighter mb-4 display-3 text-default">Planos por valor do benefício</p>
            <app-heat-map-matriz [referencia]="'valorBeneficio'" [esconderControles]="true" [calculoMatrizPlanejamento]="matriz"></app-heat-map-matriz>
        </div>
    </div>

    <!-- <div *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio">
        
        
        <app-destaque-kertzman [visualizarPdf]="false"  #destaqueKertzman *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-destaque-kertzman>
    
        <app-variacao-contribuicoes-originais-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-contribuicoes-originais-simulacao-kertzman>
    
        <app-variacao-rmi-simulacao-kertzman-chart  [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-variacao-rmi-simulacao-kertzman-chart>
    
        <app-variacao-payback-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-payback-simulacao-kertzman>
    
        <app-variacao-taxa-payback-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-variacao-taxa-payback-simulacao-kertzman>
    
        <app-variacao-lucro-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-lucro-simulacao-kertzman>

        

        <app-comparativo-capitalizacao-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-comparativo-capitalizacao-kertzman>


        
        <app-retorno-financeiro-beneficio-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-retorno-financeiro-beneficio-kertzman>
        <app-imposto-renda-contribuinte-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-imposto-renda-contribuinte-kertzman>
        
    
    </div> -->

</div>