<div>
    <div class="row pb-2">
        <div class="col">
            <h5 class=" h3 mb-0 linha-quadro">Imposto de Renda
                <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroListagemImpostoRendaAnual" aria-expanded="false" aria-controls="helpQuadroListagemImpostoRendaAnual"></i>
            </h5>

            <div class="collapse" id="helpQuadroListagemImpostoRendaAnual">

                <div class="row py-4 d-flex ">

                    <div class="col-3 py-2 d-flex">
                        <div class="row w-100 bg-secondary rounded m-2">
                            <div class="col d-flex p-2">

                                <p class=" p-2 lh-1 text-justify">
                                    Listagem anual de custo de imposto de renda
                                </p>

                            </div>
                            <!-- <div class="mt-auto pb-2 text-right">
                                <i class="comando bi bi-cash-coin fs-3"></i>
                            </div> -->

                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>
    <div class="row">
        <div class="col">

            <div class>

                <div class="row">
                    <div class="col-4">
                        <div>
                            <div>
                                <p class="text-center fw-lighter h6 text-uppercase">Custo</p>
                            </div>
                            <p class="display-1 lh-1  mb-0">
                                {{caso.custoContributivoTotalImpostoRenda | currency}}

                            </p>
                            <p class="lh-1 fw-lighter">
                                Custo total Imposto de Renda
                            </p>

                        </div>
                    </div>
                    <div class="col-8">
                        <div class="" style="height: 300px;">
                            <canvas id="chartImpostoRendaAnual"></canvas>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>
</div>
