<div class="row pb-2">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro"></h5>
        <div class="row">
            <div class="col-6">
                <h5 class=" h3 mb-0">Simulação do Plano
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpResumoSimulacao" aria-expanded="false" aria-controls="helpResumoSimulacao"></i>
                </h5>
            </div>
            <div class="col-6">
                <div class="text-right">
                    <!-- <i class="comando bi bi-filetype-pdf fs-6 " role="button" container="body" (click)="gerarPdf()" ngbTooltip="Salvar PDF" triggers="hover"></i> -->
                </div>
            </div>
        </div>

        <div class="collapse" id="helpResumoSimulacao">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row w-100 bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1">
                                Resumo da simulação do plano
                            </p>

                        </div>
                        <!-- <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-cash-coin fs-3"></i>
                        </div> -->

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div class="row">
    <ng-container *ngIf="caso?.pontosSimulacao == 0">
        <div class="col-4">
            <div>
                <div>
                    <p class="text-center fw-lighter h6 text-uppercase">Idade</p>
                </div>
                <p class="display-3 fw-light lh-1  mb-0">
                    {{caso?.tempoIdadeSimulacao?.porExtenso}}
                </p>
                <p class="lh-1 fw-light">
                    Tempo de idade para completar o plano
                </p>
            </div>
    
        </div>
    
        <div class="col-4">
            <div>
                <div>
                    <p class="text-center fw-lighter h6 text-uppercase">Tempo de Contribuição</p>
                </div>
    
                <p class="display-3 fw-light lh-1  mb-0">
                    {{caso?.tempoContribuicaoSimulacao?.porExtenso}}
                </p>
                <p class="lh-1 fw-light">
                    Tempo de contribuição para completar o plano 
                </p>
    
            </div>
    
        </div>
    </ng-container>

    <ng-container *ngIf="caso?.pontosSimulacao > 0">
        <div class="col-4">
            <div>
                <div>
                    <p class="text-center fw-lighter h6 text-uppercase">Pontos</p>
                </div>
    
                <p class="display-3 fw-light lh-1  mb-0">
                    {{caso?.pontosSimulacao}}
                </p>
                <p class="lh-1 fw-light">
                    Pontos para completar o plano
                </p>
    
            </div>
    
        </div>
    </ng-container>

    <div class="col-4">
        <div>
            <div>
                <p class="text-center fw-lighter h6 text-uppercase">Parcelas de Contribuição</p>
            </div>

            <p class="display-3 fw-light lh-1  mb-0">
                {{caso?.contribuicoesSimulacao}}
            </p>
            <p class="lh-1 fw-light">
                Total de parcelas para completar o plano
            </p>
        </div>

    </div>

</div>