

<!--scroll tabela https://stackoverflow.com/questions/63666463/table-width-100-with-overflow-scroll -->
<div>

  <div *ngIf="!aguarde && calculoMatrizPlanejamento && calculoMatrizPlanejamento.casos && calculoMatrizPlanejamento.casos.length > 0 ">

    <!-- <app-destaque-beneficio *ngIf="destaque" [destaque]="destaque"
      (relatorioCompletoEvent)="verRelatorioCompleto($event)"></app-destaque-beneficio> -->

    <div class="row">
      <div class="col-12">
        <div class="row py-2  flex d-flex justify-content-between">

          <div class="col-auto flex d-flex">
            <div class="col-auto p-0 m-0">
              <i class="comando bi bi-file-earmark-arrow-down fs-6 " (click)="pdfMatriz()" role="button" container="body" ngbTooltip="Relatório em PDF" triggers="hover"></i>
            </div>

            <!-- <div class="col-auto p-0 m-0">
              <i class="comando bi bi-bookmarks fs-6 "></i>
            </div> -->

          </div>
          <!-- 
          <div class="col-auto flex d-flex">
            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="pdfMatriz()" container="body" ngbTooltip="Exportar tabela em PDF">
                <i class="comando bi bi-filetype-pdf fs-6 "></i>
              </a>
            </div>
      
            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" container="body" ngbTooltip="Exportar tabela em excel">
                <i class="comando bi bi-filetype-xls fs-6 "></i>
              </a>
            </div>
          </div> -->

        </div>
      </div>
    </div>

    <div class="pb-4">
      <app-destaque-melhor-cenario *ngIf="calculoMatrizPlanejamento && calculoMatrizPlanejamento.casos" [calculoMatrizPlanejamento]="calculoMatrizPlanejamento" (dastaqueCanvasEvent)="abrirCasoCanvas($event)"></app-destaque-melhor-cenario>
    </div>

    <div class="pb-4">
      <app-heat-map-matriz *ngIf="calculoMatrizPlanejamento && calculoMatrizPlanejamento.casos" [calculoMatrizPlanejamento]="calculoMatrizPlanejamento" (cenarioCanvasEvent)="abrirCasoCanvas($event)"></app-heat-map-matriz>
    </div>

    <div class="pb-4">
      <app-tabela-matriz *ngIf="calculoMatrizPlanejamento" [calculoMatrizPlanejamento]="calculoMatrizPlanejamento" (atualizacaoMatrizEvent)="atualizarMatriz($event)" (abrirPlanoEvent)="abrirCasoCanvas($event)"></app-tabela-matriz>
    </div>

  </div>
</div>

<!-- <div class="py-4">
  <app-tabela-custo-oportunidade *ngIf="calculoMatrizPlanejamento && calculoMatrizPlanejamento.matrizCustoOportunidade" [calculoMatrizPlanejamento]="calculoMatrizPlanejamento"></app-tabela-custo-oportunidade>
</div> -->

<div class="offcanvas h-100 offcanvas-bottom" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasCaso" aria-labelledby="canvasCaso">

  <div class="offcanvas-header ml-6 mr-2 justify-content-between">
    <div *ngIf="casoCanvas" class="w-100 mr-4">
      <div class="row d-flex">
        <div class="col-auto " *ngIf="casoCanvas && casoCanvas.beneficio">
          <span class="h1 lh-1 fw-lighter"> {{casoCanvas.beneficio.qualificacaoDetalhada}}</span>

        </div>
        <div class="col-auto p-0">

          <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpSequencia" aria-expanded="false" aria-controls="helpSequencia"></i>

        </div>
      </div>

      <div class="collapse" id="helpSequencia">
        <p class="lh-1 py-2">
          Detalhamento da simulação
        </p>

      </div>
    </div>
    <!-- <span *ngIf="sequencia" class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Sequência {{sequencia.sequencia}}</span> -->
    <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
    <div class="row flex d-flex align-items-top justify-content-end">

      <div class="hstack">
        <!-- <div [className]="aguarde ? 'visible': 'invisible'">
            <div class="mx-4">
              <app-aguarde-local [aguarde]="aguarde"></app-aguarde-local>
            </div>
          </div> -->
        <div class>
          <i role="button" class="comando bi bi-filetype-pdf fs-6" (click)="gerarPDFCaso(casoCanvas)" container="body" ngbTooltip="PDF"></i>

        </div>

        <div class>
          <i role="button" class="comando bi bi-x fs-6" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar" (click)="fecharCanvasCaso()"></i>

        </div>
      </div>

    </div>

  </div>
  <div class="offcanvas-body ml-6 mr-2">

    <div class="py-2">
      <!-- <app-relatorio-beneficio #relatorioBeneficio *ngIf="casoCanvas" [beneficio]="casoCanvas.beneficio"></app-relatorio-beneficio> -->
    </div>
    <div class="py-2">
      <app-relatorio-caso-planejamento #relatorioCaso *ngIf="casoCanvas" [caso]="casoCanvas"></app-relatorio-caso-planejamento>
    </div>

  </div>

</div>

<div class="offcanvas h-100 offcanvas-bottom" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="canvasKertzman" aria-labelledby="canvasKertzman">

  <div class="offcanvas-header ml-6 mr-2 justify-content-between">
    <div *ngIf="kertzman" class="w-100 mr-4">
      <div class="row d-flex">
        <div class="col-auto " *ngIf="kertzman">
          <span class="h1 lh-1 fw-lighter"> {{kertzman.relatorio.otimizacao.beneficio.qualificacaoDetalhada}}</span>

        </div>
        <div class="col-auto p-0">

          <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpCanvasKertzman" aria-expanded="false" aria-controls="helpCanvasKertzman"></i>

        </div>
      </div>

      <div class="collapse" id="helpCanvasKertzman">
        <p class="lh-1 py-2">
          Detalhamento da Otimização
        </p>

      </div>
    </div>
    <!-- <span *ngIf="sequencia" class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Sequência {{sequencia.sequencia}}</span> -->
    <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
    <div class="row flex d-flex align-items-top justify-content-end">

      <div class="hstack">
        <!-- <div [className]="aguarde ? 'visible': 'invisible'">
            <div class="mx-4">
              <app-aguarde-local [aguarde]="aguarde"></app-aguarde-local>
            </div>
          </div> -->
        <div class>
          <i role="button" class="comando bi bi-filetype-pdf fs-6" (click)="gerarPDFKertzman(kertzman)" container="body" ngbTooltip="PDF"></i>

        </div>

        <div class>
          <i role="button" class="comando bi bi-x fs-6" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar" (click)="fecharCanvasKertzman()"></i>

        </div>
      </div>

    </div>

  </div>
  <div class="offcanvas-body ml-6 mr-2">
    <div>
      <app-aguarde-local [aguarde]="aguarde"></app-aguarde-local>
    </div>

    <div class="py-2">
      <app-sumario-resultado-planejamento-kertzman #sumarioResultado
        *ngIf="kertzman && kertzman.relatorio"
        [calculoPlanejamentoKertzman]="kertzman"
        (otimizacaoEspecificaEvent)="atualizarOtimizacaoEspecifica($event)">
      </app-sumario-resultado-planejamento-kertzman>
    </div>
  </div>

</div>