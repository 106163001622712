import { GanttModule, GanttComponent } from '@syncfusion/ej2-angular-gantt';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompartilhadoComponent } from './compartilhado.component';
import { ImportadorCNISComponent } from './importador-cnis/importador-cnis.component';
import { FormularioCNISComponent } from './formulario-cnis/formulario-cnis.component';
import { EditorCNISComponent } from './editor-cnis/editor-cnis.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QuadroRelatorioTempoContribuicaoComponent } from './quadro-relatorio-tempo-contribuicao/quadro-relatorio-tempo-contribuicao.component';
import { DetalhamentoRelacoesPrevidenciariasComponent } from './detalhamento-relacoes-previdenciarias/detalhamento-relacoes-previdenciarias.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxSelectModule } from 'ngx-select-ex';

import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NovaContribuicaoModalComponent } from './nova-contribuicao-modal/nova-contribuicao-modal.component';
import { NgbCarouselModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { QuadroRelatorioRendaMensalInicialComponent } from './quadro-relatorio-renda-mensal-inicial/quadro-relatorio-renda-mensal-inicial.component';
import { SumarioBeneficiosComponent } from './sumario-beneficios/sumario-beneficios.component';
import { ExtratoComponent } from './extrato/extrato.component';
import { AnaliseComponent } from './analise/analise.component';
import { DestaqueBeneficioComponent } from './destaque-beneficio/destaque-beneficio.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { GrupoTempoContribuicaoComponent } from './grupo-tempo-contribuicao/grupo-tempo-contribuicao.component';
import { GruposSumarioCalculoBeneficiosComponent } from './grupos-sumario-calculo-beneficios/grupos-sumario-calculo-beneficios.component';
import { GrupoIdadeComponent } from './grupo-idade/grupo-idade.component';
import { QuadroSeguradoComponent } from './quadro-segurado/quadro-segurado.component';
import { RelatorioIdadeComponent } from './relatorio-idade/relatorio-idade.component';
import { RelatorioBeneficioComponent } from './relatorio-beneficio/relatorio-beneficio.component';
import { QuadroBeneficioComponent } from './quadro-beneficio/quadro-beneficio.component';
import { AnaliseBeneficioComponent } from './analise-beneficio/analise-beneficio.component';
import { ExtratoBeneficioComponent } from './extrato-beneficio/extrato-beneficio.component';
import { ResultadoBeneficioComponent } from './resultado-beneficio/resultado-beneficio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GrupoInvalidezComponent } from './grupo-invalidez/grupo-invalidez.component';
import { GrupoEspecialComponent } from './grupo-especial/grupo-especial.component';
import { GrupoAcidenteComponent } from './grupo-acidente/grupo-acidente.component';
import { GrupoDoencaComponent } from './grupo-doenca/grupo-doenca.component';
import { GrupoReclusaoComponent } from './grupo-reclusao/grupo-reclusao.component';
import { GrupoMaternidadeComponent } from './grupo-maternidade/grupo-maternidade.component';
import { GrupoMorteComponent } from './grupo-morte/grupo-morte.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InputMaskModule } from '@ngneat/input-mask';




import { BeneficioLiquidacaoSentencaPrevidenciarioComponent } from './beneficio-liquidacao-sentenca-previdenciario/beneficio-liquidacao-sentenca-previdenciario.component';
import { AbatimentoLiquidacaoSentencaPrevidenciarioComponent } from './abatimentos-liquidacao-sentenca-previdenciario/abatimentos-liquidacao-sentenca-previdenciario.component';
import { ProcessoLiquidacaoSentencaPrevidenciarioComponent } from './processo-liquidacao-sentenca-previdenciario/processo-liquidacao-sentenca-previdenciario.component';
import { HonorariosLiquidacaoSentencaPrevidenciarioComponent } from './honorarios-liquidacao-sentenca-previdenciario/honorarios-liquidacao-sentenca-previdenciario.component';
import { ResumoLiquidacaoSentencaPrevidenciarioComponent } from './resumo-liquidacao-sentenca-previdenciario/resumo-liquidacao-sentenca-previdenciario.component';
import { RelatorioLiquidacaoSentencaPrevidenciarioComponent } from './relatorio-liquidacao-sentenca-previdenciario/relatorio-liquidacao-sentenca-previdenciario.component';
import { ReferenciaLiquidacaoSentencaPrevidenciarioComponent } from './referencia-liquidacao-sentenca-previdenciario/referencia-liquidacao-sentenca-previdenciario.component';
import { ImportadorHiscreComponent } from './importador-hiscre/importador-hiscre.component';
import { ReferenciaValorCausaPrevidenciarioComponent } from './referencia-valor-causa-previdenciario/referencia-valor-causa-previdenciario.component';
import { BeneficioValorCausaPrevidenciarioComponent } from './beneficio-valor-causa-previdenciario/beneficio-valor-causa-previdenciario.component';
import { AbatimentosValorCausaPrevidenciarioComponent } from './abatimentos-valor-causa-previdenciario/abatimentos-valor-causa-previdenciario.component';
import { AtualizacaoValorCausaPrevidenciarioComponent } from './atualizacao-valor-causa-previdenciario/atualizacao-valor-causa-previdenciario.component';
import { ResumoValorCausaPrevidenciarioComponent } from './resumo-valor-causa-previdenciario/resumo-valor-causa-previdenciario.component';
import { RelatorioValorCausaPrevidenciarioComponent } from './relatorio-valor-causa-previdenciario/relatorio-valor-causa-previdenciario.component';
import { RankingRmiComponent } from './ranking-rmi/ranking-rmi.component';
import { BuracoTempoContribuicaoComponent } from './buraco-tempo-contribuicao/buraco-tempo-contribuicao.component';
import { EvolucaoSalariosComponent } from './evolucao-salarios/evolucao-salarios.component';
import { AnaliseCnisComponent } from './analise-cnis/analise-cnis.component';
import { EvolucaoSalariosPosRealComponent } from './evolucao-salarios-pos-real/evolucao-salarios-pos-real.component';

import { CompartilhadoModule as CompartilhadoModuleAplicacao } from 'src/app/aplicacao/compartilhado/compartilhado.module';
import { NovaEvolucaoRmiComponent } from './nova-evolucao-rmi/nova-evolucao-rmi.component';
import { NovoValorCompetenciaComponent } from './novo-valor-competencia/novo-valor-competencia.component';
import { NovaRegraAtualizacaoMonetariaComponent } from './nova-regra-atualizacao-monetaria/nova-regra-atualizacao-monetaria.component';
import { NovaRegraAplicacaoJurosComponent } from './nova-regra-aplicacao-juros/nova-regra-aplicacao-juros.component';
import { ImportadorExtratoPrismaComponent } from './importador-extrato-prisma/importador-extrato-prisma.component';
import { RelatorioExtratoPrismaComponent } from './relatorio-extrato-prisma/relatorio-extrato-prisma.component';
import { MontagemCenarioPlanejamentoComponent } from './montagem-cenario-planejamento/montagem-cenario-planejamento.component';
import { CasoPlanejamentoPrevidenciarioComponent } from './caso-planejamento-previdenciario/caso-planejamento-previdenciario.component';
import { SumarioResultadoPlanejamentoComponent } from './sumario-resultado-planejamento/sumario-resultado-planejamento.component';
import { DestaqueSumarioResultadoPlanejamentoComponent } from './destaque-sumario-resultado-planejamento/destaque-sumario-resultado-planejamento.component';
import { RankingRoiComponent } from './ranking-roi/ranking-roi.component';
import { DuracaoSaqueCapitalizacaoChartComponent } from './duracao-saque-capitalizacao-chart/duracao-saque-capitalizacao-chart.component';
import { ProgressaoCapitalizacaoChartComponent } from './progressao-capitalizacao-chart/progressao-capitalizacao-chart.component';



import { CarouselDestaqueCasoPlanejamentoComponent } from './carousel-destaque-caso-planejamento/carousel-destaque-caso-planejamento.component';
import { VariacaoSalarioRetornoChartComponent } from './variacao-salario-retorno-chart/variacao-salario-retorno-chart.component';
import { OpcoesValorContribuicaoComponent } from './opcoes-valor-contribuicao/opcoes-valor-contribuicao.component';

import { OpcoesSalarioAliquotaComponent } from './opcoes-salario-aliquota/opcoes-salario-aliquota.component';
import { EditorEnquadramentoContributivoComponent } from './editor-enquadramento-contributivo/editor-enquadramento-contributivo.component';
import { EditorSalarioBaseAliquotaComponent } from './editor-salario-base-aliquota/editor-salario-base-aliquota.component';
import { CasosAnalisadosPlanejamentoComponent } from './casos-analisados-planejamento/casos-analisados-planejamento.component';
import { CriteriosEnquadramentoPadraoComponent } from './criterios-enquadramento-padrao/criterios-enquadramento-padrao.component';
import { PreAnaliseBeneficioKertzmanComponent } from './pre-analise-beneficio-kertzman/pre-analise-beneficio-kertzman.component';
import { DestaquePreAnaliseCumprimentoRequisitosComponent } from './destaque-pre-analise-cumprimento-requisitos/destaque-pre-analise-cumprimento-requisitos.component';
import { EditorEnquadramentoContributivoKertzmanComponent } from './editor-enquadramento-contributivo-kertzman/editor-enquadramento-contributivo-kertzman.component';
import { EditorSalarioBaseAliquotaKertzmanComponent } from './editor-salario-base-aliquota-kertzman/editor-salario-base-aliquota-kertzman.component';
import { SumarioResultadoPlanejamentoKertzmanComponent } from './sumario-resultado-planejamento-kertzman/sumario-resultado-planejamento-kertzman.component';
import { QuadroCarenciaPlanejamentoComponent } from './quadro-carencia-planejamento/quadro-carencia-planejamento.component';
import { QuadroDescartesComponent } from './quadro-descartes/quadro-descartes.component';
import { DescartesRmiChartComponent } from './descartes-rmi-chart/descartes-rmi-chart.component';
import { VariacaoRmiSimulacaoKertzmanChartComponent } from './variacao-rmi-simulacao-kertzman-chart/variacao-rmi-simulacao-kertzman-chart.component';
import { VariacaoTaxaRetornoMensalSimulacaoKertzmanComponent } from './variacao-taxa-retorno-mensal-simulacao-kertzman/variacao-taxa-retorno-mensal-simulacao-kertzman.component';
import { VariacaoLucroSimulacaoKertzmanComponent } from './variacao-lucro-simulacao-kertzman/variacao-lucro-simulacao-kertzman.component';
import { QuadroBeneficioKertzmanComponent } from './quadro-beneficio-kertzman/quadro-beneficio-kertzman.component';
import { VariacaoTaxaPaybackSimulacaoKertzmanComponent } from './variacao-taxa-payback-simulacao-kertzman/variacao-taxa-payback-simulacao-kertzman.component';
import { VariacaoPaybackSimulacaoKertzmanComponent } from './variacao-payback-simulacao-kertzman/variacao-payback-simulacao-kertzman.component';
import { VariacaoContribuicoesOriginaisSimulacaoKertzmanComponent } from './variacao-contribuicoes-originais-simulacao-kertzman/variacao-contribuicoes-originais-simulacao-kertzman.component';
import { GrupoAposentadoriasComponent } from './grupo-aposentadorias/grupo-aposentadorias.component';
import { QuadroBeneficioPreviewComponent } from './quadro-beneficio-preview/quadro-beneficio-preview.component';
import { GrupoCasosPlanejamentoComponent } from './grupo-casos-planejamento/grupo-casos-planejamento.component';
import { CasoPlanejamentoPreviewComponent } from './caso-planejamento-preview/caso-planejamento-preview.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MontagemCNISComponent } from './montagem-cnis/montagem-cnis.component';

import { MontagemRelacaoPrevidenciariaComponent } from './montagem-relacao-previdenciaria/montagem-relacao-previdenciaria.component';




import { CollapseModule } from 'ngx-bootstrap/collapse';

import { GeradorCnisComponent } from './gerador-cnis/gerador-cnis.component';
import { MontagemLeituraCnisComponent } from './montagem-leitura-cnis/montagem-leitura-cnis.component';
import { ExtratoRelacoesPrevidenciariasComponent } from './extrato-relacoes-previdenciarias/extrato-relacoes-previdenciarias.component';
import { NovaRelacaoPrevidenciariaComponent } from './nova-relacao-previdenciaria/nova-relacao-previdenciaria.component';
import { EditorRelacaoPrevidenciariaComponent } from './editor-relacao-previdenciaria/editor-relacao-previdenciaria.component';
import { DesfazerEdicaoRelacaoPrevidenciariaComponent } from './desfazer-edicao-relacao-previdenciaria/desfazer-edicao-relacao-previdenciaria.component';
import { NovaContribuicaoPrevidenciariaComponent } from './nova-contribuicao-previdenciaria/nova-contribuicao-previdenciaria.component';

import { SeletorTipoAtividadeComponent } from './seletor-tipo-atividade/seletor-tipo-atividade.component';
import { ApresentacaoMatrizPlanejamentoComponent } from './apresentacao-matriz-planejamento/apresentacao-matriz-planejamento.component';


import { NgxSliderModule } from 'ngx-slider-v2';


import { CriarMatrizPlanejamentoComponent } from './criar-matriz-planejamento/criar-matriz-planejamento.component';

import { SeletorMatrizPlanejamentoComponent } from './seletor-matriz-planejamento/seletor-matriz-planejamento.component';
import { NgSelectModule } from '@ng-select/ng-select';


import { TotalCasosMatrizComponent } from './total-casos-matriz/total-casos-matriz.component';
import { VariacaoLucroMatrizComponent } from './variacao-lucro-matriz/variacao-lucro-matriz.component';
import { VariacaoRmiMatrizComponent } from './variacao-rmi-matriz/variacao-rmi-matriz.component';
import { VariacaoTaxaRetornoMatrizComponent } from './variacao-taxa-retorno-matriz/variacao-taxa-retorno-matriz.component';
import { VariacaoPaybackMatrizComponent } from './variacao-payback-matriz/variacao-payback-matriz.component';

import { EditorSeletorCnisComponent } from './editor-seletor-cnis/editor-seletor-cnis.component';
import { EditorContribuicaoComponent } from './editor-contribuicao/editor-contribuicao.component';
import { DestaqueKertzmanComponent } from './destaque-kertzman/destaque-kertzman.component';
import { CenarioMatrizPreviewComponent } from './cenario-matriz-preview/cenario-matriz-preview.component';
import { VariacaoDataMatrizComponent } from './variacao-data-matriz/variacao-data-matriz.component';
import { RadarMatrizComponent } from './radar-matriz/radar-matriz.component';
import { VariacaoInvestimentoMatrizComponent } from './variacao-investimento-matriz/variacao-investimento-matriz.component';
import { LinkMarcadorLeituraCnisComponent } from './link-marcador-leitura-cnis/link-marcador-leitura-cnis.component';
import { AtualizacaoLiquidacaoSentencaPrevidenciarioComponent } from './atualizacao-liquidacao-sentenca-previdenciario/atualizacao-liquidacao-sentenca-previdenciario.component';
import { ResultadoQualidadeSeguradoComponent } from './resultado-qualidade-segurado/resultado-qualidade-segurado.component';
import { OpcoesProdutoPrivadoComparativoKertzmanComponent } from './opcoes-produto-privado-comparativo-kertzman/opcoes-produto-privado-comparativo-kertzman.component';
import { OpcoesImpostoRendaComponent } from './opcoes-imposto-renda/opcoes-imposto-renda.component';
import { MapaQualidadeSeguradoComponent } from './mapa-qualidade-segurado/mapa-qualidade-segurado.component';
import { MapaConsolidadoQualidadeSeguradoComponent } from './mapa-consolidado-qualidade-segurado/mapa-consolidado-qualidade-segurado.component';
import { CnisGeralModalComponent } from './cnis-geral-modal/cnis-geral-modal.component';
import { DivisaoRecorteRelacaoComponent } from './divisao-recorte-relacao/divisao-recorte-relacao.component';
import { ResultadoAnaliseCnisComponent } from './resultado-analise-cnis/resultado-analise-cnis.component';
import { DadosSeguradoComponent } from './dados-segurado/dados-segurado.component';
import { ListagemIndicadoresComponent } from './listagem-indicadores/listagem-indicadores.component';
// import { CompartilhadoDashboardModule } from 'src/app/aplicacao/dashboard/compartilhado-dashboard/compartilhado-dashboard.module';
import { ResultadoIncapacidadeTemporariaComponent } from './resultado-incapacidade-temporaria/resultado-incapacidade-temporaria.component';
import { EditorCnisTabularComponent } from './editor-cnis-tabular/editor-cnis-tabular.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { QuadroSeguradoEditorCnisComponent } from './quadro-segurado-editor-cnis/quadro-segurado-editor-cnis.component';

import { NgxFileDropModule } from 'ngx-file-drop';
import { ParcelasLiquidacaoSentencaComponent } from './parcelas-liquidacao-sentenca/parcelas-liquidacao-sentenca.component';
import { TotaisLiquidacaoSentencaComponent } from './totais-liquidacao-sentenca/totais-liquidacao-sentenca.component';
import { ResumoLiquidacaoSentencaComponent } from './resumo-liquidacao-sentenca/resumo-liquidacao-sentenca.component';
import { BarraComandosComponent } from './barra-comandos/barra-comandos.component';
import { QuadroSeguradoRelatorioComponent } from './quadro-segurado-relatorio/quadro-segurado-relatorio.component';
import { AguardeConfigComponent } from './aguarde-config/aguarde-config.component';
import { AguardeLocalComponent } from './aguarde-local/aguarde-local.component';
import { RelatorioCasoPlanejamentoComponent } from './relatorio-caso-planejamento/relatorio-caso-planejamento.component';
import { ProtocolosCorrecaoPipe } from 'src/app/aplicacao/compartilhado/pipes/protocolos-correcao.pipe';
import { ProtocolosJurosPipe } from 'src/app/aplicacao/compartilhado/pipes/protocolos-juros.pipe';
import { ResultadoMatrizComponent } from './resultado-matriz/resultado-matriz.component';
import { EditorOpcoesMatrizComponent } from './editor-opcoes-matriz/editor-opcoes-matriz.component';
import { TabelaMatrizComponent } from './tabela-matriz/tabela-matriz.component';



import { PivotMatrizComponent } from './pivot-matriz/pivot-matriz.component';
import { TabelaCustoOportunidadeComponent } from './tabela-custo-oportunidade/tabela-custo-oportunidade.component';
import { EditorCnisGridComponent } from './editor-cnis-grid/editor-cnis-grid.component';
// import { GridModule, Selection } from '@syncfusion/ej2-angular-grids';
import { MaskedTextBoxModule, SliderModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CanvasSequenciaComponent } from './canvas-sequencia/canvas-sequencia.component';
import { CanvasContribuicoesComponent } from './canvas-contribuicoes/canvas-contribuicoes.component';
import { CanvasCarenciaComponent } from './canvas-carencia/canvas-carencia.component';
import { CanvasIndicadoresComponent } from './canvas-indicadores/canvas-indicadores.component';
import { CanvasPontosAtencaoComponent } from './canvas-pontos-atencao/canvas-pontos-atencao.component';
import { EditorContribuicoesExcelComponent } from './editor-contribuicoes-excel/editor-contribuicoes-excel.component';


import { SpreadsheetAllModule, SpreadsheetModule } from '@syncfusion/ej2-angular-spreadsheet';

import { ListBoxAllModule, ListBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { CanvasTempoContribuicaoComponent } from './canvas-tempo-contribuicao/canvas-tempo-contribuicao.component';
import { ValidacoesTempoContribuicaoComponent } from './validacoes-tempo-contribuicao/validacoes-tempo-contribuicao.component';
import { ValidacoesTempoContribuicaoTreeComponent } from './validacoes-tempo-contribuicao-tree/validacoes-tempo-contribuicao-tree.component';
import { ValidacoesCarenciaComponent } from './validacoes-carencia/validacoes-carencia.component';
import { ValidacoesPontosAtencaoComponent } from './validacoes-pontos-atencao/validacoes-pontos-atencao.component';
import { HeatMapMatrizComponent } from './heat-map-matriz/heat-map-matriz.component';

import { HeatMapModule, HeatMapAllModule } from '@syncfusion/ej2-angular-heatmap'
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { ValidacaoTempoContribuicaoCarenciaComponent } from './validacao-tempo-contribuicao-carencia/validacao-tempo-contribuicao-carencia.component';
import { SalariosPreviewComponent } from './salarios-preview/salarios-preview.component';
import { AddAtividadeComponent } from './add-atividade/add-atividade.component';
import { DestaquesAnaliseCnisComponent } from './destaques-analise-cnis/destaques-analise-cnis.component';
import { MetricaExcedenteComponent } from './metrica-excedente/metrica-excedente.component';
import { MetricaPeriodosComponent } from './metrica-periodos/metrica-periodos.component';
import { MetricaConcomitanciasComponent } from './metrica-concomitancias/metrica-concomitancias.component';
import { DestaqueMelhorCenarioComponent } from './destaque-melhor-cenario/destaque-melhor-cenario.component';
import { PreviewDestaqueMelhorCenarioComponent } from './preview-destaque-melhor-cenario/preview-destaque-melhor-cenario.component';
import { FrequenciaContributivaComponent } from './frequencia-contributiva/frequencia-contributiva.component';
import { AddAtividadeMatrizComponent } from './add-atividade-matriz/add-atividade-matriz.component';
import { ComparativoCapitalizacaoKertzmanComponent } from './comparativo-capitalizacao-kertzman/comparativo-capitalizacao-kertzman.component';
import { RetornoFinanceiroBeneficioKertzmanComponent } from './retorno-financeiro-beneficio-kertzman/retorno-financeiro-beneficio-kertzman.component';
import { ImpostoRendaContribuinteKertzmanComponent } from './imposto-renda-contribuinte-kertzman/imposto-renda-contribuinte-kertzman.component';
import { ResumoSimulacaoComponent } from './resumo-simulacao/resumo-simulacao.component';

import { IntervaloContribuicoesMatrizComponent } from './intervalo-contribuicoes-matriz/intervalo-contribuicoes-matriz.component';
import { TabelaPlanosRelatorioMatrizComponent } from './tabela-planos-relatorio-matriz/tabela-planos-relatorio-matriz.component';
import { CustoImpostoRendaAnualComponent } from './custo-imposto-renda-anual/custo-imposto-renda-anual.component';
import { InputImpostoRendaComponent } from './input-imposto-renda/input-imposto-renda.component';
import { EditorImpostoRendaComponent } from './editor-imposto-renda/editor-imposto-renda.component';
import { EditorOtimizacaoKertzmanComponent } from './editor-otimizacao-kertzman/editor-otimizacao-kertzman.component'





@NgModule({
  declarations: [
    CompartilhadoComponent,
    ImportadorCNISComponent,
    FormularioCNISComponent,
    EditorCNISComponent,
    QuadroRelatorioTempoContribuicaoComponent,
    DetalhamentoRelacoesPrevidenciariasComponent,
    NovaContribuicaoModalComponent,

    QuadroRelatorioRendaMensalInicialComponent,
    SumarioBeneficiosComponent,
    ExtratoComponent,
    AnaliseComponent,
    DestaqueBeneficioComponent,
    GrupoTempoContribuicaoComponent,
    GruposSumarioCalculoBeneficiosComponent,
    GrupoIdadeComponent,
    QuadroSeguradoComponent,
    RelatorioIdadeComponent,
    RelatorioBeneficioComponent,
    QuadroBeneficioComponent,
    AnaliseBeneficioComponent,
    ExtratoBeneficioComponent,
    ResultadoBeneficioComponent,
    GrupoInvalidezComponent,
    GrupoEspecialComponent,
    GrupoAcidenteComponent,
    GrupoDoencaComponent,
    GrupoReclusaoComponent,
    GrupoMaternidadeComponent,
    GrupoMorteComponent,
    BeneficioLiquidacaoSentencaPrevidenciarioComponent,
    AbatimentoLiquidacaoSentencaPrevidenciarioComponent,
    ProcessoLiquidacaoSentencaPrevidenciarioComponent,
    HonorariosLiquidacaoSentencaPrevidenciarioComponent,
    ResumoLiquidacaoSentencaPrevidenciarioComponent,
    RelatorioLiquidacaoSentencaPrevidenciarioComponent,
    ReferenciaLiquidacaoSentencaPrevidenciarioComponent,
    ImportadorHiscreComponent,
    ReferenciaValorCausaPrevidenciarioComponent,
    BeneficioValorCausaPrevidenciarioComponent,
    AbatimentosValorCausaPrevidenciarioComponent,
    AtualizacaoValorCausaPrevidenciarioComponent,
    ResumoValorCausaPrevidenciarioComponent,
    RelatorioValorCausaPrevidenciarioComponent,
    RankingRmiComponent,
    BuracoTempoContribuicaoComponent,
    EvolucaoSalariosComponent,
    AnaliseCnisComponent,
    EvolucaoSalariosPosRealComponent,
    NovaEvolucaoRmiComponent,
    NovoValorCompetenciaComponent,
    NovaRegraAtualizacaoMonetariaComponent,
    NovaRegraAplicacaoJurosComponent,
    ImportadorExtratoPrismaComponent,
    RelatorioExtratoPrismaComponent,
    MontagemCenarioPlanejamentoComponent,
    CasoPlanejamentoPrevidenciarioComponent,
    SumarioResultadoPlanejamentoComponent,
    DestaqueSumarioResultadoPlanejamentoComponent,
    RankingRoiComponent,
    DuracaoSaqueCapitalizacaoChartComponent,
    ProgressaoCapitalizacaoChartComponent,
    CarouselDestaqueCasoPlanejamentoComponent,
    VariacaoSalarioRetornoChartComponent,
    OpcoesValorContribuicaoComponent,
    OpcoesSalarioAliquotaComponent,
    EditorEnquadramentoContributivoComponent,
    EditorSalarioBaseAliquotaComponent,
    CasosAnalisadosPlanejamentoComponent,
    CriteriosEnquadramentoPadraoComponent,
    PreAnaliseBeneficioKertzmanComponent,
    DestaquePreAnaliseCumprimentoRequisitosComponent,
    EditorEnquadramentoContributivoKertzmanComponent,
    EditorSalarioBaseAliquotaKertzmanComponent,
    SumarioResultadoPlanejamentoKertzmanComponent,
    QuadroCarenciaPlanejamentoComponent,
    QuadroDescartesComponent,
    DescartesRmiChartComponent,
    VariacaoRmiSimulacaoKertzmanChartComponent,
    VariacaoTaxaRetornoMensalSimulacaoKertzmanComponent,
    VariacaoLucroSimulacaoKertzmanComponent,
    QuadroBeneficioKertzmanComponent,
    VariacaoTaxaPaybackSimulacaoKertzmanComponent,
    VariacaoPaybackSimulacaoKertzmanComponent,
    VariacaoContribuicoesOriginaisSimulacaoKertzmanComponent,
    GrupoAposentadoriasComponent,
    QuadroBeneficioPreviewComponent,
    GrupoCasosPlanejamentoComponent,
    CasoPlanejamentoPreviewComponent,
    MontagemCNISComponent,
    MontagemRelacaoPrevidenciariaComponent,

    GeradorCnisComponent,
    MontagemLeituraCnisComponent,
    ExtratoRelacoesPrevidenciariasComponent,
    NovaRelacaoPrevidenciariaComponent,
    EditorRelacaoPrevidenciariaComponent,
    DesfazerEdicaoRelacaoPrevidenciariaComponent,
    NovaContribuicaoPrevidenciariaComponent,

    SeletorTipoAtividadeComponent,
    ApresentacaoMatrizPlanejamentoComponent,


    CriarMatrizPlanejamentoComponent,
    SeletorMatrizPlanejamentoComponent,

    TotalCasosMatrizComponent,
    VariacaoLucroMatrizComponent,
    VariacaoRmiMatrizComponent,
    VariacaoTaxaRetornoMatrizComponent,
    VariacaoPaybackMatrizComponent,
    EditorSeletorCnisComponent,
    EditorContribuicaoComponent,
    DestaqueKertzmanComponent,
    CenarioMatrizPreviewComponent,
    VariacaoDataMatrizComponent,
    RadarMatrizComponent,
    VariacaoInvestimentoMatrizComponent,
    LinkMarcadorLeituraCnisComponent,
    AtualizacaoLiquidacaoSentencaPrevidenciarioComponent,
    ResultadoQualidadeSeguradoComponent,
    OpcoesProdutoPrivadoComparativoKertzmanComponent,
    OpcoesImpostoRendaComponent,
    MapaQualidadeSeguradoComponent,
    MapaConsolidadoQualidadeSeguradoComponent,
    CnisGeralModalComponent,
    DivisaoRecorteRelacaoComponent,
    ResultadoAnaliseCnisComponent,
    DadosSeguradoComponent,
    ListagemIndicadoresComponent,
    ResultadoIncapacidadeTemporariaComponent,
    EditorCnisTabularComponent,
    QuadroSeguradoEditorCnisComponent,
    ParcelasLiquidacaoSentencaComponent,
    TotaisLiquidacaoSentencaComponent,
    ResumoLiquidacaoSentencaComponent,
    BarraComandosComponent,
    QuadroSeguradoRelatorioComponent,
    AguardeConfigComponent,
    AguardeLocalComponent,
    RelatorioCasoPlanejamentoComponent,
    ProtocolosCorrecaoPipe,
    ProtocolosJurosPipe,
    ResultadoMatrizComponent,
    EditorOpcoesMatrizComponent,
    TabelaMatrizComponent,

    PivotMatrizComponent,
    TabelaCustoOportunidadeComponent,
    EditorCnisGridComponent,
    CanvasSequenciaComponent,
    CanvasContribuicoesComponent,
    CanvasCarenciaComponent,
    CanvasIndicadoresComponent,
    CanvasPontosAtencaoComponent,
    EditorContribuicoesExcelComponent,
    CanvasTempoContribuicaoComponent,
    ValidacoesTempoContribuicaoComponent,
    ValidacoesTempoContribuicaoTreeComponent,
    ValidacoesCarenciaComponent,
    ValidacoesPontosAtencaoComponent,
    HeatMapMatrizComponent,
    ValidacaoTempoContribuicaoCarenciaComponent,
    SalariosPreviewComponent,
    AddAtividadeComponent,
    DestaquesAnaliseCnisComponent,
    MetricaExcedenteComponent,
    MetricaPeriodosComponent,
    MetricaConcomitanciasComponent,
    DestaqueMelhorCenarioComponent,
    PreviewDestaqueMelhorCenarioComponent,
    FrequenciaContributivaComponent,
    AddAtividadeMatrizComponent,
    ComparativoCapitalizacaoKertzmanComponent,
    RetornoFinanceiroBeneficioKertzmanComponent,
    ImpostoRendaContribuinteKertzmanComponent,
    ResumoSimulacaoComponent,

    IntervaloContribuicoesMatrizComponent,
    TabelaPlanosRelatorioMatrizComponent,
    CustoImpostoRendaAnualComponent,
    InputImpostoRendaComponent,
    EditorImpostoRendaComponent,
    EditorOtimizacaoKertzmanComponent,








  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    UiSwitchModule.forRoot({
      size: 'small'
    }),
    NgxSelectModule,
    ButtonsModule.forRoot(),
    NgbModalModule,
    ProgressbarModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    InputMaskModule.forRoot({ inputSelector: 'input', isAsync: true }),
    NgbTooltipModule,
    CompartilhadoModuleAplicacao,
    NgxSliderModule,
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),


    CollapseModule.forRoot(),

    NgSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxFileDropModule,

    // GridModule,
    MaskedTextBoxModule,
    DatePickerModule,
    SpreadsheetModule,
    SpreadsheetAllModule,
    ListBoxModule,
    ListBoxAllModule,
    GanttModule,

    HeatMapModule,
    HeatMapAllModule,
    ToastModule,
    SliderModule










    // CompartilhadoDashboardModule



  ],
  exports: [
    ImportadorCNISComponent,
    FormularioCNISComponent,
    EditorCNISComponent,
    QuadroRelatorioTempoContribuicaoComponent,
    DetalhamentoRelacoesPrevidenciariasComponent,
    QuadroRelatorioRendaMensalInicialComponent,
    SumarioBeneficiosComponent,
    ExtratoComponent,
    AnaliseComponent,
    DestaqueBeneficioComponent,
    GrupoTempoContribuicaoComponent,
    GrupoIdadeComponent,
    GruposSumarioCalculoBeneficiosComponent,
    QuadroSeguradoComponent,
    QuadroBeneficioComponent,
    AnaliseBeneficioComponent,
    ExtratoBeneficioComponent,
    ResultadoBeneficioComponent,

    NovaContribuicaoModalComponent,
    GrupoInvalidezComponent,
    GrupoAcidenteComponent,
    GrupoEspecialComponent,
    GrupoDoencaComponent,
    GrupoReclusaoComponent,
    GrupoMaternidadeComponent,
    GrupoMorteComponent,
    BeneficioLiquidacaoSentencaPrevidenciarioComponent,
    AbatimentoLiquidacaoSentencaPrevidenciarioComponent,
    ProcessoLiquidacaoSentencaPrevidenciarioComponent,
    HonorariosLiquidacaoSentencaPrevidenciarioComponent,
    ResumoLiquidacaoSentencaPrevidenciarioComponent,
    RelatorioLiquidacaoSentencaPrevidenciarioComponent,
    ReferenciaLiquidacaoSentencaPrevidenciarioComponent,
    ImportadorHiscreComponent,
    ReferenciaValorCausaPrevidenciarioComponent,
    BeneficioValorCausaPrevidenciarioComponent,
    AbatimentosValorCausaPrevidenciarioComponent,
    AtualizacaoValorCausaPrevidenciarioComponent,
    ResumoValorCausaPrevidenciarioComponent,
    RelatorioValorCausaPrevidenciarioComponent,
    RankingRmiComponent,
    BuracoTempoContribuicaoComponent,
    EvolucaoSalariosComponent,
    AnaliseCnisComponent,
    EvolucaoSalariosPosRealComponent,
    ImportadorExtratoPrismaComponent,
    RelatorioExtratoPrismaComponent,
    MontagemCenarioPlanejamentoComponent,
    CasoPlanejamentoPrevidenciarioComponent,
    SumarioResultadoPlanejamentoComponent,
    DestaqueSumarioResultadoPlanejamentoComponent,
    RankingRoiComponent,
    ProgressaoCapitalizacaoChartComponent,
    DuracaoSaqueCapitalizacaoChartComponent,
    CarouselDestaqueCasoPlanejamentoComponent,
    VariacaoSalarioRetornoChartComponent,
    OpcoesValorContribuicaoComponent,
    OpcoesSalarioAliquotaComponent,
    EditorEnquadramentoContributivoComponent,
    EditorSalarioBaseAliquotaComponent,
    CasosAnalisadosPlanejamentoComponent,
    CriteriosEnquadramentoPadraoComponent,
    PreAnaliseBeneficioKertzmanComponent,
    DestaquePreAnaliseCumprimentoRequisitosComponent,
    EditorEnquadramentoContributivoKertzmanComponent,
    EditorSalarioBaseAliquotaKertzmanComponent,
    SumarioResultadoPlanejamentoKertzmanComponent,
    QuadroCarenciaPlanejamentoComponent,
    QuadroDescartesComponent,
    DescartesRmiChartComponent,
    VariacaoRmiSimulacaoKertzmanChartComponent,
    VariacaoTaxaRetornoMensalSimulacaoKertzmanComponent,
    VariacaoLucroSimulacaoKertzmanComponent,
    QuadroBeneficioKertzmanComponent,
    VariacaoPaybackSimulacaoKertzmanComponent,
    VariacaoTaxaPaybackSimulacaoKertzmanComponent,
    VariacaoContribuicoesOriginaisSimulacaoKertzmanComponent,
    GrupoAposentadoriasComponent,
    QuadroBeneficioPreviewComponent,
    GrupoCasosPlanejamentoComponent,
    CasoPlanejamentoPreviewComponent,
    MontagemCNISComponent,
    MontagemRelacaoPrevidenciariaComponent,

    GeradorCnisComponent,
    MontagemLeituraCnisComponent,
    ExtratoRelacoesPrevidenciariasComponent,
    NovaRelacaoPrevidenciariaComponent,
    EditorRelacaoPrevidenciariaComponent,
    DesfazerEdicaoRelacaoPrevidenciariaComponent,
    NovaContribuicaoPrevidenciariaComponent,

    SeletorTipoAtividadeComponent,
    ApresentacaoMatrizPlanejamentoComponent,


    CriarMatrizPlanejamentoComponent,
    SeletorMatrizPlanejamentoComponent,

    TotalCasosMatrizComponent,
    VariacaoLucroMatrizComponent,
    VariacaoRmiMatrizComponent,
    VariacaoTaxaRetornoMatrizComponent,
    VariacaoPaybackMatrizComponent,
    EditorSeletorCnisComponent,
    EditorContribuicaoComponent,
    DestaqueKertzmanComponent,
    CenarioMatrizPreviewComponent,
    VariacaoDataMatrizComponent,
    RadarMatrizComponent,
    VariacaoInvestimentoMatrizComponent,
    LinkMarcadorLeituraCnisComponent,
    AtualizacaoLiquidacaoSentencaPrevidenciarioComponent,
    ResultadoQualidadeSeguradoComponent,
    OpcoesProdutoPrivadoComparativoKertzmanComponent,
    OpcoesImpostoRendaComponent,
    MapaQualidadeSeguradoComponent,
    MapaConsolidadoQualidadeSeguradoComponent,
    CnisGeralModalComponent,
    DivisaoRecorteRelacaoComponent,
    ResultadoAnaliseCnisComponent,
    DadosSeguradoComponent,
    ListagemIndicadoresComponent,
    ResultadoIncapacidadeTemporariaComponent,
    LinkMarcadorLeituraCnisComponent,
    EditorCnisTabularComponent,
    ParcelasLiquidacaoSentencaComponent,
    TotaisLiquidacaoSentencaComponent,
    ResumoLiquidacaoSentencaComponent,
    BarraComandosComponent,
    QuadroSeguradoRelatorioComponent,
    AguardeConfigComponent,
    AguardeLocalComponent,
    RelatorioCasoPlanejamentoComponent,
    ProtocolosCorrecaoPipe,
    ProtocolosJurosPipe,
    RelatorioBeneficioComponent,
    ResultadoMatrizComponent,
    EditorOpcoesMatrizComponent,
    TabelaMatrizComponent,

    PivotMatrizComponent,
    TabelaPlanosRelatorioMatrizComponent,
    HeatMapMatrizComponent,
    EditorImpostoRendaComponent,
    EditorOtimizacaoKertzmanComponent


  ],
  providers: [
    provideNgxMask(),
  ]
})
export class CompartilhadoModule { }
