<div class=" header bg-back-dark">
    <div class=" container-fluid">
        <div class=" header-body">
            <div class=" row align-items-center py-3">
                <!-- <div class=" col-lg-12">
                    <span class=" text-white inline-block mb-0 h6 fw-lighter">{{titulo}}</span>

                </div> -->
            </div>
        </div>
    </div>
</div>