<div class="row pb-2">
  <div class="col">
    <h5 class=" h3 mb-0 linha-quadro"></h5>
    <div class="row">
      <div class="col-6">
        <h5 class=" h3 mb-0">Salários de Contribuição
          <i *ngIf="visualizarPdf" class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroPreanalise" aria-expanded="false" aria-controls="helpQuadroPreanalise"></i>
        </h5>
      </div>
      <div class="col-6">
        <div class="text-right">
          <div *ngIf="aguardePdf" class="spinner-border  mx-2">
            <span class="visually-hidden">aguarde...</span>
          </div>
          <i *ngIf="visualizarPdf" class="comando bi bi-filetype-pdf fs-6" role="button" container="body" (click)="gerarPDF()" ngbTooltip="Salvar PDF" triggers="hover"></i>
        </div>
      </div>
    </div>

    <div class="collapse" id="helpQuadroPreanalise">

      <div class="row py-4 d-flex ">

        <div class="col-3 py-2 d-flex">
          <div class="row w-100 bg-secondary rounded m-2">
            <div class="col d-flex p-2">

              <p class=" p-2 lh-1">
                Situação Atual
              </p>

            </div>
            <!-- <div class="mt-auto pb-2 text-right">
                          <i class="comando bi bi-cash-coin fs-3"></i>
                      </div> -->

          </div>

        </div>

      </div>

    </div>
  </div>
</div>
<!-- <div class="row">
  <div class="col-12">
    <h5 class=" h3 mb-0 linha-quadro"></h5>
  </div>

  <div *ngIf="visualizarPdf" class="col-12 d-flex flex-row-reverse">
    <i class="bi-filetype-pdf h1 mb-0 clicavel" (click)="gerarPDF()"></i>

    <div *ngIf="aguardePdf" class="spinner-border  mx-2">
      <span class="visually-hidden">aguarde...</span>
    </div>

  </div>
</div> -->

<div class="row">
  <!-- Chart wrapper -->
  <div class="col-lg-12" style="height: 300px;">
    <canvas id="chart"></canvas>
  </div>
</div>