import { CurrencyPipe } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { CapitalizacaoMensal } from 'src/app/modelos/previdenciario/capitalizacaomensal';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';
import { ListarTabelaCapitalizacaoSaqueService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao-saque.service';
import { ListarTabelaCapitalizacaoService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao.service';
import { ListarRelatorioPlanejamentoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-planejamento-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-comparativo-capitalizacao-kertzman',
  templateUrl: './comparativo-capitalizacao-kertzman.component.html',
  styleUrl: './comparativo-capitalizacao-kertzman.component.scss'
})
export class ComparativoCapitalizacaoKertzmanComponent {


  linhasCapitalizacaoMensal:CapitalizacaoMensal[];
  linhasCapitalizacaoMensalSaque:CapitalizacaoMensal[];

  @Input() relatorio:RelatorioKertzman;


  constructor(private listarRelatorioPlanejamentoPdfService:ListarRelatorioPlanejamentoPdfService,
    private notificacoesService:NotificacoesService,
    private cp:CurrencyPipe,
    private listarTabelaCapitalizacaoSaqueService:ListarTabelaCapitalizacaoSaqueService,
    private listarTabelaCapitalizacaoService:ListarTabelaCapitalizacaoService){}

  ngOnInit(): void {
    
    this.carregarDados();
    
  }

  carregarDados(){
    this.linhasCapitalizacaoMensal = CapitalizacaoMensal.tabelaCapitalizacao(this.relatorio.otimizacao);

    // LogService.log('linhas capitalizacao mensal', this.linhasCapitalizacaoMensal);

    // console.log("linhas capitalizacao mensal", this.linhasCapitalizacaoMensal);
    this.linhasCapitalizacaoMensalSaque = CapitalizacaoMensal.tabelaCapitalizacaoSaque(this.relatorio.otimizacao);

    // LogService.log('linhas capitalizacao mensal saque', this.linhasCapitalizacaoMensalSaque);

    // this.beneficioRelatorio = this.relatorio.otimizacao.beneficio;
    // if(this.tabelaProgressaoCapitalizacao!= undefined){
    //   this.tabelaProgressaoCapitalizacao.recalculate();
    // }
    // if(this.tabelaProgressaoSaque!= null){
    //   this.tabelaProgressaoSaque.recalculate();
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {


    // this.duracaoPrestacoesMensaisRendimento = CapitalizacaoMensal.capitalizacaoSaque(changes.caso.currentValue).length;
    // this.linhasCapitalizacaoMensal = CapitalizacaoMensal.capitalizacao(changes.caso.currentValue);
    // this.linhasCapitalizacaoMensalSaque = CapitalizacaoMensal.capitalizacaoSaque(changes.caso.currentValue);

    this.carregarDados();

    
  }

  ngAfterViewInit(): void {

  }
}
