import { AuthConfig } from 'angular-oauth2-oidc';

export const DiscoveryDocumentConfig = {
  url: "https://appcalculeiapp.b2clogin.com/appcalculeiapp.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1_in_base"
};

export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://appcalculeiapp.b2clogin.com/6358f8a5-3a68-4e8a-bedf-4ff4cf5b81ea/v2.0/',
  tokenEndpoint: 'https://appcalculeiapp.b2clogin.com/appcalculeiapp.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1_in_base',
  redirectUri: window.location.origin + '/login',
  postLogoutRedirectUri: 'https://calculei.app',
  clientId: '000cfbec-15b6-4ee0-b9a3-d080b363dc9e',
  logoutUrl: 'https://appcalculeiapp.b2clogin.com/appcalculeiapp.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1_in_base&id_token={{id_token}}',
  responseType: 'code',
  strictDiscoveryDocumentValidation: false,
  scope: 'openid offline_access https://appcalculeiapp.onmicrosoft.com/000cfbec-15b6-4ee0-b9a3-d080b363dc9e/api',
  showDebugInformation: false,
  clearHashAfterLogin: true,
  oidc: true,
};
//https://dev.to/yuriburger/azure-active-directory-b2c-with-pkce-for-your-angular-app-1dcg