<div class="row">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">Dados do Segurado</h5>
    </div>
</div>

<div class="row d-flex py-2">
    <div class="col">
        <p class="display-2  d-block lh-1 fw-lighter">
            {{ usuario.configuracao.anonimo ? (calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome | anonimo) : calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}} 
        </p>
    </div>
    
</div>
<div class="row ">

    <!-- <div class="col-3 py-1">
        <span class="d-block ">
            Nome
        </span>
        <div>
            <div [className]="!usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                <span class="d-block fw-bold lh-1">
                    {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}
                </span>
            </div>
            <div [className]="usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                <span class="d-block fw-bold lh-1">
                    {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome | anonimo}}
                </span>

            </div>
        </div>
    </div>

    <div class="col-3 py-1">
        <span class="d-block">
            CPF
        </span>
        <div [className]="!usuario.configuracao.anonimo ? 'visible' : 'invisible'">
            <span class="d-block fw-bold lh-1">
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.cpf}}
            </span>
        </div>
        <div [className]="usuario.configuracao.anonimo ? 'visible' : 'invisible'">
            <span class="d-block fw-bold lh-1">
                000.000.000-00
            </span>
        </div>
    </div>
    <div class="col-3 py-1">
        <span class="d-block">
            NIT
        </span>

        <div [className]="!usuario.configuracao.anonimo ? 'visible' : 'invisible'">
            <span class="d-block fw-bold lh-1">
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nit}}
            </span>
        </div>
        <div [className]="usuario.configuracao.anonimo ? 'visible' : 'invisible'">
            <span class="d-block fw-bold lh-1">
                000.00000.00-0
            </span>
        </div>

    </div> -->
    <div class="col-3 py-1">
        <p class=" fw-light lh-1 mb-0 text-uppercase h6">
            Data de Nascimento
        </p>
        <p class="lh-1">
            {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}
        </p>

    </div>
    <div class="col-3 py-1">
        <p class=" fw-light lh-1 mb-0 text-uppercase h6">
            Idade
        </p>
        <p class="lh-1">
            {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.idade.porExtenso}}
        </p>
    </div>

    <!-- <div class="col-3 py-1">
        <span class="d-block">
            Data do Extrato Previdenciário
        </span>
        <span class="d-block fw-bold lh-1">
            {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataExtratoPrevidenciario | date : 'dd/MM/yyyy'}}
        </span>
    </div> -->

    <div class="col-3 py-1">
        <p class=" fw-light lh-1 mb-0 text-uppercase h6">
            Sexo
        </p>

        <div class="form-group mb-3">
            <div class="form-inline d-flex align-items-center">
                <div class="btn-group w-100" btnRadioGroup (ngModelChange)="edicaoSexo($event)" [(ngModel)]="calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo">
                    <label class="btn btn-sm btn-outline-black mb-0" [btnRadio]="sexoEnumType.Feminino">Feminino</label>
                    <label class="btn btn-sm btn-outline-black mb-0" [btnRadio]="sexoEnumType.Masculino">Masculino</label>

                </div>
            </div>
        </div>

    </div>

    <div class="col-3 py-1">

        
        <p class=" fw-light lh-1 mb-0 text-uppercase h6">
            Data do Cálculo
        </p>
        <div style="min-width: 150px;">
            <ejs-datepicker [enableMask]="true" [min]="dataReforma" [strictMode]="true" (change)="changeDataCalculo($event)" locale="pt" [(ngModel)]="calculoBeneficios.dataCalculo"></ejs-datepicker>
        </div>

    </div>

</div>