<div class="row pb-2">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro"></h5>
        <div class="row">
            <div class="col-6">
                <h5 class=" h3 mb-0">Períodos de Graça
                    <i *ngIf="visualizarPdf" class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQualidadeSegurado" aria-expanded="false" aria-controls="helpQualidadeSegurado"></i>
                </h5>
            </div>
            <div *ngIf="visualizarPdf" class="col-6">
                <div class="text-right">
                    <div class="row justify-content-end">
                        <div class="col-3">
                            <app-aguarde-local [aguarde]="aguardePdf"></app-aguarde-local>
                        </div>
                    </div>
                    <i class="comando bi bi-filetype-pdf fs-6" role="button" container="body" (click)="gerarPdf()" ngbTooltip="Relatório em PDF" triggers="hover"></i>
                </div>
            </div>
        </div>

        <div class="collapse" id="helpQualidadeSegurado">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row w-100 bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1">
                                Períodos de graça
                            </p>

                        </div>
                        <!-- <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-cash-coin fs-3"></i>
                        </div> -->

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div *ngIf="calculoMapaQualidadeSegurado && calculoMapaQualidadeSegurado.manutencaoConsolidada">
    <div class="row g-3 flex d-flex pb-6">

        <ng-container *ngFor="let manutencao of calculoMapaQualidadeSegurado.manutencaoConsolidada; index as index">
            <div class="col-md-4 py-2" role="button">
                <div class="h-100 w-100 p-4 rounded bg-secondary">

                    <div class>
                        <p class=" lh-1 display-4 fw-light">de {{manutencao.periodo.inicio | date : 'dd/MM/yyyy'}} a {{manutencao.periodo.fim | date : 'dd/MM/yyyy'}}</p>

                        <!-- <p class="mb-3 text-sm">Quando quiser calcular o valor indenizatório de um benefício que deve ser concedido, para cobertura dos eventos de doença, invalidez, morte e idade avançada.</p> -->

                        <div class="d-flex">
                            <div class="">
                                <p class="lh-1">
                                    {{index + 1}}&ordm; período de graça
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </ng-container>
    </div>
</div>

<app-mapa-consolidado-qualidade-segurado #mapaConsolidado *ngIf="calculoMapaQualidadeSegurado && calculoMapaQualidadeSegurado.manutencaoConsolidada" [calculoMapaQualidadeSegurado]="calculoMapaQualidadeSegurado" [visualizarPdf]="visualizarPdf"></app-mapa-consolidado-qualidade-segurado>
<!-- <app-mapa-qualidade-segurado #mapa *ngIf="calculoMapaQualidadeSegurado" [calculoMapaQualidadeSegurado]="calculoMapaQualidadeSegurado" ></app-mapa-qualidade-segurado> -->