
<div class="row">
    <div class="col-12">
        <h5 class=" h3 mb-0 linha-quadro pb-4">Entrevista</h5>
    </div>
</div>

<div class="row pb-4 flex d-flex justify-content-end">

    <div class="col-auto flex d-flex">

        <div class="col-auto p-0 m-0">

            <div class="dropdown">
                <i class="comando bi bi-link-45deg fs-6 " (click)="gerarLink()" data-bs-toggle="dropdown" aria-expanded="false"></i>
                <!-- <button class="btn btn-secondary dropdown-toggle" type="button" >
                  Dropdown button
                </button> -->
                <ul class="dropdown-menu dropstart" style="max-width: 200px; min-width: 400px;">
                    <div class="row m-2 p-2">
                        <div class="col-12">
                            <h5 class=" text-uppercase mb-0 text-default">
                                Link da Entrevista
                            </h5>
                            <div class="flex d-flex gap-1">
                                <input class=" form-control text-left" type="text" [(ngModel)]="link" />
                                <i class="comando bi bi-clipboard fs-6" role="button" (click)="clipboardLink()"></i>
                            </div>
                            <p class="h6">
                                Link para compartilhar com o cliente
                            </p>

                        </div>
                    </div>
                </ul>
            </div>

        </div>

        <div class="col-auto p-0 m-0">
            <a class="text-default " role="button" (click)="testarEntrevista()">
                <i class="comando bi bi-mic fs-6 "></i>
            </a>
        </div>

    </div>

</div>
<app-resumo-entrevista *ngIf="entrevista" [entrevista]="entrevista"></app-resumo-entrevista>
