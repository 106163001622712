import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CapitalizacaoMensal } from 'src/app/modelos/previdenciario/capitalizacaomensal';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { RelatorioPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioplanejamentoprevidenciario';
import { FasesPlanejamento } from 'src/app/modelos/ui/fasesplanejamento';
import { FasesPlanejamentoKertzman } from 'src/app/modelos/ui/fasesplanejamentokertzman';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ListarCalculoChaveService } from 'src/app/servicos/calculo/common/listar-calculo-chave.service';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ListarTabelaCapitalizacaoSaqueService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao-saque.service';
import { ListarTabelaCapitalizacaoService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao.service';
import { LogService } from 'src/app/servicos/log.service';
import { LeituraJsonBlobService } from 'src/app/servicos/relatorio/leitura-json-blob.service';
import { ListarRelatorioPlanejamentoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-planejamento-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-relatorio-planejamento-kertzman',
  templateUrl: './relatorio-planejamento-kertzman.component.html',
  styleUrls: ['./relatorio-planejamento-kertzman.component.scss']
})
export class RelatorioPlanejamentoKertzmanComponent implements OnInit, AfterViewInit {

  constructor(private location: Location, private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private leituraJsonBlobService:LeituraJsonBlobService,
    private listarTabelaCapitalizacaoSaqueService: ListarTabelaCapitalizacaoSaqueService,
    private listarTabelaCapitalizacaoService: ListarTabelaCapitalizacaoService,
    private route: ActivatedRoute,
    private listatCalculoChaveService: ListarCalculoChaveService,
    private listarCalculoResultadoService: ListarCalculoResultadoService) { }


  caso: CasoPlanejamentoPrevidenciario;

  logoUrl: string;


  linhasCapitalizacaoMensal: CapitalizacaoMensal[];
  linhasCapitalizacaoMensalSaque: CapitalizacaoMensal[];

  calculoRowKey: string;
  calculoPartitionKey: string;
  calculoUrl: string;

  resultadoCalculo: ResultadoCalculo;

  casoMelhor:CasoPlanejamentoPrevidenciario;
  simulacoes:CasoPlanejamentoPrevidenciario[];

  calculoPlanejamentoKertzman:CalculoPlanejamentoKertzman;

  contribuicoes:Contribuicao[];

  usuario:Usuario;


  carregarDados(request: CalculoPlanejamentoKertzman) {

    

    

    this.calculoPlanejamentoKertzman = request

    LogService.log("carregar dados relatorio kertzman", this.calculoPlanejamentoKertzman);

    this.usuario = this.calculoPlanejamentoKertzman.usuario;

    this.casoMelhor = this.calculoPlanejamentoKertzman.relatorio.otimizacao;
    this.caso = this.casoMelhor;
    LogService.log("carregar dados caso", this.caso);
    // this.simulacoes = this.calculoPlanejamentoKertzman.simulacoes;

    // this.linhasCapitalizacaoMensal = CapitalizacaoMensal.tabelaCapitalizacao(this.caso);
   
    // this.linhasCapitalizacaoMensalSaque = CapitalizacaoMensal.tabelaCapitalizacaoSaque(this.caso);

   
    // // this.logoUrl = "../../../assets/img/brand/android-chrome-512x512.png";

    // this.logoUrl = this.calculoPlanejamentoKertzman.usuario.logoUrl;



    // this.contribuicoes = this.caso.beneficio.relatorioRendaMensalInicial.contribuicoes;



  }

  ngAfterViewInit(): void {
   
  }

  ngOnInit(): void {

    LogService.log('relatorio planejamento kertzman');
    this.route.queryParams.subscribe(params => {


      this.calculoUrl = params.url;
      this.calculoRowKey = params.rowkey;
      this.calculoPartitionKey = params.partitionkey;

      let resultadoCalculo: ResultadoCalculo = new ResultadoCalculo();

      resultadoCalculo.rowKey = this.calculoRowKey;
      resultadoCalculo.partitionKey = this.calculoPartitionKey;


      let calculo: Calculo = new Calculo();
      calculo.rowKey = this.calculoRowKey;
      calculo.partitionKey = this.calculoPartitionKey;
      calculo.tipo = CalculoTipos.KERTZMAN;


      this.leituraJsonBlobService.ListarJsonBlob(this.calculoUrl, request =>{

        let calculo:CalculoPlanejamentoKertzman = request as CalculoPlanejamentoKertzman;
        // console.log('relatorio kertzman', calculo);


        
        this.carregarDados(request as CalculoPlanejamentoKertzman);
      });
      
    });

  }


}
