<div class="row ">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">Imposto de Renda
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroImpostoRenda" aria-expanded="false" aria-controls="helpQuadroImpostoRenda"></i>
        </h5>

        <div class="collapse" id="helpQuadroImpostoRenda">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 ">
                                Imposto de renda
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div class="row py-4" *ngIf="opcoesPlanejamento">

    <h5 class="text-uppercase mb-0 text-default">
        Investimento
    </h5>
    <div class="form-group w-100">
        <div class="form-inline d-flex align-items-center">
            <div class="btn-group w-100" btnRadioGroup [(ngModel)]="opcoesPlanejamento?.impostoRenda.investimento">
                <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Considerar custo de Imposto de Renda no total do investimento</label>
                <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Não considerar o Imposto de Renda no valor total do investimento</label>

            </div>
        </div>
    </div>

</div>


<div *ngIf="edicaoImpostoRenda && opcoesPlanejamento?.impostoRenda?.investimento" class="row py-4 d-flex">
    <div class="col-3  d-flex py-2">
        <div class="col-12 py-2 d-flex flex-column justify-content-between bg-secondary rounded ">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Número de dependentes
            </h5>
            <input class=" form-control text-left mb-0" placeholder type="text" [(ngModel)]="edicaoImpostoRenda.numeroDependentes" />
        </div>
    </div>
    <div class="col-3  d-flex py-2">
        <div class="col-12 py-2 d-flex flex-column justify-content-between  bg-secondary rounded ">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Despesas médicas por ano
            </h5>
            <input [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" [(ngModel)]="edicaoImpostoRenda.totalDespesasMedicas" />
        </div>
    </div>
    <div class="col-3  d-flex py-2">
        <div class="col-12 py-2 d-flex flex-column justify-content-between  bg-secondary rounded ">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Despesas com Educação por ano
            </h5>
            <input [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" [(ngModel)]="edicaoImpostoRenda.totalDespesasEducacao" />
        </div>
    </div>
    <div class="col-3  d-flex py-2">
        <div class="col-12 py-2 d-flex flex-column justify-content-between  bg-secondary rounded ">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Contribuições em Previdência Privada por ano
            </h5>
            <input [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" [(ngModel)]="edicaoImpostoRenda.totalContribuicaoPrevidenciaPrivada" />
        </div>
    </div>
    <div class="col-3  d-flex py-2">
        <div class="col-12 py-2 d-flex flex-column justify-content-between  bg-secondary rounded ">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Despesas com Habitação por ano
            </h5>
            <input [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" [(ngModel)]="edicaoImpostoRenda.totalDespesasHabitacao" />
        </div>
    </div>
    

    <div class="col-3  d-flex py-2">
        <div class="col-12 py-2 d-flex flex-column justify-content-between  bg-secondary rounded ">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Doações Incentivadas por Ano
            </h5>
            <input [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" [(ngModel)]="edicaoImpostoRenda.totalDoacoesIncentivadas" />
        </div>
    </div>

    <div class="col-3  d-flex py-2">
        <div class="col-12 py-2 d-flex flex-column justify-content-between  bg-secondary rounded ">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Contribuições em Fundo de Aposentadoria do Servidor Público por ano
            </h5>
            <input [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" [(ngModel)]="edicaoImpostoRenda.totalContribuicoesFundoAposentadoriaServidorPublico" />
        </div>
    </div>

    <div class="col-3  d-flex py-2">
        <div class="col-12 py-2 d-flex flex-column justify-content-between  bg-secondary rounded ">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Pensão Alimentícia por Ano
            </h5>
            <input [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" [(ngModel)]="edicaoImpostoRenda.totalPensaoAlimenticia" />
        </div>
    </div>
</div>