<div *ngIf="opcoesMatriz && opcoesMatriz.listaAliquotas">

    <div class="pb-4">
        <div class="row ">
            <div class="col">
                <h5 class=" h3 mb-0 linha-quadro">Valor da Contribuição
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroAtividadeSecundaria" aria-expanded="false" aria-controls="helpQuadroAtividadeSecundaria"></i>
                </h5>

                <div class="collapse" id="helpQuadroAtividadeSecundaria">

                    <div class="row py-4 d-flex ">

                        <div class="col-3 py-2 d-flex">
                            <div class="row bg-secondary rounded m-2">
                                <div class="col d-flex p-2">

                                    <p class=" p-2 lh-1">
                                        Escolha quais salários e alíquotas serão consideradas no cálculo
                                    </p>

                                </div>
                                <div class="mt-auto pb-2 text-right">
                                    <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-1">
                <div class="dropdown">

                    <div data-bs-toggle="dropdown" aria-expanded="false" role="button">
                        <div class="row">
                            <div class="col-auto text-center">
                                <i class="comando bi bi-plus-square-dotted fs-6"></i>
                                <p class="h6">
                                    Incluir <br>Salário <br>& Alíquota
                                </p>
                            </div>
                        </div>

                    </div>

                    <div #dropdownMenu id="dropAddOutroValorContribuicao" class="dropdown-menu">
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                    <li><a class="dropdown-item" role="button" (click)="addPerfilContributivo(0.11, 'sm')">11% do Salário Mínimo</a></li>
                                    <li><a class="dropdown-item" role="button" (click)="addPerfilContributivo(0.11, 'teto')">11% do Teto (Sócio do Simples Nacional)</a></li>
                                    <li><a class="dropdown-item" role="button" (click)="addPerfilContributivo(0.2, 'sm')">20% do Salário Mínimo</a></li>
                                    <li><a class="dropdown-item" role="button" (click)="addPerfilContributivo(0.2, 'teto')">20% do Teto</a></li>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="container">
                            <div class="row ">
                                <div class="col">
                                    <p>Outros valores</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <input [inputMask]="currencyInputMask" [(ngModel)]="novoSalario" placeholder="R$ 0,00" class=" form-control text-left" #inputTitulo type="text" />
                                    <p class="h6">
                                        Salário de contribuição
                                    </p>
                                </div>
                                <div class="col-6 ">
                                    <input [inputMask]="jurosInputMask" [(ngModel)]="novaAliquota" placeholder="0,00" class=" form-control text-left" #inputTitulo type="text" />
                                    <p class="h6">
                                        Alíquota
                                    </p>
                                </div>
                            </div>

                            <div class="col-12 text-right">
                                <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddValorContribuicao()"></i>
                                <i class="comando bi bi-arrow-right fs-4" role="button" (click)="confirmarAddValorContribuicao()"></i>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="col">
                <div *ngIf="!(opcoesMatriz && opcoesMatriz.opcoesPlanejamento && opcoesMatriz.opcoesPlanejamento.length > 0)" class="row py-2">
                    <p>
                        Está vazio por aqui
                    </p>
                </div>

                <div class="row">
                    <ng-container *ngIf="opcoesMatriz && opcoesMatriz.opcoesPlanejamento">
                        <ng-container *ngFor="let perfil of opcoesMatriz.opcoesPlanejamento">
                            <div class="col-3 item">
                                <div class="row p-2">
                                    <div class="col-12 bg-secondary rounded py-4">
                                        <div class="row">
                                            <div class="col-auto">
                                                <p class="lh-1 fw-light">
                                                    {{perfil.edicaoSalarioAliquota.aliquota * 100 | number : '1.0-2'}}%
                                                    <ng-container *ngIf="perfil.edicaoSalarioAliquota.salario == salarioMinimo" class="h2">
                                                        sobre o Salário Mínimo {{perfil.edicaoSalarioAliquota.salario | currency}}
                                                    </ng-container>
                                                    <ng-container *ngIf="perfil.edicaoSalarioAliquota.salario == tetoInss" class="h2">
                                                        sobre o Teto do INSS {{perfil.edicaoSalarioAliquota.salario | currency}}
                                                    </ng-container>

                                                    <ng-container *ngIf="perfil.edicaoSalarioAliquota.salario != salarioMinimo && perfil.edicaoSalarioAliquota.salario != tetoInss" class="h2">
                                                        sobre {{perfil.edicaoSalarioAliquota.salario | currency}}
                                                    </ng-container>
                                                </p>

                                            </div>
                                        </div>
                                        <div class="row justify-content-end">
                                            <div class="col-auto excluir-item">
                                                <i class="bi bi-trash" role="button" (click)="excluirPerfil(perfil)"></i>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                </div>

            </div>
        </div>
    </div>

    <div class="pb-4">
        <app-frequencia-contributiva *ngIf="opcoesMatriz && opcoesMatriz.frequencias" [frequencias]="opcoesMatriz.frequencias"></app-frequencia-contributiva>
    </div>

    <div class="pb-4">
        <app-intervalo-contribuicoes-matriz *ngIf="opcoesMatriz && opcoesMatriz.intervalos" [intervalos]="opcoesMatriz.intervalos"></app-intervalo-contribuicoes-matriz>
        
    </div>

    <div class="pb-4">
        <app-add-atividade-matriz *ngIf="opcoesMatriz && opcoesMatriz.atividades" [atividades]="opcoesMatriz.atividades"></app-add-atividade-matriz>
    </div>

    <div class="pb-4">

        <div class="row ">
            <div class="col">
                <h5 class=" h3 mb-0 linha-quadro">Produto Financeiro Comparativo
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroProdutoFinanceiro" aria-expanded="false" aria-controls="helpQuadroProdutoFinanceiro"></i>
                </h5>

                <div class="collapse" id="helpQuadroProdutoFinanceiro">

                    <div class="row py-4 d-flex ">

                        <div class="col-3 py-2 d-flex">
                            <div class="row bg-secondary rounded m-2">
                                <div class="col d-flex p-2">

                                    <p class=" p-2 lh-1 ">
                                        Os resultados dos benefícios dos planos do INSS serão comparados ao retorno acumulado com a rentabilidade informada, visando auxiliar na decisão entre realizar aportes no INSS ou escolher outro produto financeiro. Esse retorno será ajustado considerando uma tributação de 15% e uma inflação projetada de 5%.
                                    </p>

                                </div>
                                <div class="mt-auto pb-2 text-right">
                                    <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-1">

                <div class="dropdown">

                    <div data-bs-toggle="dropdown" aria-expanded="false" role="button">
                        <div class="row">
                            <div class="col-auto text-center">
                                <i class="comando bi bi-plus-square-dotted fs-6"></i>
                                <p class="h6">
                                    Incluir <br>Produto<br> Financeiro
                                </p>
                            </div>
                        </div>
                    </div>

                    <div #dropdownMenu class="dropdown-menu">
                        <li>
                            <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.13)">
                                Rentabilidade 13% a.a.
                            </button>
                        </li>
                        <li>
                            <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.14)">
                                Rentabilidade 14% a.a.
                            </button>
                        </li>
                        <li>
                            <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.15)">
                                Rentabilidade 15% a.a.
                            </button>
                        </li>

                        <div class="container">
                            <div class="col-12 py-2">
                                <h5 class=" card-title text-uppercase mb-0 text-default">
                                    Outro valor
                                </h5>
                                <input [inputMask]="jurosInputMask" [(ngModel)]="novaRentabilidade" placeholder="00 %" class=" form-control text-left" #inputTitulo type="text" />
                                <p class="h6">
                                    Rentabilidade Anual
                                </p>
                            </div>
                            <div class="col-12 text-right py-2">
                                <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddRentabilidade()"></i>
                                <i class="comando bi bi-arrow-right fs-4" role="button" (click)="addRentabilidade()"></i>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col">
                <!-- <div class="row py-2">
                <p>
                    Comparativo entre o valor de contribuições no INSS e a capitalização do mesmo valor
                </p>
            </div> -->

                <div class="row">
                    <ng-container *ngFor="let rentabilidade of opcoesMatriz.listaRentabilidades">
                        <div class="col-3 item">
                            <div class="row p-2">
                                <div class="col-12 bg-secondary rounded py-4">
                                    <div class="row">
                                        <div class="col-auto">
                                            <p class="lh-1 fw-light">
                                                Rentabilidade {{rentabilidade * 100 | number : '1.0-2'}}% a.a.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end">
                                        <div class="col-auto excluir-item">
                                            <i class="bi bi-trash" role="button" (click)="excluirRendimento(rentabilidade)"></i>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </div>

            </div>
        </div>
    </div>


    <div class="">
        <app-editor-imposto-renda [edicaoImpostoRenda]="opcoesMatriz.edicaoImpostoRenda" [opcoesPlanejamento]="opcoesMatriz.opcoesPlanejamento[0]" ></app-editor-imposto-renda>
    </div>

    <div class="">
        <!-- <app-editor-otimizacao-kertzman></app-editor-otimizacao-kertzman> -->
    </div>

    <div class="pb-4">
        <div class="row ">
            <div class="col">
                <h5 class=" h3 mb-0 linha-quadro">Otimização da Aposentadoria por Idade
                    <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroOtimizacao" aria-expanded="false" aria-controls="helpQuadroOtimizacao"></i>
                </h5>
        
                <div class="collapse" id="helpQuadroOtimizacao">
        
                    <div class="row py-4 d-flex ">
        
                        <div class="col-3 py-2 d-flex">
                            <div class="row bg-secondary rounded m-2">
                                <div class="col d-flex p-2">
        
                                    <p class=" p-2 lh-1 ">
                                        Método Kertzman
                                    </p>
        
                                </div>
                                <div class="mt-auto pb-2 text-right">
                                    <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                                </div>
        
                            </div>
        
                        </div>
        
                    </div>
        
                </div>
            </div>
        
        </div>

        <div class="py-4">
            <div class="row">
                <div class="col-12">
                    
                    
                    <div class="form-group mb-3 py-2">
                        <h5 class="text-uppercase mb-0 text-default">
                            Método Kertzman
                        </h5>
                        <div class="form-inline d-flex align-items-center">
                            <div class="btn-group w-100" btnRadioGroup (ngModelChange)="aplicarOtimizacao($event)" [(ngModel)]="opcoesMatriz.otimizacaoKertzman">
                                <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Não otimizar</label>
                                <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Aplicar otimização Método Kertzman</label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>