import { ConfigService } from './../../../../../servicos/ui/config.service';
import { ExtratoBeneficio } from './../../../../../modelos/previdenciario/extratobeneficio';
import { DestaqueKertzmanComponent } from './../destaque-kertzman/destaque-kertzman.component';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Marcador } from 'src/app/modelos/common/marcador';
import { Global } from 'src/app/modelos/global';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { RelatorioPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioplanejamentoprevidenciario';
import { RelatorioRMI } from 'src/app/modelos/previdenciario/relatoriormi';
import { FasesPlanejamento } from 'src/app/modelos/ui/fasesplanejamento';
import { FasesPlanejamentoKertzman } from 'src/app/modelos/ui/fasesplanejamentokertzman';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { ListarRelatorioPlanejamentoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-planejamento-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { ExtratoBeneficioComponent } from '../extrato-beneficio/extrato-beneficio.component';
import { ListarMontagemCasoPlanejamentoKertzmanService } from 'src/app/servicos/calculo/previdenciario/listar-montagem-caso-planejamento-kertzman.service';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';


@Component({
  selector: 'app-sumario-resultado-planejamento-kertzman',
  templateUrl: './sumario-resultado-planejamento-kertzman.component.html',
  styleUrls: ['./sumario-resultado-planejamento-kertzman.component.scss']
})
export class SumarioResultadoPlanejamentoKertzmanComponent implements OnInit, AfterViewInit {

  @ViewChild('destaqueKertzman') destaqueKertzman: DestaqueKertzmanComponent;
  @ViewChild('extratoBeneficio') extratoBeneficio: ExtratoBeneficioComponent;

  @Input() calculoPlanejamentoKertzman: CalculoPlanejamentoKertzman;

  @Output() visualizacaoRelatorioEvent = new EventEmitter<CasoPlanejamentoPrevidenciario>();
  @Output() gerarPdfEvent = new EventEmitter<boolean>();

  @Output() otimizacaoEspecificaEvent = new EventEmitter<CalculoPlanejamentoKertzman>();


  relatorioVisivel: boolean;

  aguarde: boolean;


  casoSelecionado: CasoPlanejamentoPrevidenciario;

  casoBase: CasoPlanejamentoPrevidenciario;

  verCasoVisivel: boolean;

  casoMenorPayback: CasoPlanejamentoPrevidenciario;

  casoMelhor: CasoPlanejamentoPrevidenciario;

  marcadorCaso: Marcador;



  simulacoes: CasoPlanejamentoPrevidenciario[];

  beneficios: Beneficio[];

  constructor(private notificacoesService: NotificacoesService, private listarPDFService: ListarPDFService,
    private listarMontagemCasoPlanejamentoKertzmanService: ListarMontagemCasoPlanejamentoKertzmanService,
    private calculeiApiService: CalculeiApiService,
    private configService: ConfigService) {
    this.configService.getAguarde().subscribe(aguarde => this.aguarde = aguarde);
  }



  ngOnInit(): void {
    // console.log('calculo otimizacao', this.calculoPlanejamentoKertzman.otimizacao);
    // this.verCaso(this.casoMelhor20);
    // this.carregarDados();


  }

  ngAfterViewInit(): void {

  }


  carregarDados(calculo: CalculoPlanejamentoKertzman) {
    // this.calculoPlanejamentoKertzman = calculo;

    // this.casoMelhor = this.calculoPlanejamentoKertzman.casos[0];

    // if (this.destaqueKertzman) {
    //   this.destaqueKertzman.carregarDados(this.calculoPlanejamentoKertzman);

    // }


    // this.simulacoes = this.calculoPlanejamentoKertzman.simulacoes;

    // let beneficios:Beneficio[] = [];

    // // console.log('sumario calculo');
    // // console.log(this.casoMelhor.simulacoes);

    // // console.log('marcador caso');
    // // console.log(this.casoMelhor.marcador);

    // if(this.casoMelhor.marcador != undefined){
    //   this.marcadorCaso = this.casoMelhor.marcador;
    // }
    // if(this.extratoBeneficio != undefined){
    //   this.extratoBeneficio.carregarDados();
    // }
  }






  recalcularOtimizacaoEspecifica(indicativo: number) {

    this.configService.setAguarde(true);
    
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.relatorioVisivel = true;
    // console.log('solicitar recalculo otimizacao especifica', indicativo);

    this.calculoPlanejamentoKertzman.otimizacaoEspecifica = true;
    this.calculoPlanejamentoKertzman.indicativoOtimizacoes = indicativo;

    this.otimizacaoEspecificaEvent.emit(this.calculoPlanejamentoKertzman);

    // this.listarMontagemCasoPlanejamentoKertzmanService.listarMontagem(this.calculoPlanejamentoKertzman)
    //   .then((resultado: CalculoPlanejamentoKertzman) => {
    //     this.calculoPlanejamentoKertzman = resultado;
    //     this.carregarDados(this.calculoPlanejamentoKertzman);
    //     this.relatorioVisivel = false;
    //     this.configService.setAguarde(false);
    //     this.otimizacaoEspecificaEvent.emit(this.calculoPlanejamentoKertzman);
    //   });


  }

  gerarPDF() {

    this.gerarPdfEvent.emit(true);


    // this.casoSelecionado.tipoCalculo = CalculoTipos.CASOPLANEJAMENTOKERTZMAN;
    // // console.log('caso selecionado gerarpdf', this.casoSelecionado.totalSubstituicoesTeto)
    // this.casoSelecionado.usuario = Global.usuario;

    // // this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.KERTZMAN;

    // // let requestClean:CalculoPlanejamentoKertzman = JSON.parse(JSON.stringify(this.calculoPlanejamentoKertzman));
    // // requestClean.simulacoes = [];

    // this.calculeiApiService.apiBlobLx("ListarPDF",this.casoSelecionado)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'RELATORIO-PLANEJAMENTO-CONTRIBUTIVO-KERTZMAN-' + this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');



    //   });
  }


}
