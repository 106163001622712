<div *ngIf="calculoPlanejamentoPrevidenciario && calculoPlanejamentoPrevidenciario.casos">

  <!-- <app-destaque-beneficio *ngIf="destaque" [destaque]="destaque"
    (relatorioCompletoEvent)="verRelatorioCompleto($event)"></app-destaque-beneficio> -->

  <div [className]="aguarde ? 'visible': 'invisible'">
    <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
  </div>
  <div [className]="!aguarde ? 'visible': 'invisible'">

    <h5 class=" h3 mb-0 linha-quadro pb-4">Simulação de Regras de Transição</h5>

    <div class="row flex d-flex">
      <div class="col-6  flex d-flex p-0 " *ngFor="let caso of calculoPlanejamentoPrevidenciario.casos; index as i;">

        <div class="row w-100 rounded  bg-secondary m-2" style="min-height: 250px;" (click)="showCasoCanvas(caso)" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">

          <div class="row p-0 m-0">
            <div class="col">
              <p class="display-1 lh-1 fw-lighter">
                {{caso.qualificacaoReferencia }}
              </p>
              <p class="h6 lh-1 mb-0">
                {{caso.beneficio.qualificacaoDetalhada}}
              </p>
              <p class="h6 lh-1 mb-0">
                {{caso.beneficio.qualificacao}}
              </p>
            </div>

          </div>

          <div class="row p-0 m-0 align-items-end text-right">

            <div class="row p-0 m-0">
              <div class="col-12 text-right">
                <p class="display-5">
                  {{caso.beneficio.relatorioRendaMensalInicial.rendaMensalInicial| currency}}
                </p>
                <p class="lh-1 h2 fw-light">
                  <span class="h4 mx-0 px-0 ">Data da Aposentadoria</span> {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}

                </p>
                <p class="lh-1 h2 fw-light">
                  <span class="h4 mx-0 px-0 ">Novas contribuições</span> {{caso.totalContributivoComplementarMeses | number : '1.0-0' }}
                </p>

                <p class="lh-1 h2 fw-light">
                  <span class="h4 mx-0 px-0 ">Investimento</span> {{caso.totalInvestimento | currency}}
                </p>
              </div>

              <!-- <div class="col-12 flex d-flex">

                <div class="col-4  text-center">

                  <div class="progress m-0 p-0">
                    <div class="progress-bar bg-success m-0 p-0" role="progressbar" [style.width]="(caso.beneficio.relacaoIdadeCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">

                    </div>
                  </div>

                  <div class="row p-0 m-0 ">
                    <span class="h6 mt-0 pt-0">
                      Idade {{(caso.beneficio.relacaoIdadeCarencia | number : '1.0-0')}}%
                    </span>

                  </div>

                </div>

                <div class="col-4 text-center">

                  <div class="progress m-0 p-0">
                    <div class="progress-bar bg-success m-0 p-0" role="progressbar" [style.width]="(caso.beneficio.relacaoTempoContribuicaoCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">

                    </div>
                  </div>
                  <div class="row p-0 m-0 ">
                    <span class="h6 mt-0 pt-0">
                      Tempo {{caso.beneficio.relacaoTempoContribuicaoCarencia | number : '1.0-0'}}%
                    </span>

                  </div>
                </div>

                <div class="col-4  text-center" *ngIf="caso.beneficio.pontos <= 0">

                  <div class="progress m-0 p-0">
                    <div class="progress-bar bg-success m-0 p-0" role="progressbar" [style.width]="(caso.beneficio.relacaoCarenciaMeses | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">

                    </div>
                  </div>
                  <div class="row p-0 m-0 ">
                    <span class="h6 mt-0 pt-0">
                      Carência {{(caso.beneficio.relacaoCarenciaMeses | number : '1.0-0')}}%
                    </span>

                  </div>

                </div>

                <div class="col-4 text-center" *ngIf="caso.beneficio.pontos > 0">

                  <div class="progress m-0 p-0">
                    <div class="progress-bar bg-success m-0 p-0" role="progressbar" [style.width]="(caso.beneficio.relacaoPontosCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">

                    </div>
                  </div>
                  <div class="row p-0 m-0 ">
                    <span class="h6 mt-0 pt-0">
                      Pontos {{(caso.beneficio.relacaoPontosCarencia | number : '1.0-0')}} %
                    </span>

                  </div>

                </div>

              </div> -->

            </div>

          </div>

        </div>

        <div class="offcanvas h-100 offcanvas-bottom" data-bs-backdrop="static" data-bs-scroll="false" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">

          <div class="offcanvas-header ml-6 mr-2 justify-content-between">
            <div *ngIf="casoCanvas" class="w-100 mr-4">
              <div class="row d-flex">
                <div class="col-auto ">
                  <span class="h1 lh-1 fw-lighter"> {{casoCanvas.beneficio.qualificacaoReferencia}}</span>

                </div>
                <div class="col-auto p-0">

                  <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpSequencia" aria-expanded="false" aria-controls="helpSequencia"></i>

                </div>
              </div>

              <div class="collapse" id="helpSequencia">
                <p class="lh-1 py-2">
                  Detalhamento da simulação
                </p>

              </div>
            </div>
            <!-- <span *ngIf="sequencia" class="h3 mb-0 linha-quadro" id="offcanvasBottomLabel">Sequência {{sequencia.sequencia}}</span> -->
            <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
            <div class="row flex d-flex align-items-top justify-content-end">

              <div class="hstack">
                <!-- <div [className]="aguarde ? 'visible': 'invisible'">
                  <div class="mx-4">
                    <app-aguarde-local [aguarde]="aguarde"></app-aguarde-local>
                  </div>
                </div> -->
                <div class>
                  <i role="button" class="comando bi bi-filetype-pdf fs-6" (click)="gerarPDFCaso(casoCanvas)" container="body" ngbTooltip="PDF"></i>

                </div>

                <div class>
                  <i role="button" class="comando bi bi-x fs-6" data-bs-dismiss="offcanvas" container="body" ngbTooltip="Fechar"></i>

                </div>
              </div>

            </div>

          </div>
          <div class="offcanvas-body ml-6 mr-2">
            <!-- <div class="py-2">
              <app-resumo-simulacao *ngIf="casoCanvas" [caso]="casoCanvas"></app-resumo-simulacao>
            </div> -->

            <div class="pb-2">
              <app-relatorio-beneficio #relatorioBeneficio *ngIf="beneficioCanvas" [beneficio]="beneficioCanvas"></app-relatorio-beneficio>
            </div>
            <div class="py-2">
              <app-relatorio-caso-planejamento #relatorioCaso *ngIf="casoCanvas" [caso]="casoCanvas"></app-relatorio-caso-planejamento>
            </div>

          </div>

          <!-- <div class="offcanvas-header">
            <h5 *ngIf="casoCanvas" class="offcanvas-title" id="offcanvasBottomLabel">{{casoCanvas.beneficio.qualificacaoDetalhada}}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="row p-0 mb-4 m-0 rounded flex-row-reverse">
              <div class="col-auto px-0 mx-1">
                <i role="button" class="comando bi bi-filetype-pdf fs-3  m-2" (click)="gerarPDFCaso(casoCanvas)"></i>
              </div>
            </div>
            <div class="row p-0 m-0 text-left">
              <app-relatorio-beneficio #relatorioBeneficio *ngIf="beneficioCanvas" [beneficio]="beneficioCanvas"></app-relatorio-beneficio>
              <app-relatorio-caso-planejamento #relatorioCaso *ngIf="casoCanvas" [caso]="casoCanvas"></app-relatorio-caso-planejamento>
            </div>
          </div> -->
        </div>

      </div>

    </div>

  </div>

  <!-- <div class="py-2">
    <app-ranking-rmi *ngIf="calculoBeneficios.beneficios" [beneficios]="calculoBeneficios.beneficios"
      (rmiSelecionadaEvent)="verRelatorioCompleto($event)"></app-ranking-rmi>
  </div>

  <div class="py-2">
    <app-evolucao-salarios-pos-real *ngIf="calculoBeneficios.beneficios"
      [beneficio]="calculoBeneficios.beneficios[0]"></app-evolucao-salarios-pos-real>
  </div> -->

</div>
