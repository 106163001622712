import { Component, Input, OnInit } from '@angular/core';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-tabela-planos-relatorio-matriz',
  templateUrl: './tabela-planos-relatorio-matriz.component.html',
  styleUrl: './tabela-planos-relatorio-matriz.component.scss'
})
export class TabelaPlanosRelatorioMatrizComponent implements OnInit {

  @Input() matriz: MatrizPlanejamento;

  constructor() { }

  ngOnInit(): void {
    LogService.log('tabela planos matriz relatorio', this.matriz)
  }

}
