<div class="row">
  <div class="col">
    <h5 class=" h3 mb-0 linha-quadro">
      Matriz de Planos
      <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpMatriz" aria-expanded="false" aria-controls="helpMatriz"></i>
    </h5>

    <div class="collapse" id="helpMatriz">

      <div class="row d-flex ">
        <div class="col">
          <p>
            Lucro (ROI) de cada cenário por alíquota e salário
          </p>
        </div>

      </div>

      <div class="row d-flex ">

        <div class="col-3 py-2 d-flex">
          <div class="row bg-secondary rounded m-2 w-100">
            <div class="col d-flex p-2">

              <div class="vstack">
                <p class=" display-4 fw-light p-2 ">
                  Como funciona a matriz de planejamento?
                </p>
                <p class=" p-2 lh-1">
                  Ela mostra todos os planos de aposentadoria possíveis de acordo com as opções de salário e alíquota, quantidade de contribuições, e periodicidade de contribuições que foram informadas
                  no passo anterior: "Opções"
                </p>

              </div>

            </div>
            <div class="mt-auto pb-2 text-right">
              <i class="comando bi bi-grid-3x3-gap-fill fs-3"></i>

            </div>

          </div>

        </div>

        <div class="col-3 py-2 d-flex">
          <div class="row bg-secondary rounded m-2 w-100">
            <div class="col d-flex p-2">

              <div class="vstack">
                <p class=" display-4 fw-light p-2 ">
                  Nome do plano
                </p>
                <p class=" p-2 lh-1">
                  O nome do plano é a regra do benefício do INSS seguido do sinal + e o número de contribuições necessárias para completar o plano.
                </p>
                <p class=" p-2 lh-1">
                  Por exemplo, para a regra de Pontos em que o plano foi simulado com 85 novas contribuições, o nome do plano será: Pontos +85.
                </p>

              </div>

            </div>
            <div class="mt-auto pb-2 text-right">
              <!-- <i class="comando bi bi-plus-lg fs-3"></i> -->

            </div>

          </div>

        </div>

      </div>
    </div>

  </div>
</div>

<div class="row py-2">

  <div class="col">
    <span class> Selecionar critério de comparação </span>
    <div class="dropdown">
      <button class="btn btn-outline-default dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{referenciaMatrizOpcao}}
      </button>
      <ul class="dropdown-menu">
        <li>
          <button class="dropdown-item" (click)="selecaoReferenciaMatriz('roi')">Lucro estimado</button>
        </li>
        <li>
          <button class="dropdown-item" (click)="selecaoReferenciaMatriz('beneficio')">Valor do benefício</button>
        </li>
        <li>
          <button class="dropdown-item" (click)="selecaoReferenciaMatriz('taxa')">Taxa de retorno</button>
        </li>
        <li>
          <button class="dropdown-item" (click)="selecaoReferenciaMatriz('porcentagemteto')">Porcentagem do Teto</button>
        </li>
        <li>
          <button class="dropdown-item" (click)="selecaoReferenciaMatriz('investimento')">Investimento</button>
        </li>

      </ul>
    </div>

  </div>

</div>
<div *ngIf="!esconderControles">
    
</div>


<div class="row ">
  <div class="row py-2">
    <div class="col" role="button">
      <ejs-heatmap [dataSource]="dataSource"
        id="heatmap-container"
        [paletteSettings]="paletteSettings"
        (cellRender)='cellRender($event)'
        (tooltipRender)='tooltipRender($event)'
        (cellClick)=(cellClick($event))
        [allowSelection]='false'
        [cellSettings]="cellSettings"
        [legendSettings]="legendSettings"
        [tooltipSettings]="tooltipSettings"
        [xAxis]="xAxis"
        [yAxis]="yAxis"></ejs-heatmap>
    </div>
  </div>
</div>