import { Pipe, PipeTransform } from '@angular/core';
import { Periodicidade } from 'src/app/modelos/previdenciario/periodicidade';


@Pipe({
  name: 'periodicidade',
})
export class PeriodicidadePipe implements PipeTransform {

  transform(value: Periodicidade, ...args: unknown[]): string {

    let texto = 'periodicidade';



    switch (value) {
      case Periodicidade.MENSAL:
        texto = 'Mensal';
        break;

      case Periodicidade.BIMESTRAL:
        texto = 'A cada 2 meses';
        break;

      case Periodicidade.TRIMESTRAL:
        texto = 'A cada 3 meses';
        break;

      case Periodicidade.QUADRIMESTRAL:
        texto = 'A cada 4 meses';
        break;
      case Periodicidade.QUINQUIMESTRAL:
        texto = 'A cada 5 meses';
        break;
      case Periodicidade.SEMESTRAL:
        texto = 'A cada 6 meses';
        break;
      case Periodicidade.ANUAL:
        texto = 'Anual';
        break;

      default:
        break;
    }
    return texto;
  }

}
