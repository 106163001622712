<div *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio">

    <div [className]="aguarde ? 'visible': 'invisible'">
        <!-- <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config> -->
    </div>

    <div [className]="!aguarde ? 'visible': 'invisible'">
        <div class="row">
            <div class="col-12">
              <div class="row py-2  flex d-flex justify-content-between">
      
                <div class="col-auto flex d-flex">
                  <div class="col-auto p-0 m-0">
                    <i class="comando bi bi-file-earmark-arrow-down fs-6 " (click)="gerarPDF()" role="button" container="body" ngbTooltip="Relatório em PDF" triggers="hover"></i>
                  </div>
      
                  <!-- <div class="col-auto p-0 m-0">
                    <i class="comando bi bi-bookmarks fs-6 "></i>
                  </div> -->
      
                </div>
                <!-- 
                <div class="col-auto flex d-flex">
                  <div class="col-auto p-0 m-0">
                    <a class="text-default " role="button" (click)="pdfMatriz()" container="body" ngbTooltip="Exportar tabela em PDF">
                      <i class="comando bi bi-filetype-pdf fs-6 "></i>
                    </a>
                  </div>
            
                  <div class="col-auto p-0 m-0">
                    <a class="text-default " role="button" container="body" ngbTooltip="Exportar tabela em excel">
                      <i class="comando bi bi-filetype-xls fs-6 "></i>
                    </a>
                  </div>
                </div> -->
      
              </div>
            </div>
          </div>

        <app-destaque-kertzman [visualizarPdf]="false" (gerarPdfEvent)="gerarPDF($event)" #destaqueKertzman *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-destaque-kertzman>

        <app-variacao-contribuicoes-originais-simulacao-kertzman [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-contribuicoes-originais-simulacao-kertzman>

        <div class="row">
            <div class="col-6">
                <app-variacao-rmi-simulacao-kertzman-chart [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio" (recalcularOtimizacaoEspecificaEvent)="recalcularOtimizacaoEspecifica($event)"></app-variacao-rmi-simulacao-kertzman-chart>

            </div>
            <div class="col-6">
                <app-variacao-payback-simulacao-kertzman [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-payback-simulacao-kertzman>
                
            </div>
        </div>

        


        <div class="row">
            <div class="col-6">
                <app-variacao-taxa-payback-simulacao-kertzman [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-taxa-payback-simulacao-kertzman>

            </div>
            <div class="col-6">
                <app-variacao-lucro-simulacao-kertzman [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-lucro-simulacao-kertzman>
                
            </div>
        </div>
        <app-custo-imposto-renda-anual *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio && calculoPlanejamentoKertzman.relatorio.otimizacao" [caso]="calculoPlanejamentoKertzman.relatorio.otimizacao"></app-custo-imposto-renda-anual>

        <app-retorno-financeiro-beneficio-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-retorno-financeiro-beneficio-kertzman>
        <app-comparativo-capitalizacao-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-comparativo-capitalizacao-kertzman>


        <!-- <app-caso-planejamento-previdenciario [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-caso-planejamento-previdenciario> -->
        
        <!-- <app-imposto-renda-contribuinte-kertzman [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-imposto-renda-contribuinte-kertzman> -->

        <!-- <app-relatorio-beneficio [beneficio]="calculoPlanejamentoKertzman.otimizacao.beneficio"></app-relatorio-beneficio>  -->
    </div>

</div>