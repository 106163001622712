import { TotalDiasSequenciaPipe } from './../../../../compartilhado/pipes/total-dias-sequencia.pipe';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
// import { ExcelExportProperties, GridComponent, TextWrapSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { SeveridadePontoAtencaoCnis } from 'src/app/modelos/previdenciario/severidadepontoatencaocnis';
import { TiposIndicadoresCNIS } from 'src/app/modelos/previdenciario/tiposindicadorescnis';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { TipoAtividade } from 'src/app/modelos/previdenciario/tipoatividade';
import { EditorContribuicoesExcelComponent } from '../editor-contribuicoes-excel/editor-contribuicoes-excel.component';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { TiposVinculos } from 'src/app/modelos/previdenciario/tiposvinculos';
import { ContagemCarencia } from 'src/app/modelos/previdenciario/contagemcarencia';
import { v4 as uuidv4 } from 'uuid';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { LogService } from 'src/app/servicos/log.service';
import Swal from 'sweetalert2';


import * as bootstrap from 'bootstrap';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { FocusEventArgs, SliderChangeEventArgs, SliderComponent, SliderTooltipEventArgs } from '@syncfusion/ej2-angular-inputs';
import { DatePipe } from '@angular/common';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { Marcador } from 'src/app/modelos/common/marcador';
import { TiposMarcador } from 'src/app/modelos/common/tiposmarcador';
import { firstValueFrom } from 'rxjs';
import { AguardeLocalComponent } from '../aguarde-local/aguarde-local.component';


@Component({
  selector: 'app-editor-cnis-grid',
  templateUrl: './editor-cnis-grid.component.html',
  styleUrl: './editor-cnis-grid.component.scss'
})
export class EditorCnisGridComponent implements OnInit {

  // @ViewChild('grid') public grid?: GridComponent;

  @ViewChild('planilhaContribuicoes') public planilhaContribuicoes?: EditorContribuicoesExcelComponent;
  @ViewChildren(AguardeLocalComponent) listaAguarde: QueryList<AguardeLocalComponent>;


  @Input() calculoBeneficios: CalculoBeneficios;
  @Output() calculoBeneficiosChange = new EventEmitter<CalculoBeneficios>();

  @Output() gridTelaCheiaEvent = new EventEmitter<boolean>();

  listaSequencias: RelacaoPrevidenciaria[];
  totalTempoContribuicaoSequencias: string;
  totalTempoContribuicaoSequenciasDias: number;
  totalContribuicoesSequencias: number;
  visualizarSequenciasValidadas: boolean;

  carregarPlanilhas: boolean = false;
  carregarValidacao: boolean = false;
  carregarIndicadores: boolean = false;
  carregarPontosAtencao: boolean = false;


  dataRecorte: Date;

  tiposMarcadores = TiposMarcador;

  @Output() habilitarSalvarEdicaoEvent = new EventEmitter<boolean>();


  sequencia: RelacaoPrevidenciaria;
  novaSequencia: RelacaoPrevidenciaria;
  salarioContribuicaoInicialNovaSequencia: string = "Salário mínimo"
  salarioContribuicaoInicialNovaSequenciaOpcao: string = "minimo"

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  severidadePontoAtencaoCnisType = SeveridadePontoAtencaoCnis;
  tiposIndicadoresCnis = TiposIndicadoresCNIS;

  tiposAtividadeType = TipoAtividade;
  tiposVinculosType = TiposVinculos;
  // toolbarOptions: ToolbarItems[]; //= ['ColumnChooser', 'ExcelExport', 'CsvExport'];
  // exportProperties: ExcelExportProperties = {
  //   exportType: 'AllPages',
  //   fileName: 'sequencias_CNIS',

  // };

  public tooltipSliderDividirVinculo: Object = { placement: 'After', isVisible: true, showOn: 'Auto' };
  public ticksSliderDividirVinculo: Object = { placement: 'After', largeStep: 20, smallStep: 10, showSmallTicks: true };

  constructor(private calculeiApiService: CalculeiApiService,
    private changeDetector: ChangeDetectorRef,
    private elementRef: ElementRef,
    private totalDiasSequenciaPipe: TotalDiasSequenciaPipe,
    private datePipe: DatePipe,
    private notificacoesService: NotificacoesService,
    private configService: ConfigService) {

    this.novaSequencia = new RelacaoPrevidenciaria();
    this.novaSequencia.dataInicio = new Date();
    this.novaSequencia.dataFim = new Date();
    this.novaSequencia.tipoAtividade = TipoAtividade.Normal;
    this.novaSequencia.origemVinculo = "Nova sequência";
    this.novaSequencia.tipoVinculo = TiposVinculos.EMPREGADOOUAGENTEPUBLICO;
    this.novaSequencia.pontosAtencaoCNIS = new Array();
    this.novaSequencia.indicadoresCnis = new Array();

    this.dataRecorte = new Date();
  }

  datePickerSet(event: FocusEventArgs, data) {
    LogService.log('datepicker set', event.model);
    let datePicker: DatePickerComponent = event.model as DatePickerComponent;
    datePicker.enableMask = true;
    datePicker.value = new Date(data);

  }

  fecharDropDividirSequencia(idDrop: string) {
    let editor = <HTMLInputElement>document.getElementById(idDrop);
    editor.classList.remove('show');
  }

  abrirDividirSequencia(sequencia: RelacaoPrevidenciaria, idDatePicker) {
    this.dataRecorte = new Date(sequencia.dataInicio);
    let datePicker: DatePickerComponent = (document.getElementById(idDatePicker) as any).ej2_instances[0];
    datePicker.value = sequencia.dataInicio

  }
  datePickerDataRecorte(event, sequencia: RelacaoPrevidenciaria, idSlider) {

    LogService.log('datepicker data recorte sequencia', [event])

    const oneDay = 1000 * 60 * 60 * 24; // Milissegundos em um dia
    const date1Ms = (new Date(sequencia.dataInicio)).getTime();
    const date2Ms = (new Date(event.value)).getTime();

    // Calcule a diferença em milissegundos
    const differenceMs = Math.abs(date2Ms - date1Ms);

    // Converta a diferença de volta para dias e retorne
    let sliderValue: number = Math.floor(differenceMs / oneDay);

    let slider: SliderComponent = (document.getElementById(idSlider) as any).ej2_instances[0];
    this.dataRecorte = new Date(event.value);
    // slider.min = 0;
    // slider.max = this.totalDiasSequenciaPipe.transform(sequencia);
    // slider.value = sliderValue;



  }

  sliderChange(event: SliderChangeEventArgs, sequencia: RelacaoPrevidenciaria, idDatePicker: string) {

    this.dataRecorte = new Date(this.sequencia.dataInicio);
    this.dataRecorte.setDate(this.dataRecorte.getDate() + (event.value as number));
    let datePicker: DatePickerComponent = (document.getElementById(idDatePicker) as any).ej2_instances[0];
    datePicker.value = this.dataRecorte;
    LogService.log('slider change', [event, datePicker.value]);
  }
  sliderTesouraCreated(event) {

    // LogService.log('slider tesoura created', event);
  }
  tooltipChangeHandler(event: SliderTooltipEventArgs, sequencia: RelacaoPrevidenciaria) {


    event.text = this.datePipe.transform(this.dataRecorte, 'dd/MM/yyyy');
  }
  recortar(sequencia: RelacaoPrevidenciaria, idSlider: string, idDatePicker) {

    let datePicker: DatePickerComponent = (document.getElementById(idDatePicker) as any).ej2_instances[0];
    let slider: SliderComponent = (document.getElementById(idSlider) as any).ej2_instances[0];

    LogService.log('rocortar sequencia', [sequencia, this.dataRecorte, slider.value, datePicker.value]);

    this.notificacoesService.showNotification('default', 'Recortar Sequência', 'Processando recorte de sequência');

    this.calculeiApiService.api("RecortarSequencia", [this.calculoBeneficios, sequencia, this.dataRecorte])
      .subscribe(resultado => {
        this.calculoBeneficios = resultado as CalculoBeneficios;
        this.carregarDados();
        this.planilhaContribuicoes.carregarDados(this.calculoBeneficios);
        this.notificacoesService.showNotification('default', 'Pronto', 'Cnis atualizado com recorte da sequência ' + sequencia.sequencia);

      });
  }

  abrirSequenciaRecorte(sequencia: RelacaoPrevidenciaria) {
    this.dataRecorte = new Date(sequencia.dataInicio);
  }



  ngOnInit(): void {


    // console.log('passos', this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao.passos.map(passo=>passo.descricao));
    this.carregarDados();



  }

  carregarDados() {
    // this.listaSequencias = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias;
    // this.listaSequencias = this.listaSequencias.sort((a, b) => new Date(a.dataInicio).getTime() - new Date(b.dataInicio).getTime());
    // this.totalContribuicoesSequencias = this.listaSequencias.flatMap(sequencia => sequencia.contribuicoes).length;



    // this.totalTempoContribuicaoSequencias = this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao.passos[0].contagemTempo.porExtenso;
    // this.totalTempoContribuicaoSequenciasDias = this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao.passos[0].contagemTempo.totalDias;

    // this.abrirSequencia(this.listaSequencias[0])
  }

  mostrarFiltros() {
    // this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.splice(0,1);
    // console.log('total sequencias', this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.length);

  }

  exportarCsv() {
    // (this.grid as GridComponent).csvExport(this.exportProperties);
  }
  exportarXls() {
    // (this.grid as GridComponent).excelExport(this.exportProperties);
  }

  changeDataInicio(event, sequencia: RelacaoPrevidenciaria, index: number) {
    var editor = document.getElementById("editorDataInicioSequencia" + index) as HTMLInputElement;
    var aguarde: AguardeLocalComponent = this.listaAguarde.filter(aguarde => aguarde.id === "aguardeDataInicioSequencia" + index)[0];
    // LogService.log('data inicio change', editor.value);
    // LogService.log('data inicio change', aguarde.aguarde);

    aguarde.aguarde = true;


    let novaData: Date = AppComponent.stringToDate(editor.value);
    if (novaData) {
      sequencia.dataInicio = novaData;
      this.atualizarPrepCnis().then(resultado => {
        this.habilitarSalvarEdicaoEvent.emit(true);
        aguarde.aguarde = false;
      });


    } else {

      editor.value = this.datePipe.transform(sequencia.dataInicio, 'dd/MM/yyyy');
      setTimeout(() => {
        aguarde.aguarde = false;
      }, 200);

    }





  }
  changeDataFim(event, sequencia: RelacaoPrevidenciaria, index: number) {

    var editor = document.getElementById("editorDataFimSequencia" + index) as HTMLInputElement;
    var aguarde: AguardeLocalComponent = this.listaAguarde.filter(aguarde => aguarde.id === "aguardeDataFimSequencia" + index)[0];
    // LogService.log('data fim change', editor.value);

    let novaData: Date = AppComponent.stringToDate(editor.value);

    aguarde.aguarde = true;

    if (novaData) {
      sequencia.dataFim = novaData;
      this.atualizarPrepCnis().then(resultado => {
        this.habilitarSalvarEdicaoEvent.emit(true);
        aguarde.aguarde = false;
      });

    } else {
      editor.value = this.datePipe.transform(sequencia.dataFim, 'dd/MM/yyyy');
      setTimeout(() => {
        aguarde.aguarde = false;
      }, 200);
    }

  }



  abrirSequencia(sequencia: RelacaoPrevidenciaria) {

    this.sequencia = sequencia;
    LogService.log('sequencia', sequencia);

  }


  excluirSequencia(sequencia: RelacaoPrevidenciaria, index: number) {


    Swal.fire({
      title: 'Excluir Sequência',
      text: 'Deseja mesmo excluir a sequência' + sequencia.sequencia + ' ' + sequencia.origemVinculo + ' ?',
      iconHtml: '<i class="bi bi-trash"></i>',
      iconColor: '#000',
      showCloseButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Ok, excluir sequência',
      confirmButtonColor: '#000',
      showCancelButton: true,
      cancelButtonText: "cancelar"

    }).then(result => {

      if (result.isConfirmed) {
        this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.splice(index, 1);
        this.atualizarPrepCnis();
        this.habilitarSalvarEdicaoEvent.emit(true);

        this.notificacoesService.showNotification('default', 'Excluir Sequência', 'Sequência excluída. A sequência ' + sequencia.sequencia + ' ' + sequencia.origemVinculo + ' foi removida da lista de sequências');




      }

    });



  }

  adicionarVinculoFinalizado(sequencia: RelacaoPrevidenciaria) {

    // if (this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias != null && this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.length > 0) {
    //   let indexSequencia: number[] = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.map(sequencia => { return sequencia.sequencia });
    //   let proximoIndexSequencia = indexSequencia.sort((a, b) => b - a)[0] + 1;
    //   sequencia.sequencia = proximoIndexSequencia;
    // }
    // else {
    //   sequencia.sequencia = 1;
    // }


    // this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.push(sequencia);

    this.atualizarPrepCnis();

    // let novaSequenciaControle: HTMLElement = document.getElementById('listagemvinculo' + (sequencia.sequencia - 1).toString())
    // novaSequenciaControle.scrollIntoView({ behavior: "smooth" });

    this.habilitarSalvarEdicaoEvent.emit(true);

  }

  // atualizarPrepCnis() {
  //   this.calculeiApiService.api("AtualizarEdicaoCnis", this.calculoBeneficios)
  //     .subscribe(resultado => {
  //       this.calculoBeneficios = resultado as CalculoBeneficios;
  //       this.carregarDados();
  //       this.planilhaContribuicoes.carregarDados(this.calculoBeneficios)
  //     });
  // }

  async atualizarPrepCnisTroca(){
    try {
      // Converta o Observable em uma Promise
      const resultado = await firstValueFrom(this.calculeiApiService.api("AtualizarEdicaoCnis", this.calculoBeneficios));

      let atualizacao: CalculoBeneficios = resultado as CalculoBeneficios;

      // Atualize o cálculo de benefícios
      LogService.log('resultado atualizar prep cnis completo', atualizacao);
      
      this.calculoBeneficios = atualizacao;
      this.carregarDados();

      // Carregue dados da planilha de contribuições
      // this.planilhaContribuicoes.carregarDados(this.calculoBeneficios);
    } catch (error) {
      console.error('Erro ao atualizar o CNIS:', error);
    }
  }

  async atualizarPrepCnis() {
    try {
      // Converta o Observable em uma Promise
      const resultado = await firstValueFrom(this.calculeiApiService.api("AtualizarEdicaoCnis", this.calculoBeneficios));

      let atualizacao: CalculoBeneficios = resultado as CalculoBeneficios;

      // Atualize o cálculo de benefícios
      LogService.log('resultado atualizar prep cnis', atualizacao);
      let atualizacaoSequencias: RelacaoPrevidenciaria[] = atualizacao.leituraCnis.cnis.relacoesPrevidenciarias;
      this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.map(sequencia => {

        // LogService.log('sequencia match ', atualizacaoSequencias.filter(sequenciaAtualizada=>sequenciaAtualizada.rowKey === sequencia.rowKey)[0]);
        let sequenciaAtualizada = atualizacaoSequencias.filter(sequenciaAtualizada => sequenciaAtualizada.rowKey === sequencia.rowKey)[0];

        this.updateObject<RelacaoPrevidenciaria>(sequencia, sequenciaAtualizada);

      });

      this.calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoIdade = atualizacao.leituraCnis.cnis.totalTempoContribuicaoIdade;
      this.calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoIdadeDias = atualizacao.leituraCnis.cnis.totalTempoContribuicaoIdadeDias;
      this.calculoBeneficios.leituraCnis.cnis.totalCarenciaIdade = atualizacao.leituraCnis.cnis.totalCarenciaIdade;

      this.calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoTempoContribuicao = atualizacao.leituraCnis.cnis.totalTempoContribuicaoTempoContribuicao;
      this.calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoTempoContribuicaoDias = atualizacao.leituraCnis.cnis.totalTempoContribuicaoTempoContribuicaoDias;
      this.calculoBeneficios.leituraCnis.cnis.totalCarenciaTempoContribuicao = atualizacao.leituraCnis.cnis.totalCarenciaTempoContribuicao;

      this.calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoPreReforma = atualizacao.leituraCnis.cnis.totalTempoContribuicaoPreReforma;
      this.calculoBeneficios.leituraCnis.cnis.totalCarenciaPreReforma = atualizacao.leituraCnis.cnis.totalCarenciaPreReforma;
      // this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.sort((a,b)=> new Date(a.dataInicio).getTime() - new Date(b.dataInicio).getTime());

      // Carregue os dados
      this.carregarDados();

      // Carregue dados da planilha de contribuições
      // this.planilhaContribuicoes.carregarDados(this.calculoBeneficios);
    } catch (error) {
      console.error('Erro ao atualizar o CNIS:', error);
    }
  }

  updateObject<T>(target: T, source: Partial<T>): T {
    // Itera sobre as propriedades do segundo objeto (source)
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        // Atualiza a propriedade do objeto alvo (target) com o valor do source
        (target as any)[key] = source[key];
      }
    }
    return target;
  }

  editarTipoVinculo(sequencia: RelacaoPrevidenciaria, tipo: TiposVinculos) {
    sequencia.tipoVinculo = tipo;
    LogService.log("editar tipo vinculo sequencia", sequencia);
    this.atualizarPrepCnis();
    this.habilitarSalvarEdicaoEvent.emit(true);
  }

  editarTipoAtividade(sequencia: RelacaoPrevidenciaria, tipo: TipoAtividade) {
    switch (tipo) {
      case TipoAtividade.Professor:
        sequencia.tipoAtividade = TipoAtividade.Professor
        break;
      case TipoAtividade.Rural:
        sequencia.tipoAtividade = TipoAtividade.Rural
        break;
      case TipoAtividade.Especial15:
        sequencia.tipoAtividade = TipoAtividade.Especial15
        break;
      case TipoAtividade.Especial20:
        sequencia.tipoAtividade = TipoAtividade.Especial20
        break;
      case TipoAtividade.Especial25:
        sequencia.tipoAtividade = TipoAtividade.Especial25
        break;
      case TipoAtividade.Normal:
        sequencia.tipoAtividade = TipoAtividade.Normal
        break;

      default:
        sequencia.tipoAtividade = TipoAtividade.Normal
        break;


    }

    // console.log('sequencia tipo atividade', sequencia.tipoAtividade);

    this.atualizarPrepCnis();

    // let editor = <HTMLInputElement>document.getElementById(idEditor);
    // editor.classList.remove('show');
    // editor.classList.add('collapse');


    this.habilitarSalvarEdicaoEvent.emit(true);
  }

  abrirPlanilhas() {
    this.carregarPlanilhas = true;
    // this.planilhaContribuicoes.carregarDados(this.calculoBeneficios);
  }
  abrirValidacao() {
    this.carregarValidacao = true;
  }
  abrirIndicadores() {
    this.carregarIndicadores = true;
  }
  abrirPontosAtencao() {
    this.carregarPontosAtencao = true;
  }


  atualizarCnisModificado() {
    this.configService.setAguarde(true);
    // this.calculoBeneficios = calculoBeneficios;
    this.calculeiApiService.api("AtualizarEdicaoCnis", this.calculoBeneficios)
      .subscribe(atualizacao => {
        this.calculoBeneficios = atualizacao as CalculoBeneficios;

        // console.log('cnis atualizado', this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicao.passos);

        this.configService.setAguarde(false);
      });
  }


  sequenciasProcessadas(visualizar: boolean) {

    if (visualizar) {
      this.listaSequencias = this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicaoCarencia.passos[this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicaoCarencia.passos.length - 1].sequenciasValidas
      this.listaSequencias = this.listaSequencias.sort((a, b) => new Date(a.dataInicio).getTime() - new Date(b.dataInicio).getTime());
      this.visualizarSequenciasValidadas = true;

      this.totalTempoContribuicaoSequencias = this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicaoCarencia.passos[this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicaoCarencia.passos.length - 1].contagemTempo.porExtenso;
      this.totalContribuicoesSequencias = this.listaSequencias.flatMap(sequencia => sequencia.contribuicoes).length;
    }

    if (!visualizar) {

      this.visualizarSequenciasValidadas = false;

      this.listaSequencias = this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicaoCarencia.passos[0].sequenciasValidas;
      this.listaSequencias = this.listaSequencias.sort((a, b) => new Date(a.dataInicio).getTime() - new Date(b.dataInicio).getTime());
      this.totalTempoContribuicaoSequencias = this.calculoBeneficios.leituraCnis.cnis.fluxoTempoContribuicaoCarencia.passos[0].contagemTempo.porExtenso;
      this.totalContribuicoesSequencias = this.listaSequencias.flatMap(sequencia => sequencia.contribuicoes).length;

    }


  }


  changeDataInicioNovaSequencia(event) {
    // console.log('data inicio nova seqeuncia', event);
  }
  changeDataFimNovaSequencia(event) {
    // console.log('data fim nova seqeuncia', event);
  }



  confirmarNovaSequencia() {
    // console.log('confirmar nova seqeuncia');

    let relacao: RelacaoPrevidenciaria = JSON.parse(JSON.stringify(this.novaSequencia));

    relacao.rowKey = uuidv4();


    //add marcadores para view
    relacao.marcadores = new Array();
    let marcadorNovaSequencia = new Marcador();
    marcadorNovaSequencia.tipoMarcador = TiposMarcador.NOVASEQUENCIA;
    marcadorNovaSequencia.nome = "Nova sequência";
    marcadorNovaSequencia.descricao = "Nova sequência";
    relacao.marcadores.push(marcadorNovaSequencia);


    // console.log('proxima sequencia', this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.map(sequencia => sequencia.sequencia).sort((a, b) => a - b).reverse()[0]);
    let proximaSequencia = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.length > 0 ? this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.map(sequencia => sequencia.sequencia).sort((a, b) => a - b).reverse()[0] + 1 : 1;
    LogService.log('proxima', proximaSequencia);

    relacao.sequencia = proximaSequencia;

    relacao.indicadores = new Array();
    relacao.indicadoresCnis = new Array();
    relacao.contribuicoes = new Array();

    let salario: number = 0;
    let totalContribuicoes: number = monthDiff(new Date(relacao.dataInicio), new Date(relacao.dataFim));
    let dataInicio: Date = new Date(relacao.dataInicio);
    let index = 0;

    while (index < totalContribuicoes) {
      let novaContribuicao = new Contribuicao();

      novaContribuicao.valor = salario;
      novaContribuicao.competencia = new Date(dataInicio.getFullYear(), dataInicio.getMonth() + index, dataInicio.getDate())
      novaContribuicao.indicadoresCnis = new Array();
      novaContribuicao.indicadores = new Array();
      novaContribuicao.carencia = true;
      novaContribuicao.contagemCarencia = ContagemCarencia.AUTOMATICO;
      novaContribuicao.rowKey = uuidv4();
      relacao.contribuicoes.push(novaContribuicao);
      index++
    }

    relacao.totalCarenciaIdade = totalContribuicoes;
    relacao.totalCarenciaTempoContribuicao = totalContribuicoes;

    function monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }


    let salarioApi = "TransformarVinculoSalarioTeto";
    switch (this.salarioContribuicaoInicialNovaSequenciaOpcao) {
      case "minimo":
        salarioApi = "TransformarVinculoSalarioMinimo"

        break;
      case "teto":
        salarioApi = "TransformarVinculoSalarioTeto"
        break;

      default:
        break;
    }

    this.notificacoesService.showNotification('default', 'Nova Sequência', 'Processando nova sequência');

    let editor = <HTMLInputElement>document.getElementById("novaSequencia");
    editor.classList.remove('show');


    this.calculeiApiService.api(salarioApi, relacao)
      .subscribe(resultado => {
        relacao = resultado as RelacaoPrevidenciaria;

        // console.log('nova seqeuncia', relacao);


        this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.push(relacao);
        console.log(this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias);


        this.atualizarPrepCnis();
        this.notificacoesService.showNotification('default', 'Pronto', 'Nova sequência incluída');

        this.habilitarSalvarEdicaoEvent.emit(true);


      });



  }


  editarTipoAtividadeNovaSequencia(tipo: TipoAtividade) {
    switch (tipo) {
      case TipoAtividade.Professor:
        this.novaSequencia.tipoAtividade = TipoAtividade.Professor
        break;
      case TipoAtividade.Rural:
        this.novaSequencia.tipoAtividade = TipoAtividade.Rural
        break;
      case TipoAtividade.Especial15:
        this.novaSequencia.tipoAtividade = TipoAtividade.Especial15
        break;
      case TipoAtividade.Especial20:
        this.novaSequencia.tipoAtividade = TipoAtividade.Especial20
        break;
      case TipoAtividade.Especial25:
        this.novaSequencia.tipoAtividade = TipoAtividade.Especial25
        break;
      case TipoAtividade.Normal:
        this.novaSequencia.tipoAtividade = TipoAtividade.Normal
        break;

      default:
        this.novaSequencia.tipoAtividade = TipoAtividade.Normal
        break;


    }


  }

  editarSalarioContribuicaoInicialNovaSequencia(opcao: string) {
    // console.log('opcao salario contribuicao inicial', opcao);

    switch (opcao) {
      case "minimo":
        this.salarioContribuicaoInicialNovaSequenciaOpcao = "mínimo";
        this.salarioContribuicaoInicialNovaSequencia = "Salário mínimo"

        break;
      case "teto":
        this.salarioContribuicaoInicialNovaSequenciaOpcao = "teto"
        this.salarioContribuicaoInicialNovaSequencia = "Teto do INSS"

        break;

      default:
        break;
    }
  }

  editarTipoFiliacaoVinculoNovaSequencia(tipo: TiposVinculos) {

    // console.log('editar tipo filiacao vinculo', tipo);

    this.novaSequencia.tipoVinculo = tipo;

    // switch (tipo) {
    //   case TiposVinculos.EMPREGADOOUAGENTEPUBLICO:
    //     console.log('empregado ou agente publico');
    //     this.novaSequencia.tipoVinculo = TiposVinculos.EMPREGADOOUAGENTEPUBLICO;

    //     break;

    //   default:
    //     break;
    // }

  }

  abrirDataFimEditor(index: number) {
    let editor = <HTMLInputElement>document.getElementById("dataFimEditor" + index);
    editor.classList.add("show");

  }
  datepickerBlur(idDatepicker: string) {

    let editor = <HTMLInputElement>document.getElementById(idDatepicker);
    editor.classList.remove("show");
    LogService.log('datapicker data fim blur', idDatepicker);
  }


  maximizarGridSequencias(telaCheia: boolean) {

    this.gridTelaCheiaEvent.emit(telaCheia);

  }

  ativarSequencia(sequencia: RelacaoPrevidenciaria, ativar: boolean) {

    LogService.log("ativar sequencia", ativar)
    sequencia.sequenciaInativa = !ativar;
    LogService.log("sequencia ativa", sequencia.sequenciaInativa);


    this.atualizarPrepCnis();

    switch (ativar) {
      case true:
        this.notificacoesService.showNotification('default', 'Ativar Sequência', 'Sequência ativada. A sequência ' + sequencia.sequencia + ' ' + sequencia.origemVinculo + ' será considerada');

        break;
      case false:
        this.notificacoesService.showNotification('default', 'Desativar Sequência', 'Sequência desativada. A sequência ' + sequencia.sequencia + ' ' + sequencia.origemVinculo + ' não será considerada');

        break;

      default:
        break;
    }

  }

  onCreateToast() {
    LogService.log('toast created');
  }

  canvasContribuicoesAtualizarCnisModificado(sequencia: RelacaoPrevidenciaria) {
    this.atualizarCnisModificado();
    // this.atualizarPrepCnis().then(()=>{
    //   this.abrirSequencia(this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.filter(vinculo=>vinculo.rowKey===sequencia.rowKey)[0]);
    // });
  }


  atualizarCanvasSequencia(sequencia: RelacaoPrevidenciaria) {

    LogService.log('atualizar sequencia editor cnis grid antes de atualizarprep', sequencia);
    this.atualizarPrepCnisTroca().then(() => {
      this.abrirSequencia(this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.filter(vinculo => vinculo.rowKey === sequencia.rowKey)[0]);

      if (this.carregarPlanilhas) {
        this.planilhaContribuicoes.carregarDados(this.calculoBeneficios);

      }


      LogService.log('atualizar sequencia', sequencia);
    });
  }






  todosEspecial(grau: number) {
    LogService.log('todos especial', grau);
    this.notificacoesService.showNotification('default', 'Vínculos Especials', 'Processoando atualização de todos os vínculos para especial ' + grau);

    this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.map(sequencia => {
      switch (grau) {
        case 25:
          sequencia.tipoAtividade = TipoAtividade.Especial25;
          break;
        case 20:
          sequencia.tipoAtividade = TipoAtividade.Especial20;
          break;
        case 15:
          sequencia.tipoAtividade = TipoAtividade.Especial15;
          break;

        default:
          break;
      }
    });

    this.atualizarPrepCnis().then(resultado => {
      LogService.log('todos especiais executado', resultado);
      this.notificacoesService.showNotification('default', 'Vínculos Especials', 'Atualização finalizada');
    })

  }
}



