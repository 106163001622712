import { Component } from '@angular/core';
import { StatusAssinatura } from 'src/app/modelos/usuario/statusassinatura';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { LogService } from 'src/app/servicos/log.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-pagina-inicial',
  templateUrl: './pagina-inicial.component.html',
  styleUrl: './pagina-inicial.component.scss'
})
export class PaginaInicialComponent {

  assinaturaAtiva:boolean = false;
  usuario:Usuario;

  constructor(private configService:ConfigService){
    this.configService.getUsuario().subscribe(usuario=>{
      this.usuario = usuario;
      // LogService.log('usuario assinatura', usuario.assinatura);
      
      this.assinaturaAtiva = this.usuario.assinatura && this.usuario.assinatura.statusAssinatura == StatusAssinatura.ATIVA;
      // LogService.log('assinatura ativa', this.assinaturaAtiva);
    });


  }
}
