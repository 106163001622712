export enum ListaRegras {
    RegraDescartes,
    RevisaoVidaToda,
    Permanente,
    NA,
    RegraPontos,
    Pedagio50,
    Pedagio100,
    IdadeMinimaProgressiva,
    IncapacidadePermanente,
    Transicao,
    IncapacidadeTemporaria,

    REGRA1TEMPOCONTRIBUICAOPONTOS,
    REGRA2TEMPOCONTRIBUICAOIDADEPROGRESSIVA,
    REGRA3TEMPOCONTRIBUICAOPEDAGIO50,
    REGRA4TEMPOCONTRIBUICAOPEDAGIO100,
    REGRA5IDADETRANSICAO,
    RURAL,
    PROFESSOR,
    INVALIDEZORDINARIA,
    INVALIDEZACIDENTARIA
}

export class ListaRegrasEx {

    static regraToString(regra: ListaRegras) {

        let resultado = '';
        let conversao: ListaRegras = ListaRegras[regra.toString()];


        switch (conversao) {
            case ListaRegras.Pedagio100:
                resultado = 'Pedágio 100%';
                break;

            case ListaRegras.RegraDescartes:
                resultado = 'Regra dos Descartes';
                break;

            case ListaRegras.Transicao:
                resultado = 'Regra de Transição';
                break;

            case ListaRegras.Permanente:
                resultado = 'Regra Permanente';
                break;

            case ListaRegras.RegraPontos:
                resultado = 'Regra de Pontos';
                break;

            case ListaRegras.Pedagio50:
                resultado = 'Pedágio 50%';
                break;

            case ListaRegras.Pedagio100:
                resultado = 'Pedagio 100%';
                break;

            case ListaRegras.IdadeMinimaProgressiva:
                resultado = 'TC + Idade Mínima';
                break;

            case ListaRegras.IncapacidadePermanente:
                resultado = 'Incapacidade Permanente';
                break;

            case ListaRegras.IncapacidadeTemporaria:
                resultado = 'Incapacidade Temporária';
                break;

            case ListaRegras.RURAL:
                resultado = 'Rural';
                break;
            case ListaRegras.PROFESSOR:
                resultado = 'Professor';
                break;

            case ListaRegras.INVALIDEZORDINARIA:
                resultado = 'Invalidez ordinária';
                break;

            case ListaRegras.INVALIDEZACIDENTARIA:
                resultado = 'Invalidez acidentária';
                break;

            default:
                resultado = 'Regra não reconhecida';
                break;
        }

        return resultado;
    }
}