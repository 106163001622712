import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-matriz-dashboard',
  templateUrl: './matriz-dashboard.component.html',
  styleUrl: './matriz-dashboard.component.scss'
})
export class MatrizDashboardComponent implements OnInit {

  @ViewChild('inputTitulo', {static: false}) inputTitulo: ElementRef;
  @Output() editarTituloEvent = new EventEmitter<string>();

  atualizandoListaMatrizes:boolean;
  listaMatrizes:MatrizPlanejamento[];

  matrizCanvas:MatrizPlanejamento;

  matrizTituloOriginal:string = "Nova matriz de Planejamento";
  

  constructor(private router:Router,
    private configService:ConfigService,
    private calculeiApiService:CalculeiApiService){}

  ngOnInit(): void {
    this.matrizCanvas = new MatrizPlanejamento();
    this.matrizCanvas.nome = this.matrizTituloOriginal;

    this.carregarMatrizes();

    
  }
  novaMatriz(){
    this.router.navigate(['calculei/calculos/matriz']);
  }

  gerarRelatorioMatriz(matriz:MatrizPlanejamento){}

  edicaoTitulo(event){
    this.editarTituloEvent.emit(event.target.value);
  }

  
  blurEditorTitulo(event){
    //salvar nome matriz

    if(event.target.value === ""){
      this.matrizCanvas.nome = this.matrizTituloOriginal;
    }
  }
  focoEditorTitulo(event){
    this.matrizCanvas.nome = "";
    
  }

  abrirMatriz(matriz:MatrizPlanejamento){

    let editor = <HTMLInputElement>document.getElementById(matriz.id);
    editor.classList.remove('collapse');
    editor.classList.add('show');

    this.calculeiApiService.api("AbrirMatrizPlanejamento", matriz)
    .subscribe(resultado=>{

      const navigationExtras: NavigationExtras = {
        state: { matriz: resultado as MatrizPlanejamento }
      }
      this.router.navigate(["/calculei/calculos/matriz"], navigationExtras)
      
    });

    

  }

  carregarMatrizes(){

    this.atualizandoListaMatrizes = true;
    let usuario:Usuario;

    this.configService.getUsuario().subscribe(resultado=>usuario = resultado);
    this.calculeiApiService.api("ListarMatrizPlanejamento", usuario)
    .subscribe(lista=>{
      // console.log('resultado lista matriz', lista);

      this.listaMatrizes = lista as MatrizPlanejamento[];
      this.atualizandoListaMatrizes = false;
    });
  }
}
