import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICellClickEventArgs, ICellEventArgs, ITooltipEventArgs } from '@syncfusion/ej2-angular-heatmap';
import { FrequenciaPipe } from 'src/app/aplicacao/compartilhado/pipes/frequencia.pipe';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { OpcoesNumeroContribuicoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { LogService } from 'src/app/servicos/log.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-heat-map-matriz',
  templateUrl: './heat-map-matriz.component.html',
  styleUrl: './heat-map-matriz.component.scss'
})
export class HeatMapMatrizComponent implements OnInit {


  @Input() calculoMatrizPlanejamento: MatrizPlanejamento;
  @Input() esconderControles:boolean;
  @Input() referencia:string;
  @Output() cenarioCanvasEvent = new EventEmitter<CasoPlanejamentoPrevidenciario>();

  casoCanvas: CasoPlanejamentoPrevidenciario;

  constructor(private currencyPipe: CurrencyPipe,
    private frequenciaPipe: FrequenciaPipe,
    private calculeiApiService: CalculeiApiService,
    private configService: ConfigService,
    private decimalPipe: DecimalPipe) {
    // this.referenciaMatrizOpcao = "Lucro estimado";
  }

  ngOnInit(): void {
    this.carregarDados();

  }





  referenciaMatriz: string;
  referenciaMatrizOpcao: string;

  xAxis: Object;
  yAxis: Object;
  dataSource: Object[][];
  cellSettings: Object;
  legendSettings: Object;
  tooltipSettings: Object;

  public paletteSettings: Object = {
    palette: [
      { color: '#d3d3d3' },
      { color: '#696969' },
    ],
    type: "Gradient"
  };

  regrasUnicas(array: any[]): any[] {
    const mapaUnicos = new Map();

    array.forEach(caso => {
      if (!mapaUnicos.has(caso.qualificacaoReferencia + " " + "+" + caso.totalContributivoComplementarMeses)) {
        mapaUnicos.set(caso.qualificacaoReferencia + " " + "+" + caso.totalContributivoComplementarMeses, caso);
      }
    });

    return Array.from(mapaUnicos.values());
  }



  carregarDados() {
    // LogService.log('aliquotas salarios', this.calculoMatrizPlanejamento.casos.map(caso => caso.aliquotaContributiva + " " + caso.baseContributiva))

    function basesUnicas(array: any[]): any[] {
      const mapaUnicos = new Map();

      array.forEach(caso => {
        if (!mapaUnicos.has(caso.aliquotaContributiva + " " + caso.baseContributiva)) {
          mapaUnicos.set(caso.aliquotaContributiva + " " + caso.baseContributiva, caso);
        }
      });

      return Array.from(mapaUnicos.values());
    }




    const bases = basesUnicas(this.calculoMatrizPlanejamento.casos).map(caso => caso.aliquotaContributiva + " " + caso.baseContributiva);

    const regras = this.regrasUnicas(this.calculoMatrizPlanejamento.casos).map(caso => caso.qualificacaoReferencia + " " + "+" + caso.totalContributivoComplementarMeses);

    // LogService.log('aliquotas salarios filtrados', bases);

    // LogService.log('regras filtrados', regras);


    this.xAxis = {
      valueType: "Category",
      labels: regras,
      textStyle: {
        size: '12px',
        fontWeight: '300',
        fontStyle: 'Normal',
        color: '#000000',
        fontFamily: 'tt-commons-pro',

      }
    };
    this.yAxis = {

      valueType: "Category",
      labels: bases.map(base => (parseFloat(base.split(" ")[0]) * 100).toString() + "%" + " sobre " + this.currencyPipe.transform(parseFloat(base.split(" ")[1]))),

      textStyle: {
        size: '12px',
        fontWeight: '300',
        color: '#000000',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };

    this.cellSettings = {

      border: {
        width: 1,
        radius: 4,
        color: 'white'
      },
      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };

    this.legendSettings = {
      border: {
        width: 1,
        radius: 4,
        color: 'white'
      },
      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };

    this.tooltipSettings = {
      fill: '#000000',
      // border: {
      //     width: 1,
      //     radius: 4,
      //     color: 'white'
      // },
      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };

    // this.dataSource = bases.map(base => this.calculoMatrizPlanejamento.casos.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) === base).map(caso => caso.retornoTotalInvestimentoRoi));


    let montagem: any[][] = new Array();

    regras.forEach(regra => {
      // LogService.log('regra ', regra);
      let casosRegra = this.calculoMatrizPlanejamento.casos.filter(caso => caso.qualificacaoReferencia + " " + "+" + caso.totalContributivoComplementarMeses === regra);
      // LogService.log('casos por regra', [regra, casosRegra]);

      let mapa: any[] = new Array();
      bases.forEach(base => {



        if (casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base).length > 0) {

          switch (this.referenciaMatriz) {
            case 'roi':
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].retornoTotalInvestimentoRoi.toFixed(2))


              break;

            case 'beneficio':
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].valorBeneficio.toFixed(2))

              break;

            case 'taxa':
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].taxaRetornoPlanejamentoContributivo * 100)

              break;

            case 'porcentagemteto':
              let caso: CasoPlanejamentoPrevidenciario = casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0];
              mapa.push(((caso.valorBeneficio * 100) / caso.opcoesPlanejamento.edicaoSalarioAliquota.salarioTeto).toFixed(2));



              break;

            case 'investimento':
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].totalInvestimento)

              break;

            default:
              this.referenciaMatrizOpcao = "Valor do benefício"
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].valorBeneficio.toFixed(2))

              break;
          }


        }
        if (casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base).length == 0) {

          mapa.push(0)

        }

      });
      montagem.push(mapa);
    });


    // LogService.log('montagem', montagem);

    this.dataSource = montagem;





  }

  tooltipRender(args: ITooltipEventArgs): void {

    switch (this.referenciaMatriz) {
      case 'roi':
        args.content = ['Alíquota e salário de ' + args.yLabel + ', na regra ' + args.xLabel + '. Lucro estimado ' + this.currencyPipe.transform((args.value as number))];
        break;

      case 'beneficio':
        // args.displayText = '$ ' + (args.value as number);
        args.content = ['Alíquota e salário de ' + args.yLabel + ', na regra ' + args.xLabel + '. Valor do benefício ' + this.currencyPipe.transform((args.value as number))];
        break;

      case 'taxa':

        args.content = ['Alíquota e salário de ' + args.yLabel + ', na regra ' + args.xLabel + '. Taxa de retorno ' + (args.value as number).toFixed(2) + '%'];
        break;

      case 'porcentagemteto':

        args.content = ['Alíquota e salário de ' + args.yLabel + ', na regra ' + args.xLabel + '. Porcentagem do Teto ' + (args.value as number) + '%'];
        break;

      case 'investimento':

        args.content = ['Alíquota e salário de ' + args.yLabel + ', na regra ' + args.xLabel + '. Investimento ' + this.currencyPipe.transform((args.value as number))];
        break;

      default:
        args.content = ['Alíquota e salário de ' + args.yLabel + ', na regra ' + args.xLabel + '. Lucro estimado ' + this.currencyPipe.transform((args.value as number))];
        // args.displayText = '$ ' + (args.value as number);
        break;
    }

  };

  cellRender(args: ICellEventArgs): void {
    // args.displayText = '$ ' + (args.value as number);
    switch (this.referenciaMatriz) {
      case 'roi':
        args.displayText = this.currencyPipe.transform((args.value as number));
        break;

      case 'beneficio':
        // args.displayText = '$ ' + (args.value as number);
        args.displayText = this.currencyPipe.transform((args.value as number));
        break;

      case 'taxa':

        args.displayText = (args.value as number).toFixed(2) + '%';
        break;

      case 'porcentagemteto':

        args.displayText = (args.value as number) + '%';
        break;

      case 'investimento':

      args.displayText = this.currencyPipe.transform((args.value as number));
        break;

      default:
        args.displayText = this.currencyPipe.transform((args.value as number));
        // args.displayText = '$ ' + (args.value as number);
        break;
    }
  };

  cellClick(args: ICellClickEventArgs): void {
    // LogService.log('clique celula y', args.yValue);
    this.casoCanvas = null;
    let caso: CasoPlanejamentoPrevidenciario = this.calculoMatrizPlanejamento.casos
      .filter(caso => caso.qualificacaoReferencia + " " + "+" + caso.totalContributivoComplementarMeses === args.xValue)
      .filter(caso => caso.aliquotaContributiva * 100 + "% sobre " + this.currencyPipe.transform(caso.baseContributiva) === args.yValue)
    [0];





    if (caso) {

      this.cenarioCanvasEvent.emit(caso);
    }
  }

  fecharCanvasCaso() {
    document.getElementById('canvasCaso').classList.remove('show');
  }


  selecaoReferenciaMatriz(referencia: string) {

    this.referenciaMatriz = referencia;
    this.carregarDados();

    switch (referencia) {
      case 'roi':
        LogService.log('referencia matriz', 'roi');
        this.referenciaMatrizOpcao = "Lucro estimado"


        break;

      case 'beneficio':
        LogService.log('referencia matriz', 'beneficio');
        this.referenciaMatrizOpcao = "Valor do benefício"

        break;

      case 'taxa':
        LogService.log('referencia matriz', 'taxa');
        this.referenciaMatrizOpcao = "Taxa de retorno a.m."

        break;
      case 'porcentagemteto':
        LogService.log('referencia matriz', 'taxa');
        this.referenciaMatrizOpcao = "Porcentagem do teto"

        break;
      case 'investimento':
        LogService.log('referencia matriz', 'taxa');
        this.referenciaMatrizOpcao = "Investimento"

        break;

      default:
        LogService.log('referencia matriz default', referencia);
        break;
    }

  }


  gerarPDFCaso(caso: CasoPlanejamentoPrevidenciario) {

  }



}
