
<div class="row py-4">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">
            Métricas
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#quadroAjudaMetricas" aria-expanded="false" aria-controls="quadroAjudaMetricas">

            </i>
            <div class="collapse" id="quadroAjudaMetricas">
                <p class=" lh-1 py-2">
                    Lista de indicadores presentes nas sequências e contribuições do CNIS.
                </p>

            </div>
        </h5>
    </div>
</div>

<div class="row d-flex">
    
    <div class="col-4 py-2 d-flex">
        <div class="row bg-secondary rounded m-2 w-100">
            <div>

                <div class="row">
                    <div class="col">
                        <h5 class=" h3 mb-0 py-4">
                            Total de Tempo e Carência para Aposentadorias por Idade
                        </h5>
                    </div>
                </div>

                <div class="vstack gap-3">
                    <div>
                        <span class="d-block lh1 fw-lighter">Total de Tempo de Contribuição </span>
                        <span class="d-block lh1 fw-light display-3">{{calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoIdade.porExtenso}} </span>
                    </div>

                    

                    <div>
                        <span class="d-block lh1 fw-lighter">Total de Carência </span>
                        <span class="d-block lh1 fw-light display-3">{{calculoBeneficios.leituraCnis.cnis.totalCarenciaIdade}} </span>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="col-4 py-2 d-flex">
        <div class="row bg-secondary rounded m-2 w-100">

            <div>

                <div class="row">
                    <div class="col">
                        <h5 class=" h3 mb-0 py-4">
                            Total de Tempo e Carência para Aposentadorias por Tempo
                        </h5>
                    </div>
                </div>
                <div class="vstack gap-3">
                    <div>
                        <span class="d-block lh1 fw-lighter">Total de Tempo de Contribuição </span>
                        <span class="d-block lh1 fw-light display-3">{{calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoTempoContribuicao.porExtenso}} </span>
                    </div>

                    <div>
                        <span class="d-block lh1 fw-lighter">Total de Carência </span>
                        <span class="d-block lh1 fw-light display-3">{{calculoBeneficios.leituraCnis.cnis.totalCarenciaTempoContribuicao}} </span>
                    </div>
                </div>

            </div>

        </div>

    </div>

    

    <div class="col-4 py-2 d-flex">
        <div class="row bg-secondary rounded m-2 w-100">
            
            <div>

                <div class="row">
                    <div class="col">
                        <h5 class=" h3 mb-0 py-4">
                            Total de Tempo e Carência para Pré Reforma 13/11/2019
                        </h5>
                    </div>
                </div>
                <div class="pb-2">
                    <span class="lh1 fw-lighter h2"> </span>
                </div>
                <div class="vstack gap-3">
                    <div>
                        <span class="d-block lh1 fw-lighter">Total de Tempo de Contribuição </span>
                        <span class="d-block lh1 fw-light display-3">{{calculoBeneficios.leituraCnis.cnis.totalTempoContribuicaoPreReforma.porExtenso}} </span>
                    </div>

                    

                    <div>
                        <span class="d-block lh1 fw-lighter">Total de Carência </span>
                        <span class="d-block lh1 fw-light display-3">{{calculoBeneficios.leituraCnis.cnis.totalCarenciaPreReforma}} </span>
                    </div>
                </div>

            </div>

        </div>

    </div>

    

    <div class="col-4 py-2 d-flex">
        <div class="row bg-secondary rounded m-2 w-100">
            <div>
                <div class="row">
                    <div class="col">
                        <h5 class=" h3 mb-0 py-4">
                            Períodos Sem Remuneração
                        </h5>
                    </div>
                </div>
                <app-metrica-periodos *ngIf="calculoBeneficios" [calculoBeneficios]="calculoBeneficios"></app-metrica-periodos>

            </div>

        </div>

    </div>

    <div class="col-4 py-2 d-flex">
        <div class="row bg-secondary rounded m-2 w-100">
            <div>
                <div class="row">
                    <div class="col">
                        <h5 class=" h3 mb-0 py-4">
                            Contribuições Concomitântes
                        </h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <span class="d-block lh1 fw-light display-3" >{{totalConcomitantes}}</span>
                    </div>
                </div>
                
            </div>

        </div>

    </div>

    <div class="col-4 py-2 d-flex">
        <div class="row bg-secondary rounded m-2 w-100">
            <div>
                <div class="row">
                    <div class="col">
                        <h5 class=" h3 mb-0 py-4">
                            Contribuições abaixo do Salário Mínimo
                        </h5>
                    </div>
                </div>
                

                <div class="row">
                    <div class="col">
                        <span class="d-block lh1 fw-light display-3" >{{totalAbaixoMinimo}}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="col-4 py-2 d-flex">
        <div class="row bg-secondary rounded m-2 w-100">
            <div>
                <div class="row">
                    <div class="col">
                        <h5 class=" h3 mb-0 py-4">
                            Restituição
                        </h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <span class="d-block lh1 fw-light display-3" >{{totalRestituicao | currency}}</span>
                    </div>
                </div>
                <!-- <app-metrica-excedente *ngIf="calculoBeneficios" [calculoBeneficios]="calculoBeneficios"></app-metrica-excedente> -->
            </div>

        </div>

    </div>

</div>

<div class="row">

</div>