<!-- <app-destaque-pre-analise-cumprimento-requisitos *ngIf="beneficio"
    [destaque]="beneficio"></app-destaque-pre-analise-cumprimento-requisitos> -->

<!-- <app-evolucao-salarios-pos-real *ngIf="beneficio" [beneficio]="beneficio"></app-evolucao-salarios-pos-real> -->

<!-- <app-quadro-segurado *ngIf="beneficio" [cnis]="beneficio.cnis"></app-quadro-segurado> -->

<!-- <app-analise-beneficio *ngIf="beneficio" [beneficio]="beneficio"></app-analise-beneficio> -->


<div class="py-2">
    <app-quadro-beneficio-preview [beneficio]="caso.beneficio"></app-quadro-beneficio-preview>
</div>
<div class="py-2">
    <app-resumo-simulacao *ngIf="caso" [caso]="caso"></app-resumo-simulacao>
</div>
<div class="py-2">
    <app-quadro-beneficio *ngIf="caso.beneficio"[beneficio]="caso.beneficio"></app-quadro-beneficio>
</div>

<div class="py-2">
    <app-extrato-beneficio #extratoBeneficio *ngIf="caso.beneficio" [beneficio]="caso.beneficio"></app-extrato-beneficio>
</div>
