import { Component, Input, OnInit } from '@angular/core';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';

@Component({
  selector: 'app-destaques-analise-cnis',
  templateUrl: './destaques-analise-cnis.component.html',
  styleUrl: './destaques-analise-cnis.component.scss'
})
export class DestaquesAnaliseCnisComponent implements OnInit{
  

  totalRestituicao:number;
  totalConcomitantes:number;
  totalAbaixoMinimo:number;

  @Input() calculoBeneficios:CalculoBeneficios;

  constructor(){}


  ngOnInit(): void {


    this.totalRestituicao = this.calculoBeneficios.leituraCnis.cnis.contribuicoesComposicaoExcedentes
    .reduce((acumulador, contribuicao) => acumulador + contribuicao.valorRestituicaoAtualizado, 0);

    this.totalConcomitantes = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.flatMap(sequencia=>sequencia.contribuicoes).filter(contribuicao=>contribuicao.concomitante).length;

    this.totalAbaixoMinimo = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias.flatMap(sequencia=>sequencia.contribuicoes).filter(contribuicao=>contribuicao.salarioMinimoCompetencia > contribuicao.valorOriginal && (new Date(contribuicao.competencia).getTime() > new Date(1994,7,1).getTime())).length;
    
  }

}
