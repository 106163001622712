
<div class="">
    <table class="table table-bordered table-sm  table-striped  table-hover align-middle">
        <thead class style="display: table-header-group; page-break-inside: avoid !important;">

            <tr>
                <th class=" alignt-middle text-center" scope="col">
                    <div class="vstack">
                        <div>
                            <span class>
                                Plano
                            </span>
                        </div>
                        <div>
                            <span class>
                                Alíquota / Salário
                            </span>
                        </div>
                    </div>
                </th>

                <th class=" alignt-middle text-center" scope="col">
                    <div class="vstack">
                        <div>
                            <span class>
                                Data da Aposentadoria
                            </span>
                        </div>
                        <div>
                            <span class>
                                Idade na Data
                            </span>
                        </div>
                    </div>
                </th>

                <th class=" alignt-middle text-center" scope="col">
                    <div class="vstack">
                        <div>
                            <span class>
                                Valor do Benefício
                            </span>
                        </div>
                        <div>
                            <span class>
                                Percentual do teto do INSS
                            </span>
                        </div>
                        <div>
                            <span class>
                                Lucro Estimado
                            </span>
                        </div>
                    </div>
                </th>
                <th class=" alignt-middle text-center" scope="col">
                    <div class="vstack">
                        <div>
                            <span class>
                                Total de novas Contribuições
                            </span>
                        </div>
                        <div>
                            <span class>
                                Valor da Contribuição
                            </span>
                        </div>
                        <div>
                            <span class>
                                Total do Investimento
                            </span>
                        </div>
                    </div>
                </th>

                <th class=" alignt-middle text-center" scope="col">
                    <div class="vstack">
                        <div>
                            <span class>
                                Rentabilidade produto financeiro / Investimento
                            </span>
                        </div>
                        <div>
                            <span class>
                                Valor renda fixa Capitalização
                            </span>
                        </div>
                    </div>
                </th>

            </tr>

        </thead>
        <tbody>
            <ng-container *ngFor="let caso of matriz.casos">
                <tr>
                    <td class style="page-break-inside: avoid !important;">
                        <div class="vstack text-center">
                            <div>
                                {{caso.referenciaMatriz}}
                            </div>
                            <div>
                                <span [ngSwitch]="caso.baseContributiva" class="p-0 m-0">
                                    <span *ngSwitchCase="matriz.tetoInss">
                                        <span> {{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / Teto</span>
                                    </span>
                                    <span *ngSwitchCase="matriz.salarioMinimo">
                                        <span>{{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / Sal. Mín</span>
                                    </span>
                                    <span *ngSwitchDefault>
                                        <span>{{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / {{caso.baseContributiva | currency}} </span>
                                    </span>
                                </span>
                            </div>
                        </div>

                    </td>

                    <td class style="page-break-inside: avoid !important;">
                        <div class="vstack text-center">
                            <div>
                                {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}
                            </div>
                            <div>
                                {{caso.idadeDataAposentadoria.porExtenso}}
                            </div>
                        </div>
                    </td>

                    <td class style="page-break-inside: avoid !important;">
                        <div class="vstack text-center">
                            <div>
                                {{caso.valorBeneficio | currency}}
                            </div>

                            <div>
                                {{(caso.valorBeneficio * 100) / matriz.tetoInss | number : '1.0-0' }}%
                            </div>
                            <div>
                                {{caso.retornoTotalInvestimentoRoi | currency}}
                            </div>
                        </div>
                    </td>

                    <td class style="page-break-inside: avoid !important;">
                        <div class="vstack text-center">
                            <div>
                                + {{caso.totalContributivoComplementarMeses}}
                            </div>

                            <div>
                                {{caso.valorContribuicaoComplementarMensal | currency}}
                            </div>
                            <div>
                                {{caso.totalInvestimento | currency}}
                            </div>
                        </div>

                    </td>

                    <td class style="page-break-inside: avoid !important;">
                        <div class="vstack text-center">
                            <div>
                                {{caso.taxaRetornoPrivadoAno * 100 | number :'1.2-2'}}% / {{caso.totalContributivoCapitalizadoGanhoReal | currency }}
                            </div>
                            <div>
                                {{caso.valorSaqueCapitalizacao | currency }}
                            </div>
                        </div>
                    </td>

                </tr>

            </ng-container>

        </tbody>
    </table>

</div>
