export enum OpcoesNumeroContribuicoesPlanejamento {
    BASICO,
    ESPECIFICOANO,
    ESPECIFICO,
    MANTERQUALIDADESEGURADO,
    COMPLETO,
    PERIODOMESES,
    BIMESTRAL,
    TRIMESTRAL,
    QUADRIMESTRAL,
    QUINQUIMESTRAL,
    SEMESTRAL,
    ANUAL
}

export class OpcoesNumeroContribuicoesPlanejamentoEx {
    static toString(opcao: OpcoesNumeroContribuicoesPlanejamento) {

        let texto: string = "";

        switch (opcao) {
            case OpcoesNumeroContribuicoesPlanejamento.ESPECIFICO:
                texto = "Quantidade específica";

                break;
            case OpcoesNumeroContribuicoesPlanejamento.ESPECIFICOANO:
                texto = "Quantidade específica por ano durante o tempo necessário para cumprir o requisito de idade";

                break;
            case OpcoesNumeroContribuicoesPlanejamento.BASICO:
                texto = "Apenas o necessário";

                break;
            case OpcoesNumeroContribuicoesPlanejamento.MANTERQUALIDADESEGURADO:
                texto = "Apenas o necessário para manter a qualidade de segurado";

                break;

            case OpcoesNumeroContribuicoesPlanejamento.COMPLETO:
                texto = "Até a data da aposentadoria";
                break;

            case OpcoesNumeroContribuicoesPlanejamento.PERIODOMESES:
                texto = "Fazer uma contribuição a cada 'X' meses";
                break;

            case OpcoesNumeroContribuicoesPlanejamento.BIMESTRAL:
                texto = "Bimestral até a data da aposentadoria";
                break;

            case OpcoesNumeroContribuicoesPlanejamento.TRIMESTRAL:
                texto = "Trimestral até a data da aposentadoria";
                break;

            case OpcoesNumeroContribuicoesPlanejamento.QUADRIMESTRAL:
                texto = "Quadrimestral até a data da aposentadoria";
                break;

            case OpcoesNumeroContribuicoesPlanejamento.QUINQUIMESTRAL:
                texto = "Quinquimestral até a data da aposentadoria";
                break;

            case OpcoesNumeroContribuicoesPlanejamento.SEMESTRAL:
                texto = "Semestral até a data da aposentadoria";
                break;
            case OpcoesNumeroContribuicoesPlanejamento.ANUAL:
                texto = "Anualmente até a data da aposentadoria";
                break;


            default:
                break;
        }

        return texto;

    }
}