<div class="row pb-2">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">Plano Indicado
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpMelhorCenario" aria-expanded="false" aria-controls="helpMelhorCenario"></i>
        </h5>

        <div class="collapse" id="helpMelhorCenario">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row w-100 bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 text-justify">
                                Cenário com maior potencial de retorno
                            </p>

                        </div>
                        <!-- <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-cash-coin fs-3"></i>
                        </div> -->

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>
<div class="row" >
    <div class="col">

        <div class="" >
            
            <div class="row">
                <div class="col-4" role="button">
                    <div  >
                        <div>
                            <p class="text-center fw-lighter h6 text-uppercase">Plano</p>
                        </div>
                        <p (click)="showCasoCanvas(caso)" role="button" class="display-1 lh-1 fw-lighter  mb-0">
                            {{caso.qualificacaoReferencia + ' +' + caso.totalContributivoComplementarMeses}}
                        </p>

                        <p class="display-4 lh-1 fw-lighter mb-0">
                            {{caso.tipoBeneficio | beneficio }}
                        </p>
                        
                        <p class="lh-1 fw-lighter">
                            Regra
                        </p>

                        <a (click)="showCasoCanvas(caso)"> ver detalhes do plano</a>
                    </div>
                </div>
                <div class="col-4">
                    <div class="vstack">
                        <div class="">
                            <div>
                                <p class="text-center fw-lighter h6 text-uppercase">Resumo do Plano</p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Data da Aposentadoria
                                </p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{caso.idadeDataAposentadoria.porExtenso}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Idade na Aposentadoria
                                </p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 mb-0">
                                    {{caso.valorBeneficio | currency}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Valor do Benefício
                                </p>
                            </div>
                        </div>
                        <hr>
                        <div *ngIf="caso.totalContributivoComplementarMeses > 0">
                            <div>
                                <p class="text-center fw-lighter h6 text-uppercase">Comparativo de Produto Financeiro</p>
                            </div>

                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{caso.totalContributivoCapitalizadoGanhoReal | currency}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Resultado Acumulado no Produto Financeiro
                                </p>
                            </div>

                            <div>
                                <div class="hstack">
                                    <!-- <div class="">
                                        <i class="bi bi-arrow-down"></i>

                                    </div> -->
                                    <div class="">
                                        <p class="display-4 lh-1 fw-light mb-0">
                                            {{caso.valorSaqueCapitalizacao | currency}}
                                        </p>
                                        <p class="lh-1 fw-lighter">
                                            Benefício Mensal do Resultado Acumulado no Produto Financeiro
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="col-4">
                    <div>
                        <p class="text-center fw-lighter h6 text-uppercase">investimento no Plano</p>
                    </div>

                    <div *ngIf="caso.totalContributivoComplementarMeses > 0">
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.aliquotaContributiva * 100 | number : '1.0-2'}}% sobre {{caso.baseContributiva | currency}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Alíquota e Salário Base
                        </p>
                    </div>

                    <div *ngIf="caso.totalContributivoComplementarMeses > 0">
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.valorContribuicaoComplementarMensal | currency}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Valor da Contribuição
                        </p>
                    </div>

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.totalContributivoComplementarMeses}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Total de Contribuições
                        </p>
                    </div>

                    

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.totalInvestimento | currency}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Total do Investimento
                        </p>
                    </div>

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.retornoTotalInvestimentoRoi | currency}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Estimativa de Retorno do Investimento (Lucro ou ROI)
                        </p>
                    </div>

                    <!-- <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.dataPayback | date : 'MM/yyyy'}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Data do Payback
                        </p>
                    </div>

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.idadePayback.porExtenso}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Idade no Payback
                        </p>
                    </div> -->

                    

                </div>
            </div>

        </div>

    </div>

</div>