import { Component, EventEmitter, input, Input, Output, ViewChild } from '@angular/core';
import { CalculoMapaQualidadeSegurado } from 'src/app/modelos/previdenciario/calculomapaqualidadesegurado';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { ListarCalculoMapaQualidadeSeguradoService } from 'src/app/servicos/calculo/previdenciario/listar-calculo-mapa-qualidade-segurado.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { MapaQualidadeSeguradoComponent } from '../mapa-qualidade-segurado/mapa-qualidade-segurado.component';
import { MapaConsolidadoQualidadeSeguradoComponent } from '../mapa-consolidado-qualidade-segurado/mapa-consolidado-qualidade-segurado.component';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-resultado-qualidade-segurado',
  templateUrl: './resultado-qualidade-segurado.component.html',
  styleUrls: ['./resultado-qualidade-segurado.component.scss']
})
export class ResultadoQualidadeSeguradoComponent {

  @Input() visualizarPdf: boolean = true;
  @Input() calculoMapaQualidadeSegurado: CalculoMapaQualidadeSegurado;

  @Output() relatorioProntoEvent = new EventEmitter<CalculoMapaQualidadeSegurado>();

  @ViewChild('mapa') mapa: MapaQualidadeSeguradoComponent;
  @ViewChild('mapaConsolidado') mapaConsolidado: MapaConsolidadoQualidadeSeguradoComponent;

  aguarde: boolean;



  constructor(private configService: ConfigService,
    private calculeiApiService: CalculeiApiService,
    private notificacoesService: NotificacoesService,
    private listarCalculoMapaQualidadeSeguradoService: ListarCalculoMapaQualidadeSeguradoService) { }


  gerarRelatorio(calculoMapaQualidadeSegurado: CalculoMapaQualidadeSegurado) {

    this.listarCalculoMapaQualidadeSeguradoService.listarCalculo(calculoMapaQualidadeSegurado)
      .then((calculo: CalculoMapaQualidadeSegurado) => {
        // console.log('gerar relatorio mapa qualidade segurado pronto');
        // console.log(calculo);
        this.calculoMapaQualidadeSegurado = calculo;
        this.relatorioProntoEvent.emit(this.calculoMapaQualidadeSegurado);
      });
    this.configService.setAguarde(false);



  }

  carregarDados(calculoMapaQualidadeSegurado: CalculoMapaQualidadeSegurado) {

    this.calculoMapaQualidadeSegurado = calculoMapaQualidadeSegurado;

  }

  gerarPdf() {
    this.configService.setAguarde(true);
    this.calculoMapaQualidadeSegurado.tipoCalculo = CalculoTipos.QUALIDADESEGURADO;
    this.calculoMapaQualidadeSegurado.usuario = Global.usuario;


    this.calculeiApiService.apiBlobServices("ListarPDF", this.calculoMapaQualidadeSegurado)
      // this.calculeiApiService.apiBlob("ListarPDF", this.calculoPlanejamentoKertzman)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO-QUALIDADE-SEGURADO-' + this.calculoMapaQualidadeSegurado.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
        this.configService.setAguarde(false);



      });
  }


}
