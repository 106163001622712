<div class="row">
    <div class="col">
        <p class="h3">
            Nova Ficha
        </p>
        <i class="comando bi bi-plus-square-dotted fs-6" data-bs-toggle="collapse" href="#novafichaeditarea" role="button" aria-expanded="false" aria-controls="novafichaeditarea" role="button"></i>
    </div>
</div>


<!-- <div class="collapse" id="collapseExample">
    <div class="card card-body">
        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
    </div>
</div> -->

<div class="collapse" id="novafichaeditarea">
    <div class="row py-2">
        <div class="col-12">
            <!-- <app-nova-pergunta *ngIf="ficha" (addPerguntaEvent)="addPergunta($event)" ></app-nova-pergunta> -->
            <div class="row py-2">
                <div class="col-3">
                    <h5 class="  text-uppercase mb-0 text-default">
                        Nome da Ficha
                    </h5>
                    <input class=" form-control text-left" type="text" [(ngModel)]="ficha.nome" />
                    <p class="h6">
                        Nome da ficha
                    </p>
                </div>
            </div>

            <div class="row py-2">
                <div class="col">
                    <p class="h3">
                        Adicionar Perguntas
                    </p>
                </div>
            </div>

            <form [formGroup]="novaPerguntaForm">
                <div class="row py-2">
            
                    <div class="col-3">
                        <h5 class="  text-uppercase mb-0 text-default">
                            Título
                        </h5>
                        <input class=" form-control text-left" type="text" placeholder="Ex. exerce alguma atividade como autônomo?" formControlName="titulo" />
                        <p class="h6">
                            Pergunta que vai ser apresentada no formulário
                        </p>
                    </div>
                    
                    
            
                    <div class="col-3">
                        <h5 class=" text-uppercase mb-0 text-default">
                            Descrição
                        </h5>
                        <textarea class=" form-control text-left" type placeholder="Ex. atividade autônoma é aquela que..." formControlName="descricao"></textarea>
                        <p class="h6">
                            Um esclarecimento breve da pergunta (opcional)
                        </p>
                    </div>
            
                    <div class="col-3">
                        <h5 class="  text-uppercase mb-0 text-default">
                            Instruções
                        </h5>
                        <textarea class=" form-control text-left" type placeholder="Ex. certifique-se de que..." formControlName="instrucao"></textarea>
                        <p class="h6">
                            Alguma instrução ou alerta para orientar o entrevistado (opcional)
                        </p>
                    </div>
            
            
                    <div class="col-3">
                        <h5 class="  text-uppercase mb-0 text-default">
                            Link
                        </h5>
                        <input class=" form-control text-left" type="text" placeholder="Ex. https://google.com" formControlName="link" />
                        <p class="h6">
                            Link externo para informações complementares sore a pergunta (opcional)
                        </p>
                    </div>
            
            
                    <div class="col-3 ">
                        <div class="row justify-content-start">
                            <div class="col-auto">
                                <h5 class="text-uppercase mb-0 text-default">
                                    Tipo de Resposta
                                </h5>
                            </div>
                        </div>
                        <div class="row justify-content-start">
                            <div class="col-12">
                                <div class="dropdown w-100">
                                    <button class="btn w-100 btn-default btn-lg  text-left dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{pergunta.tipoResposta | tipoResposta}}
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li *ngFor="let tipo of itemsTiposResposta">
                                            <button class="dropdown-item" (click)="editarTipoResposta(tipo.value)">{{tipo.text}}</button>
                                        </li>
                                    </ul>
                                </div>
                                <p class="h6">
                                    Forma que o campo de resposta aparece ao entrevistado
                                </p>
            
                            </div>
                        </div>
                    </div>
            
                    <div class="col-3" *ngIf="pergunta.tipoResposta === tiposRespostasType.SELECAO || pergunta.tipoResposta === tiposRespostasType.SELECAOMULTIPLA">
            
                        <h5 class="  text-uppercase mb-0 text-default">
                            Nova Opção
                        </h5>
                        <div class=" flex d-flex">
                            <input class=" form-control text-left" type="text" placeholder="Ex. sim tiro pró-labore" #novaOpcao />
                            <i class="comando bi bi-arrow-right fs-6 px-2 mx-2" role="button" (click)="addOpcao()"></i>
                        </div>
                        <p class="h6">
                            Opção da lista de opções de respostas para a pergunta
                        </p>
            
                    </div>
            
                    <div class="col-3 " *ngIf="pergunta.tipoResposta === tiposRespostasType.SELECAO || pergunta.tipoResposta === tiposRespostasType.SELECAOMULTIPLA">
            
                        <div class="row py-2">
                            <div class="col">
                                <h5 class="  text-uppercase mb-0 text-default">
                                    Opções selecionadas
                                </h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto p-0 m-0" *ngFor="let opcao of listaNovasOpcoes">
                                <span class="badge rounded-pill text-bg-dark px-4 m-1">{{opcao}}</span>
                            </div>
                        </div>
                        
                        
                    </div>
            
                    <div class="col-auto text-right align-self-center">
                        <i class="comando bi bi-plus-lg fs-4 " role="button" (click)="addPergunta()"></i>
                    </div>
            
            
                    
            
                </div>
            
                
            
            </form>


        </div>
    </div>

    
    <!-- <app-listagem-perguntas *ngIf="ficha" [ficha]="ficha"></app-listagem-perguntas> -->

    <div *ngIf="ficha.perguntas && ficha.perguntas.length > 0">
        <div class="row">
            <div class="col-12">
                <p class="h3">
                    Perguntas da Ficha
                </p>
            </div>
        </div>
    
        <div class="row">
            <div class="col-12">
                <table class="my-4 table table-striped table-hover  align-middle" >
                    <thead class="" style="display: table-header-group; page-break-inside: avoid !important;">
                      <tr class="text-center ">
                        <th scope="col">Título</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Instruções</th>
                        <th scope="col">Link</th>
                        <th scope="col">Opções</th>
                        <th scope="col">Tipo de Resposta</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-center" *ngFor="let pergunta of ficha.perguntas">
                        <td>{{pergunta.titulo }}</td>
                        <td>{{pergunta.descricao }}</td>
                        <td>{{pergunta.instrucao }}</td>
                        <td>{{pergunta.link }}</td>
                        <td>{{pergunta.tipoResposta | tipoResposta}}</td>
                        <td>
                            <div class="row">
                                <div class="col-auto p-0 m-0" *ngFor="let opcao of pergunta.opcoes">
                                    <span class="badge rounded-pill text-bg-dark px-4 m-1">{{opcao}}</span>
                                </div>
                            </div>
                        </td>
                        
                      </tr>
                      
                    </tbody>
                  </table>
            </div>
        </div>
    </div>

    <div class="row py-2">
        <div class="col-auto">
            <i class="comando bi bi-cloud-arrow-up fs-6" role="button" (click)="salvarFicha()"></i>
        </div>
    </div>
    
</div>
