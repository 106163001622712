import { Component, Input, OnInit } from '@angular/core';
import { OpcoesNumeroContribuicoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';
import { OpcoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesplanejamento';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-frequencia-contributiva',
  templateUrl: './frequencia-contributiva.component.html',
  styleUrl: './frequencia-contributiva.component.scss'
})
export class FrequenciaContributivaComponent implements OnInit {

  @Input() frequencias:OpcoesNumeroContribuicoesPlanejamento[];
  opcoesNumeroContribuicoesPlanejamentoType = OpcoesNumeroContribuicoesPlanejamento;

  constructor() { }


  ngOnInit(): void {

    // LogService.log('opcoes frequencia', this.opcoesPlanejamentoFrequencia);

    if (this.frequencias && this.frequencias.length === 0) {
      
      this.frequencias.push( OpcoesNumeroContribuicoesPlanejamento.BASICO);

      // LogService.log('opcoes frequencia preenchida', this.opcoesPlanejamentoFrequencia);
      
    }

  }

  addOpcao(opcao: OpcoesNumeroContribuicoesPlanejamento) {

    if (!this.frequencias) {

      this.frequencias = new Array<OpcoesNumeroContribuicoesPlanejamento>()

    }

   
   
    this.frequencias.push(opcao);

  }

  excluir(opcoes: OpcoesNumeroContribuicoesPlanejamento) {

    this.frequencias.splice(this.frequencias.indexOf(opcoes), 1);

  }


}
