<div class=" header bg-back-dark">
    <div class=" container-fluid">
        <div class=" header-body">
            <div class=" row align-items-center py-3">
                <!-- <div class=" col-lg-12">
                    <span class=" text-white inline-block mb-0 h6 fw-lighter">Admin</span>
                    <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
                        
                    </nav>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class=" container-fluid mt--3 ">

    <div class=" row">
        <div class=" col-lg-12">
            <div class=" card">
                <div class=" card-header border-dark">
                    <h3 class=" mb-0">Admin</h3>
                    <!-- <h3 *ngIf="titulo" class=" mb-0">{{titulo}}</h3> -->
                </div>
                <div class=" card-body">
                    


                    <div class="  ">

                        <div class="pb-4">
                            <div class="card">

                                <div class="card-body">

                                    <div class="row">
                                        <div class="col">
                                            <span class=" display-3 font-weight-lighter  mb-0 text-default">
                                                Edição Usuário
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row py-2">
                                        <div class="col">

                                        <app-editor-usuario></app-editor-usuario>

                                        </div>

                                    </div>
                                    


                                </div>











                            </div>



                        </div>

                        <div class="pb-4">
                            <div class="card">

                                <div class="card-body">

                                    <div class="row">
                                        <div class="col">
                                            <span class=" display-3 font-weight-lighter  mb-0 text-default">
                                                Planos
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row py-2">
                                        <div class="col">

                                            <app-planos-criados></app-planos-criados>

                                        </div>

                                    </div>



                                    <div class="row py-2">
                                        <div class="col">

                                            <app-planos></app-planos>

                                        </div>

                                    </div>


                                    <div class="row py-2">
                                        <div class="col">

                                            <app-simulador-desconto></app-simulador-desconto>

                                        </div>

                                    </div>


                                    <div class="row py-2">
                                        <div class="col">

                                            <app-novo-plano></app-novo-plano>

                                        </div>

                                    </div>

                                    


                                </div>











                            </div>



                        </div>


                    </div>



                </div>
            </div>
        </div>
    </div>
</div>