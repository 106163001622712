import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
// import { DataSourceChangedEventArgs, headerContent } from '@syncfusion/ej2-angular-grids';
import { CellStyleModel, Column, DataSourceChangedEventArgs, Spreadsheet, SpreadsheetComponent } from '@syncfusion/ej2-angular-spreadsheet';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-editor-contribuicoes-excel',
  templateUrl: './editor-contribuicoes-excel.component.html',
  styleUrl: './editor-contribuicoes-excel.component.scss'
})
export class EditorContribuicoesExcelComponent implements OnInit {



  @Input() calculoBeneficios: CalculoBeneficios;
  @ViewChild('planilha') planilha: SpreadsheetComponent;

  contribuicoesOriginais: Object[];
  contribuicoesProcessadas: Object[];
  contribuicoesExcedentes: Object[];
  contribuicoesComposicaoExcedentes: Object[];

  dataAtualizacao: Date = new Date();


  sequenciasOriginais: RelacaoPrevidenciaria[];
  sequenciasProcessadas: RelacaoPrevidenciaria[];

  public cellStyle: CellStyleModel = {
    fontWeight: 'bold',
    textAlign: 'right',
    fontSize: '14pt'
  };




  constructor(private datePipe: DatePipe) {



  }

  ngOnInit(): void {

    this.carregarDados(this.calculoBeneficios);

    // this.contribuicoes = this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias
    //   .flatMap(sequencia => sequencia.contribuicoes
    //     .map(contribuicao => {
    //       return {
    //         competencia: this.datePipe.transform(new Date(contribuicao.competencia), 'MM/yyyy'),
    //         valor: contribuicao.valor,
    //         salarioMinimoCompetencia: contribuicao.salarioMinimoCompetencia,
    //         tetoCompetencia:contribuicao.tetoCompetencia
    //       }
    //     }));
  }

  created() {
    // LogService.log('created', this.planilha);

    this.planilha.sheets[0].rowCount = this.sequenciasOriginais.flatMap(sequencia => sequencia.contribuicoes).length + 1;
    // this.planilha.sheets[1].rowCount = this.sequenciasProcessadas.flatMap(sequencia => sequencia.contribuicoes).length + 1;

    // this.planilha.sheets[2].rowCount = this.contribuicoesExcedentes.length + 2;
    this.planilha.sheets[1].rowCount = this.contribuicoesComposicaoExcedentes.length + 2;

    this.planilha.numberFormat('mm/yyyy', 'Originais!B2:B' + this.planilha.sheets[0].rowCount);
    // this.planilha.numberFormat('mm/yyyy', 'Validadas!B2:B' + this.planilha.sheets[1].rowCount);
    // this.planilha.numberFormat('mm/yyyy', 'Excedentes!B2:B' + this.planilha.sheets[2].rowCount);
    this.planilha.numberFormat('mm/yyyy', 'Restituição!B2:B' + this.planilha.sheets[1].rowCount);


    this.planilha.numberFormat('#,##0.00', 'Originais!C2:C' + this.planilha.sheets[0].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Validadas!C2:C' + this.planilha.sheets[1].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Excedentes!C2:C' + this.planilha.sheets[2].rowCount);
    this.planilha.numberFormat('#,##0.00', 'Restituição!C2:C' + this.planilha.sheets[1].rowCount);


    this.planilha.numberFormat('#,##0.00', 'Originais!D2:D' + this.planilha.sheets[0].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Validadas!D2:D' + this.planilha.sheets[1].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Excedentes!D2:D' + this.planilha.sheets[2].rowCount);
    this.planilha.numberFormat('#,##0.00', 'Restituição!D2:D' + this.planilha.sheets[1].rowCount);

    this.planilha.numberFormat('#,##0.00', 'Originais!E2:E' + this.planilha.sheets[0].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Validadas!E2:E' + this.planilha.sheets[1].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Excedentes!E2:E' + this.planilha.sheets[2].rowCount);
    this.planilha.numberFormat('0.0%', 'Restituição!E2:E' + this.planilha.sheets[1].rowCount);

    this.planilha.numberFormat('#,##0.00', 'Originais!F2:F' + this.planilha.sheets[0].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Validadas!F2:F' + this.planilha.sheets[1].rowCount);
    this.planilha.numberFormat('0.0%', 'Restituição!E2:E' + this.planilha.sheets[1].rowCount);

    this.planilha.numberFormat('#,##0.00', 'Restituição!F2:F' + this.planilha.sheets[1].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Restituição!H2:H' + this.planilha.sheets[1].rowCount);
    this.planilha.numberFormat('#,##0.00000', 'Restituição!G2:G' + this.planilha.sheets[1].rowCount);
    this.planilha.numberFormat('#,##0.00', 'Restituição!H2:H' + this.planilha.sheets[1].rowCount);

    this.planilha.numberFormat('#,##0.00', 'Originais!H2:H' + this.planilha.sheets[0].rowCount);
    // this.planilha.numberFormat('#,##0.00', 'Validadas!H2:H' + this.planilha.sheets[1].rowCount);


    this.planilha!.cellFormat({ fontWeight: 'bold', fontSize: '12pt', backgroundColor: '#ffffff', color: '#000000', textAlign: 'center' }, 'Originais!A1:h1');
    // this.planilha!.cellFormat({ fontWeight: 'bold', fontSize: '12pt', backgroundColor: '#ffffff', color: '#000000', textAlign: 'center' }, 'Validadas!A1:h1');
    // this.planilha!.cellFormat({ fontWeight: 'bold', fontSize: '12pt', backgroundColor: '#ffffff', color: '#000000', textAlign: 'center' }, 'Excedentes!A1:h1');
    this.planilha!.cellFormat({ fontWeight: 'bold', fontSize: '12pt', backgroundColor: '#ffffff', color: '#000000', textAlign: 'center' }, 'Restituição!A1:J1');

    this.planilha!.cellFormat({ fontFamily: 'Roboto' }, 'Originais!A1:H' + this.planilha.sheets[0].rowCount);
    // this.planilha!.cellFormat({ fontFamily: 'Roboto' }, 'Validadas!A1:H' + this.planilha.sheets[1].rowCount);
    // this.planilha!.cellFormat({ fontFamily: 'Roboto' }, 'Excedentes!A1:H' + this.planilha.sheets[2].rowCount);
    this.planilha!.cellFormat({ fontFamily: 'Roboto' }, 'Restituição!A1:J' + this.planilha.sheets[1].rowCount);

    this.planilha!.conditionalFormat({ type: 'LessThan', value: '0', format: { style: { fontFamily: 'Roboto', color: '#FF0000', backgroundColor: "#ffffff" } }, range: 'Originais!E2:E' + this.planilha.sheets[0].rowCount })
    // this.planilha!.conditionalFormat({ type: 'LessThan', value: '0', format: { style: { fontFamily: 'Roboto', color: '#FF0000', backgroundColor: "#ffffff" } }, range: 'Validadas!E2:E' + this.planilha.sheets[1].rowCount })
    // this.planilha!.conditionalFormat({ type: 'LessThan', value: '0', format: { style: { fontFamily: 'Roboto', color: '#FF0000', backgroundColor: "#ffffff" } }, range: 'Excedentes!E2:E' + this.planilha.sheets[0].rowCount })
    this.planilha!.conditionalFormat({ type: 'LessThan', value: '0', format: { style: { fontFamily: 'Roboto', color: '#FF0000', backgroundColor: "#ffffff" } }, range: 'Restituição!E2:E' + this.planilha.sheets[1].rowCount })


    this.planilha.activeSheetIndex = 1;

  }

  carregarDados(calculoBeneficios: CalculoBeneficios) {

    this.sequenciasOriginais = calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias;
    // this.sequenciasProcessadas = calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias;

    this.contribuicoesComposicaoExcedentes = new Array();
    // this.contribuicoesExcedentes = new Array();

    this.contribuicoesOriginais = this.sequenciasOriginais
      .flatMap(sequencia => sequencia.contribuicoes
        .map(contribuicao => {
          return {
            sequencia: sequencia.sequencia,
            competencia: this.datePipe.transform(new Date(contribuicao.competencia).toISOString(), 'MM/yyyy'),
            valor: contribuicao.valorOriginal,
            salarioMinimoCompetencia: contribuicao.salarioMinimoCompetencia,
            complementacaoSalarioMinimo: (contribuicao.valorOriginal - contribuicao.salarioMinimoCompetencia) >= 0 ? 0 : contribuicao.valorOriginal - contribuicao.salarioMinimoCompetencia,
            tetoCompetencia: contribuicao.tetoCompetencia,
            indice: contribuicao.fatorMultiplicadorCorrecao,
            valorAtualizado: contribuicao.valorAtualizado,
          }
        }));
    // this.planilha.sheets[0].ranges[0].dataSource = this.contribuicoes;




    // this.contribuicoesProcessadas = this.sequenciasProcessadas
    //   .flatMap(sequencia =>
    //     sequencia.contribuicoes.filter(contribuicao => contribuicao.validaContagemCarenciaAposentadoriaIdade && contribuicao.validaContagemCarenciaAposentadoriaTempo).map(contribuicao => {
    //       return {
    //         sequencia: sequencia.sequencia,
    //         competencia: this.datePipe.transform(new Date(contribuicao.competencia), 'MM/yyyy'),
    //         valor: contribuicao.valor,
    //         salarioMinimoCompetencia: contribuicao.salarioMinimoCompetencia,
    //         complementacaoSalarioMinimo: (contribuicao.valor - contribuicao.salarioMinimoCompetencia) >= 0 ? 0 : contribuicao.valor - contribuicao.salarioMinimoCompetencia,
    //         tetoCompetencia: contribuicao.tetoCompetencia,
    //         indice: contribuicao.fatorMultiplicadorCorrecao,
    //         valorAtualizado: contribuicao.valorAtualizado,
    //       }
    //     }));

    // this.contribuicoesExcedentes = this.calculoBeneficios.leituraCnis.cnis.contribuicoesExcedentes ? this.calculoBeneficios.leituraCnis.cnis.contribuicoesExcedentes.map(contribuicao => {

    //   return {
    //     sequencia: contribuicao.vinculoSequencia,
    //     competencia: this.datePipe.transform(new Date(contribuicao.competencia), 'MM/yyyy'),
    //     valor: contribuicao.valor,
    //     tetoCompetencia: contribuicao.tetoCompetencia,
    //     excedente: contribuicao.valor - contribuicao.tetoCompetencia,
    //   }
    // }) : new Array();

    this.contribuicoesComposicaoExcedentes = this.calculoBeneficios.leituraCnis.cnis.contribuicoesComposicaoExcedentes.map(contribuicao => {

      return {
        sequencia: contribuicao.vinculoSequencia,
        competencia: this.datePipe.transform(new Date(contribuicao.competencia), 'MM/yyyy'),
        valor: contribuicao.valorOriginal,
        tetoCompetencia: contribuicao.tetoCompetencia,
        // proporcionalTeto: (contribuicao.valorOriginal) / contribuicao.tetoCompetencia,
        aliquota: contribuicao.aliquota,
        // excedenteTeto: contribuicao.excedenteTeto,
        restituicao: contribuicao.valorRestituicao,
        fator: contribuicao.fatorAtualizacaoRestituicao,
        corrigido: contribuicao.valorRestituicaoAtualizado,
      }
    });

    // LogService.log('contribuicoes excedentes', this.calculoBeneficios.leituraCnis.cnis.contribuicoesExcedentes);
    // LogService.log('contribuicoes excedentes composicao', this.contribuicoesComposicaoExcedentes);



  }
  dataSourceChanged(args: DataSourceChangedEventArgs): void {

    // LogService.log('data source change',);

    this.contribuicoesComposicaoExcedentes.forEach(contribuicao => {

      let atualizacao: Contribuicao = this.calculoBeneficios.leituraCnis.cnis.contribuicoesComposicaoExcedentes[this.contribuicoesComposicaoExcedentes.indexOf(contribuicao)];

      contribuicao['sequencia'] = atualizacao.vinculoSequencia;
      contribuicao['competencia'] = this.datePipe.transform(new Date(atualizacao.competencia), 'MM/yyyy');
      contribuicao['valor'] = atualizacao.valorOriginal;
      contribuicao['tetoCompetencia'] = atualizacao.tetoCompetencia;
      // contribuicao['proporcionalTeto'] = (atualizacao.valorOriginal) / atualizacao.tetoCompetencia;
      contribuicao['aliquota'] = atualizacao.aliquota;
      // contribuicao['excedenteTeto'] = atualizacao.excedenteTeto;
      contribuicao['restituicao'] = atualizacao.valorRestituicao;
      contribuicao['fator'] = atualizacao.fatorAtualizacaoRestituicao;
      contribuicao['corrigido'] = atualizacao.valorRestituicaoAtualizado;



    });

    // LogService.log('calculo beneficio', this.calculoBeneficios.leituraCnis.cnis.contribuicoesComposicaoExcedentes);

    this.created();
  }





}
