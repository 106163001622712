import { Component, Input, OnInit } from '@angular/core';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-resumo-simulacao',
  templateUrl: './resumo-simulacao.component.html',
  styleUrl: './resumo-simulacao.component.scss'
})
export class ResumoSimulacaoComponent implements OnInit{
  

  @Input() caso:CasoPlanejamentoPrevidenciario;

  ngOnInit(): void {

    // LogService.log('caso resumo simulacao', this.caso.tempoIdadeSimulacao);
    
  }

}
