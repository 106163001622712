import { CamposMatriz } from './../../../../../modelos/previdenciario/camposmatriz';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Marcador } from 'src/app/modelos/common/marcador';
import { Global } from 'src/app/modelos/global';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { FiltrosMatriz } from 'src/app/modelos/previdenciario/filtrosmatriz';
import { ListaRegras } from 'src/app/modelos/previdenciario/listaregras';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { StatusBeneficio } from 'src/app/modelos/previdenciario/statusbeneficio';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { LogService } from 'src/app/servicos/log.service';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-resultado-matriz',
  templateUrl: './resultado-matriz.component.html',
  styleUrl: './resultado-matriz.component.scss'
})
export class ResultadoMatrizComponent implements OnInit, OnChanges {

  @Input() calculoMatrizPlanejamento: MatrizPlanejamento;
  @Output() atualizacaoMatrizEvent = new EventEmitter<MatrizPlanejamento>();



  casoCanvas: CasoPlanejamentoPrevidenciario;
  kertzman: CalculoPlanejamentoKertzman;

  tetoInss: number;
  sm: number;

  carregando: boolean;
  aguarde: boolean;

  


  constructor(private notificacoesService: NotificacoesService,
    private fb: FormBuilder,
    private configService: ConfigService,
    private changeDetector: ChangeDetectorRef,
    private calculeiApiService: CalculeiApiService,
    private listarPDFService: ListarPDFService) {


    this.configService.getAguarde().subscribe(status => this.carregando = status && !(this.calculoMatrizPlanejamento.casos.length > 0));
    this.configService.getAguarde().subscribe(aguarde => this.aguarde = aguarde);
    this.configService.getSalarioTeto().subscribe(teto => this.tetoInss = teto);
    this.configService.getSalarioMinimo().subscribe(sm => this.sm = sm);

    
  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log('onchanges resultado matriz', this.calculoMatrizPlanejamento);
  }

  

  ngOnInit(): void {

  }

 
  atualizarMatriz(matriz: MatrizPlanejamento) {
    this.atualizacaoMatrizEvent.emit(matriz);
  }


  pdfMatriz() {

    // console.log('pdf matriz', this.calculoMatrizPlanejamento);

    // this.aguardeVisivel = true;
    this.configService.setAguarde(true);
    // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    // this.calculoPlanejamentoPrevidenciario.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    // this.calculoPlanejamentoPrevidenciario.usuario = Global.usuario;
    this.calculoMatrizPlanejamento.tipoCalculo = CalculoTipos.MATRIZPLANEJAMENTO;
    this.configService.getUsuario().subscribe(usuario => this.calculoMatrizPlanejamento.usuario = usuario);

    this.calculeiApiService.apiBlobServices("ListarPDF", this.calculoMatrizPlanejamento)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'Matriz-Planejamento' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.configService.setAguarde(false);

      });

    // this.listarPDFService.ListarPDF(this.calculoMatrizPlanejamento)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'Matriz-Planejamento' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

    //     // this.aguardeVisivel = false;
    //     this.configService.setAguarde(false);

    //   });



  }

  pdfTabela() {

    // console.log('pdf matriz', this.calculoMatrizPlanejamento);

    // this.aguardeVisivel = true;
    this.configService.setAguarde(true);
    // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    // this.calculoPlanejamentoPrevidenciario.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    // this.calculoPlanejamentoPrevidenciario.usuario = Global.usuario;
    this.calculoMatrizPlanejamento.tipoCalculo = CalculoTipos.TABELAMATRIZ;
    this.configService.getUsuario().subscribe(usuario => this.calculoMatrizPlanejamento.usuario = usuario);

    this.calculeiApiService.apiBlobLx("ListarPDF", this.calculoMatrizPlanejamento)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'Matriz-Planejamento' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.configService.setAguarde(false);

      });

    // this.listarPDFService.ListarPDF(this.calculoMatrizPlanejamento)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'Matriz-Planejamento' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

    //     // this.aguardeVisivel = false;
    //     this.configService.setAguarde(false);

    //   });



  }

  gerarPDFCaso(caso: CasoPlanejamentoPrevidenciario) {
    // console.log('pdf sumario');

    // this.aguardeVisivel = true;
    this.configService.setAguarde(true);
    // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    // this.calculoPlanejamentoPrevidenciario.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    // this.calculoPlanejamentoPrevidenciario.usuario = Global.usuario;
    caso.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    caso.usuario = Global.usuario;

    this.listarPDFService.ListarPDF(caso)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO-' + caso.beneficio.qualificacaoDetalhada + '-RESULTADOS-RMI-' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.configService.setAguarde(false);

      });

  }

  gerarPDFKertzman(caso: CalculoPlanejamentoKertzman) {

    // this.configService.setAguarde(true);

    // caso.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    // caso.usuario = Global.usuario;

    // this.listarPDFService.ListarPDF(caso)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'RELATORIO-' + caso.beneficio.qualificacaoDetalhada + '-RESULTADOS-RMI-' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

    //     // this.aguardeVisivel = false;
    //     this.configService.setAguarde(false);

    //   });

  }



  




  fecharCanvasCaso() {
    document.getElementById("canvasCaso").classList.remove("show");
  }
  fecharCanvasKertzman() {
    document.getElementById("canvasKertzman").classList.remove("show");
  }

  abrirCasoCanvas(caso: CasoPlanejamentoPrevidenciario) {
    LogService.log('abrir caso canvas', [caso.qualificacaoReferencia, caso.opcoesPlanejamento]);

    // window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    this.configService.setAguarde(true);

    document.body.style.cursor = 'wait';
    let calculoRequest: MatrizPlanejamento = JSON.parse(JSON.stringify(this.calculoMatrizPlanejamento));
    calculoRequest.casoReferencia = caso;
    this.calculeiApiService.api('ListarMontagemBeneficioCasoPlanejamento', calculoRequest)
      .subscribe(resultado => {
        LogService.log('resultado listar montagem beneficio', resultado);

        switch (calculoRequest.casoReferencia.tipoCalculo) {
          case CalculoTipos.CASOPLANEJAMENTO:
            let calculoPlanejamento: CalculoPlanejamentoPrevidenciario = resultado as CalculoPlanejamentoPrevidenciario;
            LogService.log('calculo planejamento beneficio', calculoPlanejamento);
            this.casoCanvas = calculoPlanejamento.casos.filter(resultado => resultado.qualificacaoReferencia == caso.qualificacaoReferencia)[0];
            document.getElementById('canvasCaso').classList.add('show')
            this.configService.setAguarde(false);


            break;
          case CalculoTipos.CASOPLANEJAMENTOKERTZMAN:
            let calculoPlanejamentoKertzman: CalculoPlanejamentoKertzman = resultado as CalculoPlanejamentoKertzman;
            LogService.log('calculo planejamento kertzman', calculoPlanejamentoKertzman);
            this.kertzman = calculoPlanejamentoKertzman;
            document.getElementById('canvasKertzman').classList.add('show')
            this.configService.setAguarde(false);
            

            break;

          default:
            break;
        }
        

        document.body.style.cursor = 'default';

      });
  }



  atualizarOtimizacaoEspecifica(atualizacao: CalculoPlanejamentoKertzman) {
    LogService.log('atualizacao especifica', atualizacao);
    this.calcularResultadoOtimizacaoEspecifica()

  }

  calcularResultadoOtimizacaoEspecifica() {

    
    this.configService.setAguarde(true);
    this.changeDetector.detectChanges()

    // console.log('atualizando cnis');

    this.configService.getUsuario().subscribe(usuario => this.kertzman.usuario = usuario);
    // OpcoesPlanejamento.sanitizeCurrency(this.calculoPlanejamentoKertzman.opcoesPlanejamento);

    let prepCalculoApi: CalculoPlanejamentoKertzman = JSON.parse(JSON.stringify(this.kertzman));
    prepCalculoApi.casos = new Array();
    prepCalculoApi.simulacoes = new Array();
    prepCalculoApi.preAnalise = null;
    prepCalculoApi.calculoBeneficios.beneficios = new Array();



    this.calculeiApiService.api("ListarMontagemCasoPlanejamentoKertzman", prepCalculoApi)
      .subscribe(calculo => {

        this.kertzman = calculo as CalculoPlanejamentoKertzman;

        LogService.log('kertman pronto otimizacao especifica', this.kertzman);


        this.configService.setAguarde(false);
        this.changeDetector.detectChanges();

      });
  }







}
