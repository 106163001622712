<nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()" (mouseout)="onMouseLeaveSidenav()">

  <ng-scrollbar orientation="vertical" class=" h-100">
    <!-- content goes here -->

    <div class="scrollbar-inner ">
      <div class="sidenav-header d-flex align-items-center">
        <!-- <a class="navbar-brand" [routerLink]="['previdenciario/calculos/novocalculo']">
          <img src='{{"assets/img/brand/"+ GlobalType.logo + ".svg"}}' width="50" />
        </a> -->
        <div class="mx-auto">
          <!-- Sidenav toggler -->
          <!-- <div class="sidenav-toggler d-none d-xl-block" data-action="sidenav-unpin" data-target="#sidenav-main" (click)="minimizeSidebar()"> -->
          <div class="sidenav-toggler d-none d-xl-block" data-action="sidenav-unpin" data-target="#sidenav-main">

            <!-- <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div> -->

            <a class="sidenav-toggler-inner" [routerLink]="['previdenciario/calculos/novocalculo']">
              <img src='{{"assets/img/brand/"+ GlobalType.logo + ".svg"}}' width="30" />
            </a>
          </div>
        </div>
      </div>
      <div class="navbar-inner ">
        <div class="navbar-collapse collapse" id="sidenav-collapse-main">

          <!-- Collapse header -->

          <!-- items ferramentas -->
          <ul class="navbar-nav">
            <li *ngFor="let menuitem of menuItems" routerLinkActive="active" class="nav-item rounded">

              <!-- menu tipo normal -->
              <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
                <i class="{{ menuitem.icontype }} fs-6"></i>
                <span class="nav-link-text"> {{ menuitem.title }}</span>
              </a>

              <!-- tipo link externo -->
              <a [href]="menuitem.path" target="_blank" *ngIf="menuitem.type === 'linkexterno'" class="nav-link">
                <i class="{{ menuitem.icontype }} fs-6"></i>
                <span class="nav-link-text"> {{ menuitem.title }}</span>
              </a>
            </li>
          </ul>

          <div class="py-4"></div>

        </div>
      </div>
    </div>

    <div class="fixed-bottom">
      <div class="scrollbar-inner ">
        <div class="navbar-inner ">
          <div class="navbar-collapse collapse" id="sidenav-collapse-main">

            <!-- items configuracao -->
            <ul class="navbar-nav">
              <li *ngFor="let menuitem of menuItemsConfig" routerLinkActive="active" class="nav-item rounded">

                <!-- menu tipo normal -->
                <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
                  <i class="{{ menuitem.icontype }} fs-6"></i>
                  <span class="nav-link-text"> {{ menuitem.title }}</span>
                </a>

                <!-- tipo link externo -->
                <a [href]="menuitem.path" target="_blank" *ngIf="menuitem.type === 'linkexterno'" class="nav-link">
                  <i class="{{ menuitem.icontype }} fs-6"></i>
                  <span class="nav-link-text"> {{ menuitem.title }}</span>
                </a>
              </li>

              <li  routerLinkActive="active" class="nav-item rounded">

                <!-- menu tipo normal -->
                <div class="nav-link" role="button" (click)="sair()">
                  <i class="bi bi-door-open fs-6"></i>
                  <span class="nav-link-text">Sair</span>
                </div>

                
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>

  </ng-scrollbar>

</nav>