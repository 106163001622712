<div class=" header bg-back-dark">
    <div class=" container-fluid">
      <div class=" header-body">
        <div class=" row align-items-center py-3">
          <!-- <div class=" col-lg-12">
            <span class=" text-white inline-block mb-0 h6 fw-lighter">Novo Cálculo de Auxílio-acidente</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  
  <div class=" container-fluid mt--3  ">
  
    <div class=" row">
      <div class=" col-12">
        <div class="  ">
          <div class="pb-4">
  
            <div class=" card ">
  
              <div class="row">
                <div class="col-12">
  
                  <div class=" card-body  ">
  
                    <app-barra-navegacao *ngIf="navegacao" #barraNavegacao [aguarde]="aguardeVisivel"
                      [navegacao]="navegacao" [voltarHabilitado]="voltarHabilitado"
                      [proximoHabilitado]="proximoHabilitado" (voltarEvent)="voltar($event)"
                      (voltarSilenciosoEvent)="voltarSileciosoAcao($event)" (proximoEvent)="continuar($event)"
                      [tabDirective]="tabDirective" (baixarPDFEvent)="baixarPDF($event)"
                      (incluirMarcadorCnisEvent)="incluirMarcadorCnis()"
                      (excluirCnisEvent)="excluirCnis()">
                    </app-barra-navegacao>
  
                    <tabset #tabsCalculoBeneficios>
  
                      <tab (selectTab)="tabSelecionada($event)" id="tabImportarCnis">
                        <ng-template tabHeading>
                          <div class="col-1 p-4 mb-5">
                            <span class=" timeline-step timeline-step-xlg bg-black ">
                              1
                            </span>
                          </div>
                        </ng-template>
                        <div class="mt-4 mb-4">
  
                          <div class="pb-4">
                            <app-importador-cnis #importadorCNIS (leituraCNISEvent)="leituraCNISEvent($event)"
                              (processandoCNISEvent)="processandoCNISEvent($event)"></app-importador-cnis>
                          </div>
                          <div class="pb-4">
                            <app-gerador-cnis (gerarLeituraCnisFinalizadaEvent)="leituraCNISEvent($event)"></app-gerador-cnis>
                          </div>
  
                          <div class="pb-4">
                            <app-cnis-listagem #seletorCnis (leituraCnisSelecionadoEvent)="leituraCNISEvent($event)"></app-cnis-listagem>
                            <!-- <app-seletor-cnis #seletorCnis (leituraCnisSelecionadoEvent)="leituraCNISEvent($event)"></app-seletor-cnis> -->
                          </div>
  
                          
  
                        </div>
  
                      </tab>
  
                      <tab (selectTab)="tabSelecionada($event)" id="tabEditorCnis">
                        <ng-template tabHeading>
                          <div class="col-1 p-4 mb-5 ">
                            <span class=" timeline-step timeline-step-xlg bg-default">
                              2
                            </span>
                          </div>
                        </ng-template>
                        <div class="mt-4 mb-4">
                          <!-- <div class="row">
    
                              <div class="col">
                                <h1 class="display-4">CNIS</h1>
                              </div>
                            </div> -->
  
                          <div>
                            <app-editor-cnis #editorCNIS *ngIf="calculoAnaliseCnis.calculoBeneficios.leituraCnis"
                              [calculoBeneficios]="calculoAnaliseCnis.calculoBeneficios" [aguardeVisivel]="aguardeVisivel"
                              (edicaoCNISFinalizadaEvent)="edicaoCNISFinalizada($event)"
                              (listaSeletorCnisAtualizadaEvent)="atualizarListaCnis($event)"
                              (excluirCnisFinalizadoEvent)="excluirCnisFinalizado($event)"></app-editor-cnis>
                          </div>
                        </div>
  
                      </tab>
  
                      <tab (selectTab)="tabSelecionada($event)" (deselect)="abandonarDetalhe($event)" id="tabResultado">
                        <ng-template tabHeading>
                          <div class="col-1 p-4 mb-5">
                            <span class=" timeline-step timeline-step-xlg bg-default">
                              <i class="bi-check2"></i>
                            </span>
                          </div>
                        </ng-template>
                        <div class="mt-4 mb-4">
                          <!-- <div class="row">
                              <div class="col-md-auto">
                                <h1 class="display-4">Resultado</h1>
                              </div>
    
                            </div> -->
  
                          <div [className]="relatorioVisivel ? 'visible' : 'invisible'">
                            <!-- <app-evolucao-salarios-pos-real *ngIf="grupos" [grupos]="grupos"></app-evolucao-salarios-pos-real> -->
                          </div>
  
                          <div>
                            <app-resultado-analise-cnis *ngIf="calculoAnaliseCnis" [calculoAnaliseCnis]="calculoAnaliseCnis"></app-resultado-analise-cnis>
  
                          </div>
                        </div>
  
                      </tab>
  
                    </tabset>
  
                  </div>
  
                </div>
              </div>
  
            </div>
  
          </div>
  
        </div>
      </div>
    </div>
  
  </div>