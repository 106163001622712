import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AutenticacaoService } from './autenticacao.service';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ListarUsuarioService } from '../usuario/listar-usuario.service';
import { Global } from 'src/app/modelos/global';
import { ConfigService } from '../ui/config.service';
import { Observable, of } from 'rxjs';
import { LogService } from '../log.service';

@Injectable({
  providedIn: 'root'
})
export class AutorizacaoService  {

  autorizado: boolean;

  constructor(private autenticacaoService: AutenticacaoService, private router: Router,
    private listarUsuarioService: ListarUsuarioService,
    private configService: ConfigService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    
    if (this.autenticacaoService.oauthService.hasValidAccessToken() && this.autenticacaoService.oauthService.hasValidAccessToken() && this.autenticacaoService.oauthService.hasValidIdToken()) {
      let claims = this.autenticacaoService.oauthService.getIdentityClaims();
      
      let usuarioRequest = new Usuario();
      usuarioRequest.rowKey = claims['oid'];
      usuarioRequest.email = claims['emails'][0];
      // LogService.log('claims usuario', claims);
      


      return this.listarUsuarioService.listarUsuario(usuarioRequest)
      .then(usuario=>{
        let usuarioResponse:Usuario = usuario as Usuario;
        if(usuarioResponse.emailConfirmado){
          // console.log("setusuario autorizacao");
          this.configService.setUsuario(usuario as Usuario);
          
          return true;
        }
        else{
          this.router.navigateByUrl('confirmaremail?id=' + usuarioResponse.rowKey);
          return false;
        }
      });
      
    }else{
      this.router.navigateByUrl('/login');
      return false;
    }
    
    // if (this.autenticacaoService.oauthService.hasValidAccessToken()) {
    //   let claims = this.autenticacaoService.oauthService.getIdentityClaims();

    //   let usuarioRequest = new Usuario();
    //   usuarioRequest.rowKey = claims['oid'];
    //   usuarioRequest.email = claims['emails'][0];

    

    //   this.listarUsuarioService.listarUsuario(usuarioRequest)
    //     .then(async usuario => {
    //       //controle de usuario e assinatura
    //       this.configService.setUsuario(usuario as Usuario);

    //       if (Global.usuario.emailConfirmado) {
    //         this.autorizado = true;
    //       }
    //       else {
    //         this.autorizado = false;
    //         // this.router.navigateByUrl('confirmaremail?id=' + Global.usuario.rowKey);
    //       }

    //     })
    //     .then(() => {
    //       if (this.autenticacaoService.oauthService.hasValidAccessToken() && this.autorizado) {
    
    //         return true;
    //       } else {
    //         // this.router.navigateByUrl('/login');
    
    //         this.autenticacaoService.oauthService.initCodeFlow()
    //         return false;
    //       }
    //     });
    // }
    // else {
    //   // this.router.navigateByUrl('/login');
    
    //   this.autenticacaoService.oauthService.initCodeFlow()
    //   return false;
    // }




  }
}