import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';
import { RelatorioRMI } from 'src/app/modelos/previdenciario/relatoriormi';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-variacao-rmi-simulacao-kertzman-chart',
  templateUrl: './variacao-rmi-simulacao-kertzman-chart.component.html',
  styleUrls: ['./variacao-rmi-simulacao-kertzman-chart.component.scss']
})
export class VariacaoRmiSimulacaoKertzmanChartComponent implements OnInit, OnChanges {

  @Input() relatorio: RelatorioKertzman;
  @Input() visualizarPdf: boolean = true;

  @Input() calculoPlanejamentoKertzman: CalculoPlanejamentoKertzman;

  @Output() recalcularOtimizacaoEspecificaEvent = new EventEmitter<number>();

  data: any[];
  aguardePdf:boolean;

  chart: Chart;

  constructor(private currency: CurrencyPipe, private datePipe: DatePipe,
    private listarPDFService: ListarPDFService,
    private decimalPipe:DecimalPipe,
    private notificacoesService: NotificacoesService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.carregarDados();
    }
  }


  ngOnInit(): void {

    this.carregarDados();

  }

  carregarDados() {
    if (this.chart) { this.chart.destroy(); this.chart = null; }

    // console.log('variacao rmi');
    // console.log(this.simulacoes.flatMap(caso => caso.relatorioRMI.rendaMensalInicial));


    // console.log('total simulacoes');
    // console.log(this.caso.simulacoes.length);
    // LogService.log('map', this.relatorio.variacaoRmi);
    this.data = Array.from( Object.entries(this.relatorio.variacaoRmi).map(([key, value]) => [Number(key), value as number]).values());

    let datasetLabels = Array.from( Object.entries(this.relatorio.variacaoRmi).map(([key, value]) => [Number(key), value as number]).keys());

    let colors = datasetLabels.map(variacao=>variacao === this.relatorio.otimizacao.totalSubstituicoesTeto ? "#00BFFF" : "#333333" )
    let dataset = {
      labels: datasetLabels, datasets: [
        {
          data: this.data,
          label: 'Valor da RMI',
          backgroundColor: colors,//'#172b4d',
          
        }
      ]
    };



    let chart = document.getElementById('variacaoRmiChart') as HTMLCanvasElement;


    Chart.register(annotationPlugin);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      options: {
        onClick: (e, activeEls) => {
          // console.log('opa rmi', dataset.datasets[activeEls[0].datasetIndex].data[activeEls[0].index]);
          // console.log('opa rmi caso', this.data[activeEls[0].index]);
          // console.log(this.caso.simulacoes[activeEls[0].index].totalSubstituicoesTeto)

          LogService.log('click variacao rmi', this.relatorio.variacaoRmi[activeEls[0].index]);
          LogService.log('click variacao otimizacao', this.relatorio.variacaoSubstituicoesTeto[activeEls[0].index]);
          this.recalcularOtimizacaoEspecificaEvent.emit(this.relatorio.variacaoSubstituicoesTeto[activeEls[0].index]);
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          annotation: {
            annotations: {
            }
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Valor do benefício',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              // size: 24,
              // weight: 'normal',
            },
            titleFont: {
              // size: 24,
              // weight: 'normal',

            },
            callbacks: {
              label: (context) => {

                // LogService.log('tooltip variacao rmi', context);
                let info:string[] = new Array<string>();
                info.push(context.parsed.x + ' contribuições de otimização');

                info.push("Taxa de retorno: " + this.decimalPipe.transform(this.relatorio.variacaoTaxaPayback[context.dataIndex] *100, '1.2-2') + "%");
                info.push("Payback : " + this.decimalPipe.transform(this.relatorio.variacaoPayback[context.dataIndex], '1.0-0') + " meses");

                info.push("Estimativa de Lucro (ROI) : " + this.currency.transform(this.relatorio.variacaoLucroPrevidencia[context.dataIndex]));

                return info;  //this.caso.simulacoes[tooltipItens[0].dataIndex].totalSubstituicoesTeto + " Contribuições de Otimização";

               
              },
              title: (tooltipItens) => {

                 // let label = context.dataset.label || '';

                // if (label) {
                //   label += ': ';
                // }
                // if (context.parsed.y !== null) {
                //   label += this.currency.transform(context.parsed.y);
                // }

                let info:string[] = new Array<string>();
                info.push("Valor do benefício: " + this.currency.transform(tooltipItens[0].parsed.y))
                return info;
                
              },

            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,

            title:{
              display:true,
              text: "Valor da RMI em R$"
            },

            ticks: {
              callback: (value, index, ticks) => {
                return this.currency.transform(value);
              }
            }

          },
          x: {
            type: 'category',
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Total de contribuições de otimização'
            },




          }
        }
      }

    });
  }


  gerarPDF() {
    this.aguardePdf = true;
    this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.VARIACAORMI;
    this.calculoPlanejamentoKertzman.usuario = Global.usuario;

    this.listarPDFService.ListarPDF(this.calculoPlanejamentoKertzman)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'VARIACAO-RMI-PLANEJAMENTO-CONTRIBUTIVO-KERTZMAN-' + this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        this.aguardePdf = false;


      });
  }

}
