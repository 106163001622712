import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-destaque-melhor-cenario',
  templateUrl: './destaque-melhor-cenario.component.html',
  styleUrl: './destaque-melhor-cenario.component.scss'
})
export class DestaqueMelhorCenarioComponent implements OnInit{

  @Input() calculoMatrizPlanejamento: MatrizPlanejamento;
  @Output() dastaqueCanvasEvent = new EventEmitter<CasoPlanejamentoPrevidenciario>();

  cenariosRapidos:CasoPlanejamentoPrevidenciario[];
  cenariosMaiorLucro:CasoPlanejamentoPrevidenciario[];
  cerniosMelhorTaxa:CasoPlanejamentoPrevidenciario[];
  caso:CasoPlanejamentoPrevidenciario;

  constructor(){}
  ngOnInit(): void {


    this.caso = this.calculoMatrizPlanejamento.casoReferencia;
    
    
  }

  showCasoCanvas(caso:CasoPlanejamentoPrevidenciario){

    LogService.log('cenario destaque abrir', caso);
    this.dastaqueCanvasEvent.emit(caso);
    

  }
  

}
