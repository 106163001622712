import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CapitalizacaoMensal } from 'src/app/modelos/previdenciario/capitalizacaomensal';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';

@Component({
  selector: 'app-duracao-saque-capitalizacao-chart',
  templateUrl: './duracao-saque-capitalizacao-chart.component.html',
  styleUrls: ['./duracao-saque-capitalizacao-chart.component.scss']
})
export class DuracaoSaqueCapitalizacaoChartComponent implements OnChanges {


  @Input() linhasCapitalizacaoMensalSaque: CapitalizacaoMensal[];

  constructor(private currency: CurrencyPipe, private datePipe: DatePipe) { }


  datasetInvestimentoAcumuladoMes: any;
  datasetRendimentoMes: any;
  datasetSaqueMes: any;
  data: any;
  chart:any;


  ngOnChanges(changes: SimpleChanges): void {

    if (changes.linhasCapitalizacaoMensalSaque != undefined && changes.linhasCapitalizacaoMensalSaque.currentValue != undefined) {
      this.linhasCapitalizacaoMensalSaque = changes.linhasCapitalizacaoMensalSaque.currentValue;
      if(this.chart != undefined){
        this.chart.destroy();
        this.popularChart();
      }
      

    }
  }


  ngOnInit(): void {
    this.popularChart();
  }


  popularChart(){

    
    this.datasetInvestimentoAcumuladoMes = { label: 'Investimento Acumuluado + rendimento no mês - saque', backgroundColor: '#000000', data: this.linhasCapitalizacaoMensalSaque.map(linha => (linha.investimentoAcumulado + linha.rendimentoMes)) }
    // this.datasetRendimentoMes = {label:'Rendimento Capitalização Mensal', data: this.linhasCapitalizacaoMensalSaque.map(linha=>linha.rendimentoMes)};
    this.data = { labels: this.linhasCapitalizacaoMensalSaque.map(linha => linha.mes), datasets: [this.datasetInvestimentoAcumuladoMes] };

    var canvas = document.getElementById('chartDuracaoCapitalizacao') as HTMLCanvasElement;
    Chart.register(annotationPlugin);
    this.chart = new Chart(canvas, {
      type: 'bar',
      data: this.data,
      options: {
        onClick: (e, activeEls) => {
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {
          legend: {
            display: true,
          },
          title: {
            display: false,
            text: '',
            font: {
              size: 16
            }
          },
          tooltip: {
            displayColors: false,
            bodyFont:{
              // size:24,
              // weight:'normal',
            },
            titleFont:{
              // size:24,
              // weight:'normal'

            },
            footerFont:{
              // size:12,
              // weight:'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.currency.transform(context.parsed.y);
                }
                return label;
              },
              title: (tooltipItens) => {
                return tooltipItens[0].label;
              },
              footer: (tooltipItens) => {
                return ''
              }
            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value, index, ticks) => {
                return this.currency.transform(value);
              }
            }

          },
          x: {
            stacked: true,
            ticks: {
              display: false,

            },
            title: {
              display: true,
              text: 'Meses'
            },




          }
        }
      }

    });
  }
}
