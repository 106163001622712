import { Component, Input } from '@angular/core';
import { Periodicidade } from 'src/app/modelos/previdenciario/periodicidade';

@Component({
  selector: 'app-intervalo-contribuicoes-matriz',
  templateUrl: './intervalo-contribuicoes-matriz.component.html',
  styleUrl: './intervalo-contribuicoes-matriz.component.scss'
})
export class IntervaloContribuicoesMatrizComponent {
  @Input() intervalos:Periodicidade[];
  periodicidadeType = Periodicidade;


  addOpcao(periodicidade:Periodicidade){
    this.intervalos.push(periodicidade);
  }

  excluir(periodicidade:Periodicidade){
    this.intervalos.splice(this.intervalos.indexOf(periodicidade), 1);
  }

}
