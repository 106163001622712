<div *ngIf="sequencia">
    <div class="py-4">
        <div class="row d-flex py-4">
            <div class="col py-2">
                <p class="display-2 lh-1 fw-lighter">
                    {{sequencia.sequencia}} {{sequencia.origemVinculo}}
                </p>
            </div>
            
        </div>
        <div class="row d-flex">
            <div class="col-3 py-2">
                <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                    Sequência
                </p>
                <p class=" lh-1">
                    {{sequencia.sequencia}}
                </p>
            </div>
    
            <div class="col-3 py-2">
                <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                    Origem do vínculo
                </p>
                <p class=" lh-1">
                    {{sequencia.origemVinculo}}
                </p>
            </div>
    
            <div class="col-3 py-2">
                <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                    Data Início
                </p>
                <p class=" lh-1">
                    {{sequencia.dataInicio | date : 'dd/MM/yyyy'}}
                </p>
            </div>
    
            <div class="col-3 py-2">
                <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                    Data Fim
                </p>
                <p class=" lh-1">
                    {{sequencia.dataFim | date : 'dd/MM/yyyy'}}
                </p>
            </div>

            <div class="col-3 py-2">
                <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                    Tipo do Vínculo
                </p>
                <!-- <span class=" lh-1">
                    {{sequencia.tipoVinculo | tipoVinculo}}
                </span> -->

                <div class="dropdown" style="position: static;">
                    <button class="btn btn-outline-default btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{sequencia.tipoVinculo | tipoVinculo}}
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.EMPREGADOOUAGENTEPUBLICO)">{{tiposVinculosType.EMPREGADOOUAGENTEPUBLICO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.CONTRIBUINTEINDIVIDUAL)">{{tiposVinculosType.CONTRIBUINTEINDIVIDUAL| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.BENEFICIO)">{{tiposVinculosType.BENEFICIO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.FACULTATIVO)">{{tiposVinculosType.FACULTATIVO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.EMPREGADODOMESTICO)">{{tiposVinculosType.EMPREGADODOMESTICO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.TRABALHADORAVULSO)">{{tiposVinculosType.TRABALHADORAVULSO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.SEGURADOESPECIAL)">{{tiposVinculosType.SEGURADOESPECIAL| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.SEGURADOFACULTATIVO)">{{tiposVinculosType.SEGURADOFACULTATIVO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.CONTRIBUINTEEMDOBRO)">{{tiposVinculosType.CONTRIBUINTEEMDOBRO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.AUTONOMO)">{{tiposVinculosType.AUTONOMO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.PENSAOMORTE)">{{tiposVinculosType.PENSAOMORTE| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.MEI)">{{tiposVinculosType.MEI| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.PRESTADORSERVICO)">{{tiposVinculosType.PRESTADORSERVICO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.AUXILIOINCAPACIDADETEMPORARIA)">{{tiposVinculosType.AUXILIOINCAPACIDADETEMPORARIA| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.AUXILIOINCAPACIDADEPERMANENTE)">{{tiposVinculosType.AUXILIOINCAPACIDADEPERMANENTE| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.SALARIOMATERNIDADE)">{{tiposVinculosType.SALARIOMATERNIDADE| tipoVinculo}}</button></li>

                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.CONTRIBUINTEINDIVIDUALPFPLANOSIMPLIFICADO)">{{tiposVinculosType.CONTRIBUINTEINDIVIDUALPFPLANOSIMPLIFICADO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.CONTRIBUINTEINDIVIDUALPJEMPRESARIO)">{{tiposVinculosType.CONTRIBUINTEINDIVIDUALPJEMPRESARIO| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.CONTRIBUINTEINDIVIDUALPF)">{{tiposVinculosType.CONTRIBUINTEINDIVIDUALPF| tipoVinculo}}</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoVinculo(sequencia, tiposVinculosType.CONTRIBUINTEINDIVIDUALPFCOOPERADO)">{{tiposVinculosType.CONTRIBUINTEINDIVIDUALPFCOOPERADO| tipoVinculo}}</button></li>

                    </ul>
                </div>
            </div>

            <div class="col-3 py-2">
                <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                    Tipo de Atividade
                </p>
                <!-- <span class=" lh-1">
                    {{sequencia.tipoAtividade | tipoAtividade}}
                </span> -->
                <div class="dropdown" style="position: static;">
                    <button class="btn btn-outline-default btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{sequencia.tipoAtividade | tipoAtividade}}
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Normal)">Normal</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Professor)">Professor</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Rural)">Rural</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Especial15)">Especial 15</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Especial20)">Especial 20</button></li>
                        <li><button class="dropdown-item" (click)="editarTipoAtividade(sequencia, tiposAtividadeType.Especial25)">Especial 25</button></li>
                    </ul>
                </div>
            </div>

            

            <div class="row py-2">

                <div class="col-3 py-2">
                    <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                        Tempo de Contribuição
                    </p>
                    <p class="lh-1">
                        {{sequencia.tempoContribuicaoBruto.porExtenso}}
                    </p>
                </div>


                <div class="col-3 py-2">
                    <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                        Tempo de Contribuição Líquido para Aposentadorias por Idade
                    </p>
                    <p class=" lh-1">
                        {{sequencia.tempoContribuicaoIdade.porExtenso}}
                    </p>
                </div>
    
    
                <div class="col-3 py-2">
                    <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                        Tempo de Contribuição Líquido para Aposentadorias por Tempo
                    </p>
                    <p class=" lh-1">
                        {{sequencia.tempoContribuicaoTempoContribuicao.porExtenso}}
                    </p>
                </div>
            </div>

            <div class="row py-2">
                <div class="col-3 py-2">
                    <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                        Carência para aposentadorias por Idade
                    </p>
                    <p class=" lh-1">
                        {{sequencia.totalCarenciaIdade}}
                    </p>
                </div>
    
                <div class="col-3 py-2">
                    <p class=" fw-light lh-1 mb-0 text-uppercase h6">
                        Carência para aposentadorias por Tempo de Contribuição
                    </p>
                    <p class=" lh-1">
                        {{sequencia.totalCarenciaTempoContribuicao}}
                    </p>
                </div>
            </div>
        </div>
    
        <!-- <div class="row d-flex">
    
            <div class="col-3 py-2">
                <p class="fw-bold lh-1">Observações</p>
                <ng-container *ngIf="sequencia && sequencia.observacoes">
                    <ng-container *ngIf="sequencia.observacoes.length > 0">
                        <ng-container *ngFor="let observacao of sequencia.observacoes">
                            <p class="lh-1">{{observacao.analise}}</p>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="sequencia.observacoes.length == 0">
                        <p class="lh-1">Nenhuma observação para esta sequência</p>
                    </ng-container>
                </ng-container>
                
                
            </div>
    
        </div> -->
    
    </div>
</div>