

<div class="row ">
    <div class="col-12 ">

        <div class="d-flex justify-content-center">
            <div class="row py-4">
                <div class="col-12">
                    <span class=" display-3 mb-0 text-default ">
                        Obrigado por testar o calculei
                    </span>
                </div>

            </div>

        </div>

    </div>
</div>

<div class="row ">
    <div class="col-12 ">

        <div class="row py-4 justify-content-center">
            <div class="col-6">
                <span class="lh-1 mb-0 text-default ">

                </span>
            </div>
        </div>

    </div>
</div>

<div class="row ">
    <div class="col-12 ">
        <div class="d-flex justify-content-center">
            <div class="row py-4">
                <div class="col-12">
                    <button class="btn btn-default" (click)="queroAssinar()">Escolher plano de assinatura</button>
                    <button class="btn btn-outline-black" (click)="queroDeixarFeedback()">Deixar uma sugestão</button>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="row ">
    <div class="col-12 ">
        <div class="d-flex justify-content-center">
            <div class="row py-4 justify-content-center">
                <div class="col-6 text-center fs-1 text-default" role="button" (click)="agendarApresentacao()">
                    <div class="vstack">
                        <i class=" mx-auto bi bi-microsoft-teams"></i>

                        <p class="mx-auto">
                            Agendar apresentação
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
