<div *ngIf="calculoMatrizPlanejamento &&  calculoMatrizPlanejamento.casos">
  <div class="row">
    <div class="col-12">

      <div class="">
        <div class="row ">
          <div class="col">
            <h5 class=" h3 mb-0 linha-quadro">
              Tabela de Planos
              <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpCenarios" aria-expanded="false" aria-controls="helpCenarios"></i>
            </h5>
  
            <div class="collapse " id="helpCenarios">
              <div class="row d-flex ">
                <div class="col">
                  <p>
                    Tabela de detalhamento de planos
                  </p>
                </div>
  
              </div>
  
              <div class="row d-flex ">
  
                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">
  
                      <p class=" p-2 lh-1 text-justify">
                        Utilize os botões para modificar a visualização de planos. Clique nos valores, para fazer anotações.
                      </p>
  
                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <!-- <i class="comando bi bi-plus-square-dotted fs-3"></i> -->
                    </div>
  
                  </div>
  
                </div>
  
                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">
  
                      <p class=" p-2 lh-1 text-justify">
  
                        Filtrar os planos por regra, alíquota ou salário de contribuição
  
                      </p>
  
                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-funnel fs-3"></i>
                    </div>
  
                  </div>
  
                </div>
  
                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">
  
                      <p class=" p-2 lh-1 text-justify">
  
                        Mostrar ou esconder as tags
  
                      </p>
  
                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-bookmarks fs-3"></i>
  
                    </div>
  
                  </div>
  
                </div>
  
                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">
  
                      <p class=" p-2 lh-1 text-justify">
  
                        Filtrar campos. Visualize apenas as informações mais relevantes de cada plano na tabela
                      </p>
  
                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-filter-square fs-3"></i>
                    </div>
  
                  </div>
  
                </div>
  
                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">
  
                      <p class=" p-2 lh-1 text-justify">
  
                        Ordenar os planos por ordem alfabética
  
                      </p>
  
                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-sort-alpha-up fs-3"></i>
  
                    </div>
  
                  </div>
  
                </div>
  
                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">
  
                      <p class=" p-2 lh-1 text-justify">
  
                        Ordenar os planos por valor do benefício
  
                      </p>
  
                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-sort-numeric-up fs-3"></i>
                    </div>
  
                  </div>
  
                </div>
  
              </div>
  
            </div>
          </div>
  
        </div>
  
        <div class="row py-2  flex d-flex justify-content-between">
  
          <div class="col-auto flex d-flex">
            <div class="col-auto p-0 m-0">
              <a class="text-default" data-bs-toggle="collapse" data-bs-target="#areaFiltros" aria-expanded="false" aria-controls="areaFiltros" role="button" container="body" ngbTooltip="Mostrar filtros de planos">
                <i class="comando bi bi-funnel fs-6"></i>
                <i class="comando bi bi-funnel-fill fs-6 "></i>
  
              </a>
            </div>
  
            <div class="col-auto p-0 m-0">
  
              <a class="text-default " aria-expanded="false" data-bs-toggle="collapse" role="button" href="#marcadoresExibir" container="body" ngbTooltip="Mostrar marcadores">
                <a class id="marcadoresExibir"></a>
                <i class="comando bi bi-bookmarks fs-6 " (click)="marcadoresExibir(true)"></i>
                <i class="comando bi bi-bookmarks-fill fs-6 " (click)="marcadoresExibir(false)"></i>
  
              </a>
            </div>
  
            <div class="col-auto p-0 m-0">
              <a class="text-default " aria-expanded="false" data-bs-toggle="collapse" role="button" href="#areaCampos" container="body" ngbTooltip="Mostrar filtro de campos da tabela">
                <i class="comando bi bi-filter-square fs-6 " (click)="marcadoresExibir(true)"></i>
                <i class="comando bi bi-filter-square-fill fs-6 " (click)="marcadoresExibir(false)"></i>
  
              </a>
  
            </div>
  
            <div class="col-auto p-0 m-0">
              <a class="text-default" aria-expanded="false" data-bs-toggle="collapse" role="button" href="#alphaFiltro" container="body" ngbTooltip="Ordem alfabética por nome dos planos">
                <a class id="alphaFiltro"></a>
                <i class="comando bi bi-sort-alpha-down fs-6 " (click)="ordenarAlpha(true)"></i>
                <i class="comando bi bi-sort-alpha-up fs-6 " (click)="ordenarAlpha(false)"></i>
  
              </a>
  
            </div>
  
            <div class="col-auto p-0 m-0">
              <a class="text-default" aria-expanded="false" data-bs-toggle="collapse" role="button" href="#valorBeneficioFiltro" container="body" ngbTooltip="Ordenar por valor de benefício">
                <a class id="valorBeneficioFiltro"></a>
                <i class="comando bi bi-sort-numeric-down fs-6 " (click)="ordernarValor(true)"></i>
                <i class="comando bi bi-sort-numeric-up fs-6 " (click)="ordernarValor(false)"></i>
  
              </a>
            </div>
  
          </div>
  
          <!-- <div class="col-auto flex d-flex">
          <div class="col-auto p-0 m-0">
            <a class="text-default " role="button" (click)="pdfMatriz()" container="body" ngbTooltip="Exportar tabela em PDF">
              <i class="comando bi bi-filetype-pdf fs-6 "></i>
            </a>
          </div>
    
          <div class="col-auto p-0 m-0">
            <a class="text-default " role="button" container="body" ngbTooltip="Exportar tabela em excel">
              <i class="comando bi bi-filetype-xls fs-6 "></i>
            </a>
          </div>
        </div> -->
  
        </div>
        <!-- campos -->
        <div class="collapse" id="areaCampos">
          <div class="row ">
            <div class="col-12">
              <p class="h3">
                Mostrar Campos
              </p>
            </div>
          </div>
  
          <div class="row m-0 p-0  flex d-flex">
            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="marcarTodosCampos()" container="body" ngbTooltip="Marcar todos">
                <i class="comando bi bi-ui-checks fs-6 "></i>
              </a>
            </div>
  
            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="limparTodosCampos()" container="body" ngbTooltip="Limpar todos">
                <i class="comando bi bi-grid fs-6 "></i>
              </a>
            </div>
          </div>
          <form [formGroup]="camposForm">
            <!-- campos -->
            <div class="row p-1">
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoAliquotaSalario">
                  <label class="form-check-label">
                    Alíquota / Salário
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoDataAposentadoria">
                  <label class="form-check-label">
                    Data da Aposentadoria
                  </label>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoRegra">
                  <label class="form-check-label">
                    Regra
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoValorBeneficio">
                  <label class="form-check-label">
                    Valor do Benefício
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoTotalContribuicoes">
                  <label class="form-check-label">
                    Total de Contribuições
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoValorContribuicao">
                  <label class="form-check-label">
                    Valor da Contribuição
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoTotalInvestimento">
                  <label class="form-check-label">
                    Total do Investimento
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoRoiAcumulado">
                  <label class="form-check-label">
                    Expectativa de Retorno
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoTaxaRetorno">
                  <label class="form-check-label">
                    Taxa de Retorno
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoGanhoRoi">
                  <label class="form-check-label">
                    Ganho no ROI
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoPercentualTeto">
                  <label class="form-check-label">
                    Percentual do Teto do INSS
                  </label>
                </div>
              </div>
  
            </div>
          </form>
        </div>
  
        <!-- filtros -->
        <div class="collapse" id="areaFiltros">
          <div class="row ">
            <div class="col-12">
              <p class="h3">
                Filtros
              </p>
            </div>
          </div>
  
          <div class="row m-0 p-0  flex d-flex">
            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="marcarTodosFiltros()" container="body" ngbTooltip="Marcar todos">
                <i class="comando bi bi-ui-checks fs-6 "></i>
              </a>
            </div>
  
            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="limparTodosFiltros()" container="body" ngbTooltip="Limpar todos">
                <i class="comando bi bi-grid fs-6 "></i>
              </a>
            </div>
          </div>
  
          <form [formGroup]="filtrosForm">
            <!-- regras -->
            <div class="row">
              <div class="col-1">
                <p class="h3">
                  Regras:
                </p>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraIdadeTransicao">
                  <label class="form-check-label">
                    Idade
                  </label>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraPedagio100">
                  <label class="form-check-label">
                    Pedágio 100%
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraPedagio50">
                  <label class="form-check-label">
                    Pedágio 50%
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraIdadeMinimaProgressiva">
                  <label class="form-check-label">
                    TC + Idade Mínima
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraPontos">
                  <label class="form-check-label">
                    Pontos
                  </label>
                </div>
              </div>
            </div>
  
            <!-- aliquota -->
            <div class="row ">
              <div class="col-1">
                <p class="h3">
                  Alíquota:
                </p>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroAliquota11">
                  <label class="form-check-label">
                    11%
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroAliquota20">
                  <label class="form-check-label">
                    20%
                  </label>
                </div>
              </div>
  
            </div>
            <!-- salario -->
            <div class="row">
              <div class="col-1">
                <p class="h3">
                  Salário:
                </p>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroSalarioMinimo">
                  <label class="form-check-label">
                    Salário Mïnimo
                  </label>
                </div>
              </div>
  
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroSalarioTeto">
                  <label class="form-check-label">
                    Teto
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>



  <!-- tabela -->
  <div class="row">
    <div class="col-12">
      <div class="table-wrapper">
        <table class="table table-bordered table-sm  table-responsive table-striped  table-hover align-middle overflow-auto">
          <thead>

            <tr>
              <td class="cabecalho">
                <span>
                  Plano
                </span>
              </td>

              <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                <ng-container *ngIf="!caso.filtrarMatriz">
                  <td class="plano">

                    <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                      {{caso.referenciaMatriz}}
                    </span>

                    <div class="dropdown">
                      <div class="dropdown-menu" [id]="'opcoesCenario'+i">
                        <div class="container">
                          <div class="row pb-4">
                            <div class="col">
                              <span class="h1 fw-lighter text-default" role="button ">
                                {{caso.referenciaMatriz}}
                              </span>
                            </div>
                            <div class="col-auto">
                              <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCenario'+i)"></i>
                            </div>
                          </div>



                          <div class="py-2">
                            <div class="row">
                              <div class="col">
                                <p class="h3">Abrir plano</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <i class="comando bi bi-plus-lg" role="button" (click)="abrirPlano(caso)"></i>

                              </div>
                            </div>
                          </div>

                          <div class="py-2">
                            <div class="row">
                              <div class="col">
                                <p class="h3">Alterar nome</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <h5 class="  text-uppercase mb-0 text-default">
                                  Nome
                                </h5>
                                <div class="row flex d-flex">
                                  <div class="col">
                                    <input class="form-control text-left" type="text" [value]="caso.referenciaMatriz" [id]="'editorTitulo' + i" />
                                  </div>
                                  <div class="col-auto align-self-center">
                                    <i class="comando bi bi-arrow-right" role="button" (click)="editarTituloCenario(caso,'editorTitulo' + i)"></i>
                                  </div>
                                </div>
                                <p class="h6">
                                  Novo nome do plano
                                </p>

                              </div>
                            </div>
                          </div>

                          <div class="py-2">
                            <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'CASO')">
                            </app-add-marcador>
                          </div>

                          <div class="py-2">
                            <div class="row">
                              <div class="col">
                                <p class="h3">Esconder plano</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <i class="comando bi bi-eye-slash" role="button" (click)="esconderCenario(caso, 'opcoesCenario'+i)"></i>
                                <p class="h6">
                                  Remover o plano da matriz
                                </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                      <span *ngFor="let marcador of caso.marcadores">
                        <span class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'CASO')" role="button" class="remover-marcador bi bi-x-lg"></i> </span>
                      </span>
                    </span>

                  </td>

                </ng-container>
              </ng-container>

            </tr>

          </thead>
          <tbody>

            <tr>

              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.aliquotaSalario">

                <th class="cabecalho">
                  Alíquota / Salário
                </th>

                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoAliquota'+ i" [style.backgroundColor]=" caso.aliquotaContributivaMarcadores | tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        <span [ngSwitch]="caso.baseContributiva" class="p-0 m-0">
                          <span *ngSwitchCase="calculoMatrizPlanejamento.tetoInss">
                            <span> {{caso.aliquotaContributiva * 100 | number : '1.0-2'}}% / Teto</span>
                          </span>
                          <span *ngSwitchCase="calculoMatrizPlanejamento.salarioMinimo">
                            <span>{{caso.aliquotaContributiva * 100 | number : '1.0-2'}}% / Sal. Mín</span>
                          </span>
                          <span *ngSwitchDefault>
                            <span>{{caso.aliquotaContributiva * 100 | number : '1.0-2'}}% / {{caso.baseContributiva | currency}} </span>
                          </span>
                        </span>

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoAliquota'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo alíquota / salário
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAliquota'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ALIQUOTA')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ALIQUOTA', 'campoAliquota'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.aliquotaContributivaMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ALIQUOTA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>

              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.dataAposentadoria">
                <th class="cabecalho">
                  Data da Aposentadoria
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoDataAposentadoria'+ i" [style.backgroundColor]=" caso.dataAposentadoriaMarcadores| tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoDataAposentadoria'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Data da Aposentadoria
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoDataAposentadoria'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'DATAAPOSENTADORIA')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'DATAAPOSENTADORIA', 'campoDataAposentadoria'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.dataAposentadoriaMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1 text-white" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'DATAAPOSENTADORIA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>

              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.regra">
                <th class="cabecalho">
                  Regra
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoRegra'+ i" [style.backgroundColor]=" caso.qualificacaoReferenciaMarcadores| tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.qualificacaoReferencia}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoRegra'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Regra
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoRegra'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'QUALIFICACAOREFERENCIA')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'QUALIFICACAOREFERENCIA', 'campoRegra'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.qualificacaoReferenciaMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'QUALIFICACAOREFERENCIA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>

              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.valorBeneficio">
                <th class="cabecalho">
                  Valor do Benefício
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoValorBeneficio'+ i" [style.backgroundColor]=" caso.valorBeneficioMarcadores | tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.valorBeneficio | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoValorBeneficio'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Valor do Benefício
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoValorBeneficio'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'VALORBENEFICIO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'VALORBENEFICIO', 'campoValorBeneficio'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.valorBeneficioMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'VALORBENEFICIO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalContribuicoes">
                <th class="cabecalho">
                  Total de Novas Contribuições
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoTotalContribuicoes'+ i" [style.backgroundColor]=" caso.totalContributivoComplementarMesesMarcadores | tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.totalContributivoComplementarMeses}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoTotalContribuicoes'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Total de Contribuições
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTotalContribuicoes'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES', 'campoTotalContribuicoes'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.totalContributivoComplementarMesesMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                    <!-- <td>

                    <span>
                      
                    </span>
                    <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                      <span *ngFor="let marcador of caso.totalContributivoComplementarMesesMarcadores">
                        <span class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                      </span>
                    </span>

                 
                  </td> -->

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.valorContribuicao">
                <th class="cabecalho">
                  Valor da Contribuição
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoValorContribuicao'+ i" [style.backgroundColor]=" caso.valorContribuicaoComplementarMensalMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.valorContribuicaoComplementarMensal | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoValorContribuicao'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Valor da Contribuição
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoValorContribuicao'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL', 'campoValorContribuicao'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.valorContribuicaoComplementarMensalMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalInvestimento">
                <th class="cabecalho">
                  Total do Investimento
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoTotalInvestimento'+ i" [style.backgroundColor]=" caso.totalInvestimentoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.totalInvestimento | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoTotalInvestimento'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Total do Investimento
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTotalInvestimento'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TOTALINVESTIMENTO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TOTALINVESTIMENTO', 'campoTotalInvestimento'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.totalInvestimentoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALINVESTIMENTO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.roiAcumulado">
                <th class="cabecalho">
                  Expectativa de Retorno
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoRoiAcumulado'+ i" [style.backgroundColor]=" caso.retornoTotalInvestimentoRoiMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.retornoTotalInvestimentoRoi | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoRoiAcumulado'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo ROI Acumulado
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoRoiAcumulado'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'RETORNOTOTALINVESTIMENTOROI')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'RETORNOTOTALINVESTIMENTOROI', 'campoRoiAcumulado'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.retornoTotalInvestimentoRoiMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'RETORNOTOTALINVESTIMENTOROI')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>

            <!-- <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.taxaRetorno">
                <th class="">Taxa de Retorno</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoTaxaRetorno'+ i" [style.backgroundColor]=" caso.taxaRetornoPlanejamentoContributivoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.taxaRetornoPlanejamentoContributivo * 100 | number : '1.2-2'}} %

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoTaxaRetorno'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Taxa de Retorno
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTaxaRetorno'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO', 'campoTaxaRetorno'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.taxaRetornoPlanejamentoContributivoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr> -->

            <!-- <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.payback">
                <th class="">Payback</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoPayback'+ i" [style.backgroundColor]=" caso.tempoRetornoInvestimentoMesesAbonoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.tempoRetornoInvestimentoMesesAbono | number : '1.0-0'}} meses

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoPayback'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Payback
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoPayback'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TEMPORETORNOINVESTIMENTOMESESABONO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TEMPORETORNOINVESTIMENTOMESESABONO', 'campoPayback'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.tempoRetornoInvestimentoMesesAbonoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TEMPORETORNOINVESTIMENTOMESESABONO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr> -->

            <!-- <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.ganhoRoi">
                <th class="">Ganho no ROI</th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoGanhoRoi'+ i" [style.backgroundColor]=" caso.lucroPlanejamentoContributivoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{caso.lucroPlanejamentoContributivo | currency}}

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesGanhoRoi'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo Ganho ROI
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesGanhoRoi'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'LUCROPLANEJAMENTOCONTRIBUTIVO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'LUCROPLANEJAMENTOCONTRIBUTIVO', 'campoGanhoRoi'+ i )">

                              </app-add-tinta>
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.lucroPlanejamentoContributivoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'LUCROPLANEJAMENTOCONTRIBUTIVO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr> -->

            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.percentualTeto">
                <th class="cabecalho">
                  % Teto do INSS
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoPercentualTeto'+ i" [style.backgroundColor]=" caso.percentualTetoInssMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                        {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}%

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoPercentualTeto'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  {{caso.referenciaMatriz}} opções campo % Teto do INSS
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoPercentualTeto'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'PERCENTUALTETOINSS')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'PERCENTUALTETOINSS', 'campoPercentualTeto'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.percentualTetoInssMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'PERCENTUALTETOINSS')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>


            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.acumuladoCapitalizacao">
                <th class="cabecalho">
                  Resultado Produto Financeiro
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoAcumuladoCapitalizacao'+ i" [style.backgroundColor]=" caso.acumuladoCapitalizacaoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">

                        {{caso.taxaRetornoPrivadoAno * 100 | number :'1.2-2'}}% / {{caso.totalContributivoCapitalizadoGanhoReal | currency }}
                        <!-- {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}% -->

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoAcumuladoCapitalizacao'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  
                                  {{caso.referenciaMatriz}} opções campo Resultado Produto Financeiro
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAcumuladoCapitalizacao'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ACUMULADOCAPITALIZACAO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ACUMULADOCAPITALIZACAO', 'campoAcumuladoCapitalizacao'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.acumuladoCapitalizacaoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ACUMULADOCAPITALIZACAO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>


            <tr>
              <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.acumuladoCapitalizacao">
                <th class="cabecalho">
                  Benefício Mensal no Produto Financeiro
                </th>
                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">

                    <td [id]="'campoAcumuladoCapitalizacao'+ i" [style.backgroundColor]=" caso.acumuladoCapitalizacaoMarcadores |  tintaFundo ">

                      <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">

                        {{caso.valorSaqueCapitalizacao | currency }}
                        <!-- {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}% -->

                      </span>

                      <div class="dropdown">
                        <div class="dropdown-menu" [id]="'opcoesCampoAcumuladoCapitalizacao'+i">
                          <div class="container">
                            <div class="row py-1">
                              <div class="col">
                                <span role="button">
                                  
                                  {{caso.referenciaMatriz}} opções campo Resultado Acumulado no Produto Financeiro
                                </span>
                              </div>
                              <div class="col-auto">
                                <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAcumuladoCapitalizacao'+i)"></i>
                              </div>
                            </div>

                            <div class="py-1">
                              <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ACUMULADOCAPITALIZACAO')">
                              </app-add-marcador>
                            </div>

                            <div class="py-1">
                              <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ACUMULADOCAPITALIZACAO', 'campoAcumuladoCapitalizacao'+ i )">

                              </app-add-tinta> -->
                            </div>

                          </div>
                        </div>

                      </div>

                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.acumuladoCapitalizacaoMarcadores">
                          <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ACUMULADOCAPITALIZACAO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>

                    </td>

                  </ng-container>
                </ng-container>

              </ng-container>

            </tr>


            

          </tbody>
        </table>

      </div>

    </div>
  </div>
</div>