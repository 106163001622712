<app-cabecalho [titulo]="'Início'"></app-cabecalho>
<div class=" container-fluid mt--3">

  <div class=" row">
    <div class=" col-12">
      <div class="  ">

        <div class="pb-4">
          <div class="card">
            <div class="row">
              <div class="col">
                <div class="card-body vh-100">

                  <div class="pb-4">
                    <div class="row">
                      <div class="col-12">
                        <p class="display-1 lh-1 fw-lighter">Olá,</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <p class="fw-light">
                          Comece seu planejamento de aposentadoria com a <mark role="button" [routerLink]="['/calculei/calculos/matriz']">Matriz de Planejamento</mark> para encontrar o melhor plano, ou escolha
                          a opção <mark role="button" [routerLink]="['/calculei/calculos/novocalculo']">Cálculos</mark> para escolher um modelo de cálculo previdenciário especializado.
                        </p>
                        <!-- <p class="lh-1 fw-light">bem vindo(a) ao Calculei, sua plataforma de Investimento Previdenciário</p> -->
                      </div>

                    </div>
                  </div>

                  <div class="pb-4">
                    <!-- <p class="h4 lh-1 fw-light">Comece com uma Análise de Cenários quando quiser calcular a regra mais vantajosa, ou faça uma Análise de CNIS </p> -->

                  </div>
                  <!-- <div class="row">
                    <div class="col-12">
                      <p class="h4">Comece criando um planejamento contributivo com o método kertzman, ou uma matriz de Planejamento.</p>
                    </div>
                  </div> -->

                  <!-- <div class="row py-4">

                    <div class="col-auto" [routerLink]="['/calculei/entrevistas/entrevista']" role="button">
                      <i class="comando bi bi-mic fs-2"></i>
                    </div>
                    <div class="col-auto" [routerLink]="['/calculei/entrevistas/entrevista']" role="button">
                      <p class="h1">
                        Entrevista de Planejamento
                      </p>
                      <p class="p-0 m-0">
                        Crie uma ficha de perguntas para o seu cliente e envie o link
                      </p>
                      <p class="h6 p-0 m-0">
                        Ideal para coletar informações para o planejamento.
                      </p>

                    </div>

                  </div> -->

                  <!-- <div class="row py-4">

                    <div class="col-auto" [routerLink]="['/calculei/calculos/previdenciario/simulacao']" role="button">
                      <i class="comando bi bi-palette2 fs-2"></i>
                    </div>
                    <div class="col-auto" [routerLink]="['/calculei/calculos/previdenciario/simulacao']" role="button">
                      <p class="h1">
                        Simulação
                      </p>
                      <p class="p-0 m-0">
                        Data e o valor da aposentadoria
                      </p>
                      <p class="h6 p-0 m-0">
                        Projeção de tempo e contribuições para cumprimento de requisitos das regras de transição da EC 103 2019
                      </p>

                    </div>

                  </div> -->

                  <div class="row py-4">
                    <div class="col-auto" role="button" [routerLink]="['/calculei/calculos/matriz']">
                      <i class="comando bi bi-grid-3x3-gap-fill fs-2"></i>

                    </div>
                    <div class="col-auto" role="button" [routerLink]="['/calculei/calculos/matriz']">

                      <p class="h1">
                        Matriz de Planejamento
                      </p>
                      <p class="p-0 m-0">
                        Comparativo dos planos de aposentadoria
                      </p>
                      <!-- <p class="h6 p-0 m-0">
                        Montagem da matriz de Planejamento previdenciário do calculei. É a ferramenta ideal para voocê reduzir o tempo de listagem e análise de cenários de aposentadoria.
                      </p> -->

                    </div>
                  </div>

                  <!-- 
                  <div class="row py-4">

                    <div class="col-auto" [routerLink]="['/calculei/calculos/previdenciario/kertzman']" role="button">
                      <i class="comando fs-2">IK
                        <div>
                          <span class="h6 text-white align-bottom">prev</span>
                        </div>
                      </i>
                    </div>
                    <div class="col-auto" [routerLink]="['/calculei/calculos/previdenciario/kertzman']" role="button">
                      <p class="h1">
                        Método Kertzman
                      </p>
                      <p class="p-0 m-0">
                        Otimização do valor do benefício na aposentadoria por idade
                      </p>
                      <p class="h6 p-0 m-0">
                        Ideal para quando as regras por tempo não forem vantajosas.
                        O Método Kertzman, busca a da taxa de retorno do investimento na aposentadoria por idade.
                      </p>

                    </div>

                  </div> -->

                  <!-- <div class="row py-4">
                    <div class="col-auto" role="button" [routerLink]="['/calculei/calculos/previdenciario/analisecnis']">

                      <i class="comando bi bi-calendar2-week fs-2"></i>

                    </div>
                    <div class="col-auto" role="button" [routerLink]="['/calculei/calculos/previdenciario/analisecnis']">

                      <p class="h1">
                        Análise de CNIS
                      </p>
                      <p class="p-0 m-0">
                        Verificação de pendências, ajustes, avisos, pontos de atenção e indicadores
                      </p>
                      

                    </div>
                  </div> -->

                  <div class="row py-4">

                    <div class="col-auto" [routerLink]="['/calculei/calculos/novocalculo']" role="button">
                      <i class="comando bi bi-plus-square fs-2"></i>
                    </div>
                    <div class="col-auto" [routerLink]="['/calculei/calculos/novocalculo']" role="button">
                      <p class="h1">
                        Cálculos
                      </p>
                      <p class="p-0 m-0">
                        Modelos de cálculos previdenciários
                      </p>
                      
                      <!-- <ul class="h6">

                        <li>
                          Análise de CNIS
                        </li>

                        <li>
                          Qualidade de Segurado
                        </li>

                        <li>
                          Auxílio por Incapacidade Temporária / Acidente de Trabalho
                        </li>

                        <li>
                          Aposentadoria por Invalidez
                        </li>

                        <li>
                          Simulação
                        </li>

                        <li>
                          Otimização Método Kertzman
                        </li>

                      </ul> -->

                    </div>

                  </div>

                  <!-- <div class="row " *ngIf="!assinaturaAtiva">
                    <div class="col-2" role="button" [routerLink]="'/calculei/usuario/planos'">
                      <div>
                        <img class="img-fluid" src="/assets/img/promocional/card-segundoanogratis.png" alt="image">
                      </div>
                    </div>
                  </div> -->

                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>