

<!-- <div class="row w-100">
    <div class="col w-100 px-0 mx-0 text-center">
        <div [className]="aguarde ? 'visible' : 'invisible'">
    
            <div class="">
                <div class="linha-crescendo"></div>
            </div>
    
        </div>
    </div>
</div> -->



<div [className]="aguarde ? 'visible' : 'invisible'">
    <div class="linha-crescendo"></div>
</div>

