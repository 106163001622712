import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { LogService } from 'src/app/servicos/log.service';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import annotationPlugin from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-custo-imposto-renda-anual',
  templateUrl: './custo-imposto-renda-anual.component.html',
  styleUrl: './custo-imposto-renda-anual.component.scss'
})
export class CustoImpostoRendaAnualComponent implements OnInit {

  @Input() caso: CasoPlanejamentoPrevidenciario;

  dataRendaAnualBrutaSalarios: any[];
  dataCustoImpostoRendaAno: any[];
  dataDespesasDedutiveis: any[];


  chart: Chart;

  constructor(private currency: CurrencyPipe, private datePipe: DatePipe,
    private listarPDFService: ListarPDFService,
    private decimalPipe: DecimalPipe,
    private notificacoesService: NotificacoesService) {

    // LogService.log('listagem imposto renda anual', this.caso);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.carregarDados();
    }
  }


  ngOnInit(): void {

    this.carregarDados();

  }

  carregarDados() {
    if (this.chart) { this.chart.destroy(); this.chart = null; }

    // console.log('variacao rmi');
    // console.log(this.simulacoes.flatMap(caso => caso.relatorioRMI.rendaMensalInicial));


    // console.log('total simulacoes');
    // console.log(this.caso.simulacoes.length);
    // LogService.log('map', this.relatorio.variacaoRmi);
    this.dataRendaAnualBrutaSalarios = this.caso.listagemImpostoRenda.map(anoImpostoRenda=>anoImpostoRenda.rendaAnualBrutaSalarios);
    this.dataCustoImpostoRendaAno = this.caso.listagemImpostoRenda.map(anoImpostoRenda=>anoImpostoRenda.custoImpostoRendaAno);
    this.dataDespesasDedutiveis = this.caso.listagemImpostoRenda.map(anoImpostoRenda=>anoImpostoRenda.totalDespesasDedutiveis);

    let datasetLabels = this.caso.listagemImpostoRenda.map(anoImpostoRenda=>(new Date(anoImpostoRenda.ano).getFullYear()));

    // let colors = datasetLabels.map(variacao => variacao === this.relatorio.otimizacao.totalSubstituicoesTeto ? "#00BFFF" : "#333333")
    let dataset = {
      labels: datasetLabels,
      datasets: [
        {
          data: this.dataRendaAnualBrutaSalarios,
          label: 'Renda Salarial Bruta',
          backgroundColor: '#A0A0A0',//'#172b4d',
          
        },
        {
          data: this.dataDespesasDedutiveis,
          label: 'Total de Despesas Dedutiveis',
          backgroundColor: '#404040',//'#172b4d',
        },
        {
          data: this.dataCustoImpostoRendaAno,
          label: 'Custo Imposto de Renda',
          backgroundColor: '#000000',//'#172b4d',
          
        }

      ]
    };



    let chart = document.getElementById('chartImpostoRendaAnual') as HTMLCanvasElement;


    Chart.register(annotationPlugin);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      options: {
        onClick: (e, activeEls) => {
          // console.log('opa rmi', dataset.datasets[activeEls[0].datasetIndex].data[activeEls[0].index]);
          // console.log('opa rmi caso', this.data[activeEls[0].index]);
          // console.log(this.caso.simulacoes[activeEls[0].index].totalSubstituicoesTeto)

          // LogService.log('click variacao rmi', this.relatorio.variacaoRmi[activeEls[0].index]);
          // LogService.log('click variacao otimizacao', this.relatorio.variacaoSubstituicoesTeto[activeEls[0].index]);

        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          annotation: {
            annotations: {
            }
          },
          legend: {
            display: true,
          },
          title: {
            display: false,
            text: 'Valor do benefício',
            
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              
            },
            callbacks: {
              label: (context) => {

                // LogService.log('tooltip variacao rmi', context);
                let info: string[] = new Array<string>();
                // LogService.log('context', context);
                info.push(context.dataset.label + ' em '+ context.label);
                // info.push(context.parsed.x + ' contribuições de otimização');

                

                return info;


              },
              title: (tooltipItens) => {

                // let label = context.dataset.label || '';

                // if (label) {
                //   label += ': ';
                // }
                // if (context.parsed.y !== null) {
                //   label += this.currency.transform(context.parsed.y);
                // }

                let info: string[] = new Array<string>();
                // LogService.log('tooltip ir' ,tooltipItens);
                info.push(this.currency.transform(tooltipItens[0].parsed.y))
                
                return info;

              },

            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,

            title: {
              display: false,
              text: "total R$"
            },

            ticks: {
              callback: (value, index, ticks) => {

                if (value as number >= 1000) {
                  return `R$ ${(value as number / 1000).toFixed(0)}k`; // Exibe em "k" para milhares
                } else {
                  return this.currency.transform(value, 'BRL', 'symbol', '1.0-0');
                }
                
              }
            }

          },
          x: {
            type: 'category',
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: false,
              text: 'Total de contribuições de otimização'
            },
          }
        }
      }

    });
  }

}
