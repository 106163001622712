import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AguardeComponent } from './aguarde/aguarde.component';
import { BeneficioPipe } from './pipes/beneficiopipe';
import { RegraBeneficioPipe } from './pipes/regrabeneficiopipe';
import { BarraNavegacaoComponent } from './barra-navegacao/barra-navegacao.component';
import { AnonimoPipe } from './pipes/anonimopipe';
import { StatusBeneficioPipe } from './pipes/statusbeneficiopipe';
import { PeriodoPipe } from './pipes/periodoplanopipe';
import { MeiosPagamentoPipe } from './pipes/meiospagamentopipe';
import { OpcaoNumeroContribuicoesPipe } from './pipes/opcoesnumerocontribuicoespipe';
import { OpcoesImpostRendaPlanejamentoPipe } from './pipes/opcoesimpostorendaplanejamentopipe';
import { HtmlCodePipe } from './pipes/htmlcodepipe';
import { NumeroPipe } from './pipes/numeropipe';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TipoAtividadePipe } from './pipes/tipoatividadepipe';
import { IncluirCasoMatrizPlanejamentoComponent } from './incluir-caso-matriz-planejamento/incluir-caso-matriz-planejamento.component';
import { MarcadorPipe } from './pipes/marcadorpipe';

import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { TipoCalculoPipe } from './pipes/tipocalculopipe';
import { TipoIndicadorPipe } from './pipes/tipo-indicador.pipe';
import { GrupoIndicadorPipe } from './pipes/grupo-indicador.pipe';
import { MoedaCompetenciaPipe } from './pipes/moeda-competencia.pipe';
import { TipoContribuicaoPipe } from './pipes/tipo-contribuicao.pipe';
import { PosteriorPlanoRealPipe } from './pipes/posterior-plano-real.pipe';
import { TipoBeneficioLiquidacaoPipe } from './pipes/tipo-beneficio-liquidacao.pipe';
import { TipoProcessoLiquidacaoPipe } from './pipes/tipo-processo-liquidacao.pipe';
import { CheckDataAtualLimitePipe } from './pipes/check-data-atual-limite.pipe';
import { TipoVinculoPipe } from './pipes/tipo-vinculo.pipe';
import { StatusAssinaturaPipe } from './pipes/status-assinatura.pipe';
import { AddMarcadorComponent } from './add-marcador/add-marcador.component';
import { FiltroCasosMatrizPipe } from './pipes/filtro-casos-matriz.pipe';
import { TipoRespostaPipe } from './pipes/tipo-resposta.pipe';
import { SelecionarClienteComponent } from './selecionar-cliente/selecionar-cliente.component';
import { SelecionarFichaComponent } from './selecionar-ficha/selecionar-ficha.component';
import { AddClienteComponent } from './add-cliente/add-cliente.component';
import { ListaClientesComponent } from './lista-clientes/lista-clientes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { InputMaskModule } from '@ngneat/input-mask';
import { ListaFichasComponent } from './lista-fichas/lista-fichas.component';
import { AddFichaComponent } from './add-ficha/add-ficha.component';
import { AddTintaComponent } from './add-tinta/add-tinta.component';
import { TintaFundoPipe } from './pipes/tinta-fundo.pipe';
import { CampoMatrizComponent } from './campo-matriz/campo-matriz.component';
import { CompararCenariosPipe } from './pipes/comparar-cenarios.pipe';
import { IndicadoresUnicosPipe } from './pipes/indicadores-unicos.pipe';
import { TotalDiasSequenciaPipe } from './pipes/total-dias-sequencia.pipe';
import { FrequenciaPipe } from './pipes/frequencia.pipe';
import { PeriodicidadePipe } from './pipes/periodicidade.pipe';

@NgModule({
  declarations: [
    AguardeComponent,
    BeneficioPipe,
    StatusBeneficioPipe,
    RegraBeneficioPipe,
    BarraNavegacaoComponent,
    AnonimoPipe,
    PeriodoPipe,
    MeiosPagamentoPipe,
    OpcaoNumeroContribuicoesPipe,
    OpcoesImpostRendaPlanejamentoPipe,
    HtmlCodePipe,
    NumeroPipe,
    TipoAtividadePipe,
    IncluirCasoMatrizPlanejamentoComponent,
    MarcadorPipe,
    CabecalhoComponent,
    TipoCalculoPipe,
    TipoIndicadorPipe,
    GrupoIndicadorPipe,
    MoedaCompetenciaPipe,
    TipoContribuicaoPipe,
    PosteriorPlanoRealPipe,
    TipoBeneficioLiquidacaoPipe,
    TipoProcessoLiquidacaoPipe,
    CheckDataAtualLimitePipe,
    TipoVinculoPipe,
    StatusAssinaturaPipe,
    AddMarcadorComponent,
    FiltroCasosMatrizPipe,
    TipoRespostaPipe,
    SelecionarClienteComponent,
    SelecionarFichaComponent,
    AddClienteComponent,
    ListaClientesComponent,
    ListaFichasComponent,
    AddFichaComponent,
    AddTintaComponent,
    TintaFundoPipe,
    CampoMatrizComponent,
    CompararCenariosPipe,
    IndicadoresUnicosPipe,
    TotalDiasSequenciaPipe,
    FrequenciaPipe,
    PeriodicidadePipe

    
    
  ],
  imports: [
    
    CommonModule,
    PopoverModule,
    
    NgbTooltipModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    InputMaskModule,
    
  ],
  exports:[
    AguardeComponent,
    BeneficioPipe,
    StatusBeneficioPipe,
    RegraBeneficioPipe,
    AnonimoPipe,
    BarraNavegacaoComponent,
    PeriodoPipe,
    MeiosPagamentoPipe,
    OpcaoNumeroContribuicoesPipe,
    OpcoesImpostRendaPlanejamentoPipe,
    HtmlCodePipe,
    NumeroPipe,
    TipoAtividadePipe,
    IncluirCasoMatrizPlanejamentoComponent,
    MarcadorPipe,
    CabecalhoComponent,
    TipoCalculoPipe,
    TipoIndicadorPipe,
    GrupoIndicadorPipe,
    MoedaCompetenciaPipe,
    TipoContribuicaoPipe,
    PosteriorPlanoRealPipe,
    TipoBeneficioLiquidacaoPipe,
    TipoProcessoLiquidacaoPipe,
    CheckDataAtualLimitePipe,
    TipoVinculoPipe,
    StatusAssinaturaPipe,
    AddMarcadorComponent,
    FiltroCasosMatrizPipe,
    TipoRespostaPipe,
    SelecionarClienteComponent,
    SelecionarFichaComponent,
    AddClienteComponent,
    ListaClientesComponent,
    AddTintaComponent,
    TintaFundoPipe,
    CampoMatrizComponent,
    CompararCenariosPipe,
    IndicadoresUnicosPipe,
    TotalDiasSequenciaPipe,
    FrequenciaPipe,
    PeriodicidadePipe,
    
    
  ]
})
export class CompartilhadoModule { }
