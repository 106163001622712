<div class=" header bg-back-dark">

  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-3">
        <!-- <div class=" col-lg-12">
          <span class=" text-white inline-block mb-0 h6 fw-lighter">Cálculo Liquidação de Sentença Previdenciária</span>
          <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">

          </nav>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div class=" container-fluid mt--3  ">

  <div class=" row">
    <div class=" col-12">
      <div class="  ">
        <div class="pb-4">

          <div class=" card ">

            <div class="row">
              <div class="col-12">

                <div class=" card-body  ">

                  <div class="row">
                    <div class="col-12">

                      <div class="row">
                        <div class="col-12">
                          <app-barra-comandos *ngIf="calculoLiquidacaoSentencaPrevidenciario" [titulo]="calculoLiquidacaoSentencaPrevidenciario.nomeCalculo" (proximoEvent)="proximo($event)" (anteriorEvent)="anterior($event)" (pdfEvent)="pdf($event)" (cancelarEvent)="cancelar($event)" (salvarEdicaoCnisEvent)="salvarEdicaoCnis($event)"></app-barra-comandos>
                        </div>
                      </div>
                      <div id="stepper1" class="bs-stepper .vertical">
                        <div class="row">
                          <div class="col-12">
                            <div class="bs-stepper-header">
                              <div class="step" data-target="#test-l-1">
                                <button class="step-trigger">
                                  <span class="bs-stepper-circle fw-normal">1</span>
                                  <span class="bs-stepper-label fw-normal">Referência do Processo</span>
                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-2">
                                <button class="step-trigger">
                                  <span class="bs-stepper-circle fw-normal">2</span>
                                  <span class="bs-stepper-label fw-normal">Benefício</span>
                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-3">
                                <button class="step-trigger">
                                  <!-- <div class="spinner-grow" role="status" [ngClass]="{'show': aguardeResultado, 'collapse': !aguardeResultado}"></div> -->

                                  <span class="bs-stepper-circle fw-normal">3</span>
                                  <span class="bs-stepper-label fw-normal">Descontos</span>

                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-4">
                                <button class="step-trigger">
                                  <!-- <div class="spinner-grow" role="status" [ngClass]="{'show': aguardeResultado, 'collapse': !aguardeResultado}"></div> -->

                                  <span class="bs-stepper-circle fw-normal">4</span>
                                  <span class="bs-stepper-label fw-normal">Atualização Monetária</span>

                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-5">
                                <button class="step-trigger">
                                  <!-- <div class="spinner-grow" role="status" [ngClass]="{'show': aguardeResultado, 'collapse': !aguardeResultado}"></div> -->

                                  <span class="bs-stepper-circle fw-normal">5</span>
                                  <span class="bs-stepper-label fw-normal">Honorários</span>

                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-6">
                                <button class="step-trigger">
                                  <!-- <div class="spinner-grow" role="status" [ngClass]="{'show': aguardeResultado, 'collapse': !aguardeResultado}"></div> -->

                                  <span class="bs-stepper-circle fw-normal"><i class="bi bi-check"></i></span>
                                  <span class="bs-stepper-label fw-normal">Resultado</span>

                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="bs-stepper-content m-0 p-0">

                          <!-- selecionar CNIS -->
                          <div id="test-l-1" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-referencia-liquidacao-sentenca-previdenciario #referencia *ngIf="calculoLiquidacaoSentencaPrevidenciario && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia"  [calculoLiquidacaoSentencaPrevidenciario]="calculoLiquidacaoSentencaPrevidenciario"></app-referencia-liquidacao-sentenca-previdenciario>
                              </div>
                            </div>
                          </div>

                          <!-- Análise CNIS -->
                          <div id="test-l-2" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-beneficio-liquidacao-sentenca-previdenciario *ngIf="calculoLiquidacaoSentencaPrevidenciario && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio" [calculoLiquidacaoSentencaPrevidenciario]="calculoLiquidacaoSentencaPrevidenciario" ></app-beneficio-liquidacao-sentenca-previdenciario>

                              </div>
                            </div>
                          </div>

                          <!-- Resultado -->
                          <div id="test-l-3" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-abatimentos-liquidacao-sentenca-previdenciario [calculoLiquidacaoSentencaPrevidenciario]="calculoLiquidacaoSentencaPrevidenciario"></app-abatimentos-liquidacao-sentenca-previdenciario>
                              </div>
                            </div>

                          </div>

                          <!-- Resultado -->
                          <div id="test-l-4" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-atualizacao-liquidacao-sentenca-previdenciario #atualizacao [calculoLiquidacaoSentencaPrevidenciario]="calculoLiquidacaoSentencaPrevidenciario"> </app-atualizacao-liquidacao-sentenca-previdenciario>

                              </div>
                            </div>

                          </div>

                          <!-- Resultado -->
                          <div id="test-l-5" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-honorarios-liquidacao-sentenca-previdenciario #honorarios [calculoLiquidacaoSentencaPrevidenciario]="calculoLiquidacaoSentencaPrevidenciario"></app-honorarios-liquidacao-sentenca-previdenciario>
                              </div>
                            </div>

                          </div>

                          <!-- Resultado -->
                          <div id="test-l-6" class="content">

                            <div class="row">
                              <div class="col-12">

                                <app-relatorio-liquidacao-sentenca-previdenciario #relatorio [calculoLiquidacaoSentencaPrevidenciario]="calculoLiquidacaoSentencaPrevidenciario" ></app-relatorio-liquidacao-sentenca-previdenciario>

                              </div>
                            </div>

                          </div>

                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

</div>
