import { Pipe, PipeTransform } from '@angular/core';
import { TiposVinculos } from 'src/app/modelos/previdenciario/tiposvinculos';

@Pipe({
  name: 'tipoVinculo',

})
export class TipoVinculoPipe implements PipeTransform {

  transform(value: TiposVinculos, ...args: unknown[]): string {

    let texto: string = "";

    let tipo: TiposVinculos = TiposVinculos[TiposVinculos[value]];
    switch (value) {

      case TiposVinculos.APOSENTADO:
        texto = "Aposentadoria";
        break;
      case TiposVinculos.AUTONOMO:
        texto = "Autônomo";
        break;
      case TiposVinculos.BENEFICIO:
        texto = "Benefício";
        break;
      case TiposVinculos.CONTRIBUINTEEMDOBRO:
        texto = "Contribuinte em Dobro";
        break;
      case TiposVinculos.CONTRIBUINTEINDIVIDUAL:
        texto = "Contribuinte Individual";
        break;
      case TiposVinculos.EMPREGADODOMESTICO:
        texto = "Empregado Doméstico";
        break;
      case TiposVinculos.EMPREGADOOUAGENTEPUBLICO:
        texto = "Empregado ou Agente Público";
        break;
      case TiposVinculos.FACULTATIVO:
        texto = "Facultativo";
        break;
      case TiposVinculos.SEGURADOESPECIAL:
        texto = "Segurado Especial";
        break;
      case TiposVinculos.SEGURADOFACULTATIVO:
        texto = "Segurado Facultativo";
        break;
      case TiposVinculos.TRABALHADORAVULSO:
        texto = "Trabalhador Avulso";
        break;
      case TiposVinculos.AUXILIOINCAPACIDADETEMPORARIA:
        texto = "Auxílio incapacidade temporária";
        break;
      case TiposVinculos.AUXILIOINCAPACIDADEPERMANENTE:
        texto = "Auxílio incapacidade permanente";
        break;
      case TiposVinculos.SALARIOMATERNIDADE:
        texto = "Salario Maternidade";
        break;
      case TiposVinculos.PRESTADORSERVICO:
        texto = "Prestador de Serviço";
        break;
      case TiposVinculos.MEI:
        texto = "MEI";
        break;
      case TiposVinculos.PENSAOMORTE:
        texto = "Pensão por morte";
        break;

      case TiposVinculos.SOCIOEMPRESARIO:
        texto = "Sócio Empresário Autônomo";
        break;

      case TiposVinculos.PROJECAOPLANEJAMENTO:
        texto = "Projeção para planejamento";
        break;

      case TiposVinculos.CONTRIBUINTEINDIVIDUALPFPLANOSIMPLIFICADO:
        texto = "Contribuinte Individual PF Plano Simplificado";
        break;

      case TiposVinculos.CONTRIBUINTEINDIVIDUALPJEMPRESARIO:
        texto = "Contribuinte Individual PJ ou Empresário";
        break;

      case TiposVinculos.CONTRIBUINTEINDIVIDUALPF:
        texto = "Contribuinte Individual PF";
        break;

      case TiposVinculos.CONTRIBUINTEINDIVIDUALPFCOOPERADO:
        texto = "Contribuinte Individual PF Cooperado";
        break;

      default:
        texto = "Tipo"
        break;

    }
    return texto;
  }

}
