import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';

@Component({
  selector: 'app-variacao-payback-simulacao-kertzman',
  templateUrl: './variacao-payback-simulacao-kertzman.component.html',
  styleUrls: ['./variacao-payback-simulacao-kertzman.component.scss']
})
export class VariacaoPaybackSimulacaoKertzmanComponent implements OnInit, OnChanges {

  @Input() visualizarPdf: boolean = true;
  @Input() relatorio: RelatorioKertzman;
  

  chart: Chart;
  data: any[];

  aguardePdf:boolean;

  constructor(private currency: CurrencyPipe, private datePipe: DatePipe, private decimalPipe: DecimalPipe,
    private listarPDFService: ListarPDFService,
    private notificacoesService: NotificacoesService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart)
      this.carregarDados();
  }


  ngOnInit(): void {
    this.carregarDados();
  }

  carregarDados() {
    if (this.chart) { this.chart.destroy(); this.chart = null; }

    this.data = this.relatorio.variacaoPayback;

    let datasetLabels = this.relatorio.variacaoSubstituicoesTeto;

    let colors = datasetLabels.map(variacao=>variacao === this.relatorio.otimizacao.totalSubstituicoesTeto ? "#00BFFF" : "#333333" )
    let dataset = {
      labels: datasetLabels, 
      datasets: [
        {
          data: this.data,
          label: 'Tempo de Retorno',
          backgroundColor: colors,
        }
      ]
    };



    let chart = document.getElementById('variacaoPaybackMeses') as HTMLCanvasElement;


    Chart.register(annotationPlugin);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      options: {
        onClick: (e, activeEls) => {
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          annotation: {
            annotations: {
            }
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Variação de Payback em Meses',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              // size: 24,
              // weight: 'normal',
            },
            titleFont: {
              // size: 24,
              // weight: 'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.decimalPipe.transform(context.parsed.y, '1.0-0') + " meses";
                }
                return label;
              },
              title: (tooltipItens) => {
                return tooltipItens[0].parsed.x + " contribuições de otimização";
              },

            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,

            title:{
              display:true,
              text: "tempo de retorno em meses"
            },

            ticks: {
              callback: (value, index, ticks) => {
                return value;
              }
            }

          },
          x: {
            type: 'category',
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Total de contribuições de otimização'
            },




          }
        }
      }

    });
  }


  gerarPDF() {

    // this.aguardePdf = true;
    // this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.VARIACAOPAYBACK;
    // this.calculoPlanejamentoKertzman.usuario = Global.usuario;

    // this.listarPDFService.ListarPDF(this.calculoPlanejamentoKertzman)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'VARIACAO-PAYBACK-PLANEJAMENTO-CONTRIBUTIVO-KERTZMAN-' + this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');


    //     this.aguardePdf = false;

    //   });
  }

}
