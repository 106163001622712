import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
export enum CalculoTipos {
    NA,
    CALCULOBENEFICIOS,
    LIQUIDACAOSENTENCAPREVIDENCIARIO,
    VALORCAUSA,
    REVISAOVIDATODA,
    SALARIOMATERNIDADE,
    PENSAOMORTE,
    AUXILIOACIDENTE,
    AUXILIORECLUSAO,
    LIQUIDACAOSENTENCACIVEL,
    KERTZMAN,
    CASOPLANEJAMENTOKERTZMAN,
    CASOPLANEJAMENTO,
    CALCULOPLANEJAMENTOPREVIDENCIARIO,
    MATRIZPLANEJAMENTO,
    VARIACAORMI,
    VARIACAOCONTRIBUICOES,
    VARIACAOPAYBACK,
    VARIACAOTAXA,
    VARIACAOLUCRO,
    SUMARIOSIMULACAORMI,
    JANELASPERIODOCONTRIBUTIVO,
    EVOLUCAOSALARIOSPREANALISEKERTZMAN,
    PREPARACAOANALISECNIS,
    INCAPACIDADETEMPORARIA,
    QUALIDADESEGURADO,
    MAPAQUALIDADESEGURADO,
    MAPAQUALIDADESEGURADOCONSOLIDADO,
    BENEFICIO,
    TABELAMATRIZ,
    INVALIDEZ,
    CASOPLANEJAMENTOCAPITALIZACAO
}

export class CalculoTiposEx {

    static tipoToString(value: string) {
        if (Number(value)) {
            value = CalculoTipos[value];
        }

        let tipo: CalculoTipos = CalculoTipos[value];
        let texto: string = "";
        switch (tipo) {
            case CalculoTipos.CALCULOBENEFICIOS:
                texto = "Renda Mensal Inicial";
                break;
            case CalculoTipos.LIQUIDACAOSENTENCAPREVIDENCIARIO:
                texto = "Liquidação de Sentença";
                break;
            case CalculoTipos.VALORCAUSA:
                texto = "Valor da Causa";
                break;
            case CalculoTipos.REVISAOVIDATODA:
                texto = "Revisão da Vida Toda";
                break;
            case CalculoTipos.SALARIOMATERNIDADE:
                texto = "Salário Maternidade";
                break;


            case CalculoTipos.PENSAOMORTE:
                texto = "Pensão por Morte";
                break;
            case CalculoTipos.AUXILIOACIDENTE:
                texto = "Auxílio Acidente";
                break;
            case CalculoTipos.AUXILIORECLUSAO:
                texto = "Auxílio Reclusão";
                break;
            case CalculoTipos.LIQUIDACAOSENTENCACIVEL:
                texto = "Liquidação de Sentença Cível";
                break;
            case CalculoTipos.KERTZMAN:
                texto = "Método Kertzman";
                break;

            case CalculoTipos.INCAPACIDADETEMPORARIA:
                texto = "Auxílio Por Incapacidade Temporária / Acidente de Trabalho";
                break;

                case CalculoTipos.INVALIDEZ:
                texto = "Cálculo de aposentadoria por invalidez";
                break;



            case CalculoTipos.CASOPLANEJAMENTOKERTZMAN:
                texto = "Método Kertzman";
                break;
            case CalculoTipos.CASOPLANEJAMENTO:
                texto = "Caso Planejamento";
                break;
            case CalculoTipos.CALCULOPLANEJAMENTOPREVIDENCIARIO:
                texto = "Simulação";
                break;
            case CalculoTipos.MATRIZPLANEJAMENTO:
                texto = "Matriz de Planejamento Previdenciário";
                break;
            case CalculoTipos.VARIACAORMI:
                texto = "Variação de RMI";
                break;

            case CalculoTipos.BENEFICIO:
                texto = "Cálculo de RMI";
                break;



            case CalculoTipos.VARIACAOCONTRIBUICOES:
                texto = "Variação de Contribuições";
                break;
            case CalculoTipos.VARIACAOPAYBACK:
                texto = "mês";
                break;
            case CalculoTipos.VARIACAOTAXA:
                texto = "mês";
                break;
            case CalculoTipos.VARIACAOLUCRO:
                texto = "mês";
                break;
            case CalculoTipos.SUMARIOSIMULACAORMI:
                texto = "Simulação";
                break;


            case CalculoTipos.JANELASPERIODOCONTRIBUTIVO:
                texto = "mês";
                break;
            case CalculoTipos.EVOLUCAOSALARIOSPREANALISEKERTZMAN:
                texto = "mês";
                break;
            default:
                break;



        }
        return texto;

    }
}