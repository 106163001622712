


<div class="row">
  <div class="col-12">
    <h5 class=" h3 mb-0 linha-quadro">Timeline de Remunerações</h5>
  </div>

  <div *ngIf="visualizarPdf" class="col-12 d-flex flex-row-reverse">
    <i class="comando bi bi-filetype-pdf fs-6" role="button" (click)="gerarPDF()"></i>

    <app-aguarde-local [aguarde]="aguardePdf"></app-aguarde-local>
    
    
  </div>
</div>



<div class="row py-4">
  <!-- Chart wrapper -->
  <div class="col-lg-12" >
    <canvas [id]="'chartBuracoTempoContributivo' + chartId" ></canvas>
  </div>
</div>