import { DeixarRecadoService } from 'src/app/servicos/usuario/deixar-recado.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import * as confetti from 'canvas-confetti';
import Swal from 'sweetalert2';
import { Recado } from 'src/app/modelos/usuario/recado';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { ExtenderPeriodoTesteService } from 'src/app/servicos/usuario/extender-periodo-teste.service';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.scss']
})
export class TesteComponent {


  prorrogacoes: number;

  constructor(private router: Router,
    private configService: ConfigService,
    private extenderPeriodoTesteService: ExtenderPeriodoTesteService,
    private datePipe: DatePipe,
    private DeixarRecadoService: DeixarRecadoService) {


    this.configService.getUsuario().subscribe(usuario => this.prorrogacoes = usuario.prorrogacoesPeriodoTeste);

  }

  queroAssinar() {
    this.router.navigateByUrl("/calculei/usuario/planos")
  }
  agendarApresentacao(){
    window.open("https://calendly.com/seniorprev/30min", "_blank")
  }
  async queroDeixarFeedback() {


    const { value: text } = await Swal.fire({
      iconHtml: '<i class="bi-envelope-at"/>',
      iconColor: '#000000',
      input: "textarea",
      inputLabel: "Ficaremos contente com seu feedback",
      inputPlaceholder: "Escreva aqui o seu feedback",
      inputAttributes: {
        "aria-label": "Escreva aqui o seu feedback"
      },
      showCancelButton: false,
      // cancelButtonText: "cancelar",
      confirmButtonText: 'Enviar',
      confirmButtonColor: '#000000'
    });
    if (text) {


      let recado: Recado = new Recado();
      recado.mensagem = text;
      this.configService.getUsuario().subscribe(usuario => recado.usuario = usuario);
      recado.data = new Date();


      this.DeixarRecadoService.deixarRecado(recado)
        .then(resposta => {

          // console.log("resposta recado");
          // console.log(resposta)

        });


      Swal.fire({
        iconHtml: '<i class="bi-hearts"/>',
        iconColor: '#000000',

        text: "Obrigado pelo seu feedback!",
        showConfirmButton:false,
      }).then(confirmacao => {

        // this.extenderPeriodoTesteService.extenderPeriodoTeste().then((usuarioResponse: Usuario) => {

        //   this.configService.setUsuario(usuarioResponse).then(usuario => {

        //     Swal.fire({
        //       iconHtml: '<i class="bi-stopwatch"/>',
        //       iconColor: '#172b4d',
        //       text: "Ótimo deu tudo certo! Período de teste renovado até " + this.datePipe.transform(usuarioResponse.fimPeriodoTeste, "dd/MM"),
        //       confirmButtonText: 'ok',
        //       confirmButtonColor: '#172b4d'
        //     });

        //     this.router.navigateByUrl('/calculei/home')

        //   });



        // });


      });
    }


  }




}
